import React from 'react';
import { FloatButton } from 'antd';
import { Viewport } from 'components/ViewportProvider';
import Icons from 'components/CategoryNav/iconsNewDesign';
import styled from 'styled-components';
import SVGIcon from 'components/SVGIcon/SVGIcon';
import { CATEGORIES_FLOATING_LIST_ID } from './constants';
import { createStructuredSelector } from 'reselect';
import { selectLang } from 'containers/MainLayout/selectors';
import { ApplicationRootState } from 'types';
import { RecommendationWidget } from 'types/schema';
import { connect } from 'react-redux';
import { ShowcaseWidget } from '../../types/schema';
import { IWidgetTag, WIDGET_TAGS } from 'components/CategoryNav/sidebarFilterData';
import { selectSections } from 'containers/HomePage/selectors';
import { ISection } from 'containers/HomePage/types';

const MenuGroup = styled(FloatButton.Group)`
  &.ant-float-btn-group {
    inset-inline-end: calc(100% - 40px);
    inset-block-start: calc(25% + 106px);
    inset-block-end: 25%;
    box-shadow: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
  }

  &.show {
    transform: translateY(0);
    transition: transform 0.3s ease-in-out;
  }
`;

const MenuItem = styled(FloatButton)`
  cursor: pointer;
  color: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px !important;
  min-height: 38px;
  min-height: 38px;
  width: auto;
  height: auto;
  border-bottom: none !important;

  ${({ hoverColor, categoryName }) => `
    &.ant-float-btn-default:hover .ant-float-btn-body {
      background-color: ${hoverColor};
    }

    &.ant-float-btn-default:hover .ant-float-btn-body .ant-float-btn-content .ant-float-btn-icon {
      mix-blend-mode: revert;
    }

    &.ant-float-btn-default:hover .ant-float-btn-body .ant-float-btn-content .ant-float-btn-icon::after {
      content: '${categoryName}';
      position: absolute;
      left: calc(100% - 30px);
      min-width: max-content;
      background-color: ${hoverColor};
      padding-block: 8px;
      padding-left: 30px;
      padding-right: 10px;
      font-family: 'Maven Pro';
      border-radius: 0 4px 4px 0;
      font-size: 14px;
      font-weight: 200;
      white-space: nowrap;
      transform: translateX(-100%);
      opacity: 0;
      transition: transform 0.3s ease-in-out, opacity 0.2s ease-in-out 0.1s;
    }

    &.ant-float-btn-default:hover .ant-float-btn-body .ant-float-btn-content .ant-float-btn-icon:hover::after {
      transform: translateX(0);
      opacity: 1;
      transition: transform 0.3s ease-in-out, opacity 0.2s ease-in-out 0.1s;
    }
  `}

  &.ant-float-btn .ant-float-btn-body {
    width: 100%;
    height: 100%;
  }

  &.ant-float-btn .ant-float-btn-body .ant-float-btn-content {
    min-height: 30px;
    min-width: 30px;
    padding: 0;
  }

  &.ant-float-btn .ant-float-btn-body .ant-float-btn-content .ant-float-btn-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 30px;
    min-height: 30px;
    margin: 0;
    mix-blend-mode: luminosity;
    color: inherit !important;
  }
`;

const Icon = styled(SVGIcon)`
  width: 22px;
  height: 22px;
  margin: auto;
  isolation: isolate;
  z-index: 1;
`;

interface IStateProps {
  lang: string;
  sections: ISection;
}

interface Props extends IStateProps {
  widgets: ShowcaseWidget[];
  recommendedForYou?: RecommendationWidget;
  className?: string;
}

const FloatingMenu: React.FC<Props> = ({ lang, sections, widgets, recommendedForYou, className }) => {
  return (
    <Viewport.Consumer>
      {({ width }) =>
        width > 1024 && (
          <MenuGroup shape="square" className={className}>
            {widgets.map((widget) => {
              let item = {} as IWidgetTag;
              let categoryName;
              if (widget.item.filter.tags?.length) {
                item = WIDGET_TAGS.find((tag) => tag.id === widget.item.filter.tags?.[0]) || item;
                categoryName = widget.item.title;
              } else if (widget.item.filter.categoryName) {
                item = CATEGORIES_FLOATING_LIST_ID.find((item) => item.id === widget?.item?.id) || item;
                categoryName = widget.item.title || '';
              }
              return sections[widget.widgetId]?.data?.length && !sections[widget.widgetId].error && categoryName ? (
                <MenuItem
                  icon={
                    Icons[item.id] && (
                      <Icon
                        src={Icons[item.id]}
                        style={{
                          objectFit: 'contain',
                          width: '22px',
                          height: '22px',
                        }}
                      />
                    )
                  }
                  key={item.id}
                  hoverColor={item.color}
                  categoryName={categoryName}
                  onClick={() => {
                    if (categoryName) {
                      const element = document.getElementById(widget.item.title);
                      if (element) {
                        window.scrollTo({
                          top: element.offsetTop - 100,
                          behavior: 'smooth',
                        });
                      }
                    }
                  }}
                />
              ) : null;
            })}
            {recommendedForYou && (
              <MenuItem
                icon={
                  Icons['RECOMMENDED_FOR_YOU'] && (
                    <Icon
                      src={Icons['RECOMMENDED_FOR_YOU']}
                      style={{
                        objectFit: 'contain',
                        width: '22px',
                        height: '22px',
                      }}
                    />
                  )
                }
                key={recommendedForYou.item.key}
                hoverColor={'#FFD5D2'}
                categoryName={recommendedForYou.item.title}
                onClick={() => {
                  const element = document.getElementById(recommendedForYou.item.title);
                  if (element) {
                    window.scrollTo({
                      top: element.offsetTop - 100,
                      behavior: 'smooth',
                    });
                  }
                }}
              />
            )}
            <MenuItem
              icon={Icons['ShopByBrand'] && <Icon src={Icons['ShopByBrand']} />}
              key={'ShopByBrand'}
              hoverColor={'#FEDEC8'}
              categoryName={lang === 'vi' ? 'Thương hiệu uy tín' : 'Trusted Brands'}
              onClick={() => {
                const element = document.getElementById('trusted-brands');
                if (element) {
                  window.scrollTo({
                    top: element.offsetTop - 100,
                    behavior: 'smooth',
                  });
                }
              }}
            />
          </MenuGroup>
        )
      }
    </Viewport.Consumer>
  );
};

const mapStateToProps = createStructuredSelector<ApplicationRootState, IStateProps>({
  lang: selectLang(),
  sections: selectSections(),
});

export default connect(mapStateToProps)(FloatingMenu);
