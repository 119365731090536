import React, { FC, useMemo } from 'react';
import { DetailsContainer } from './DetailsContainer';
import { FormattedMessage } from 'react-intl';
import { Price } from './Price';
import QuantityInput from 'containers/QuantityInput';
import { Tag, Modal } from 'antd';
import styled from 'styled-components';
import { allowDecimalQuantity } from 'utils/allowDecimalQuantity';
import utilsMessages from 'utils/messages';
import translations from 'translations';
import ProductNote, { NoteSm } from 'components/ProductNote';
import media from 'utils/mediaStyle';
import { Link } from 'react-router-dom';
import { Product, TrackingAddToCartAddFromType } from 'types/schema';
import generateProductSlug from 'utils/generateProductSlug';

const Title = styled.div`
  overflow: hidden;
  font-size: 14px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-height: 14px;
  height: 29px;
  color: #595959;
  font-weight: 500;
`;

const Header = styled.h3<any>`
  margin-right: ${({ marginRight }) => marginRight};
  margin-bottom: 5px;
  padding-right: 36px;
  ${media.md`
    margin-bottom: 12px;
    padding-right: 0;
  `};
`;

const FooterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  justify-content: flex-end;
  margin-top: 4px;
  ${({ isHomePage }) =>
    isHomePage &&
    `
    column-gap: 10px;
  `}
`;

const PriceContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
const TagExclusiveWrapper = styled.div`
  color: #424242;
  font-size: 12px;
  font-weight: 400;
`;
const TagExclusive = styled.div`
  padding: 4px 8px;
  background: #e6d8bb;
  color: #806e44;
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  width: fit-content;
`;

const SaleContact = styled.div`
  border: 1px solid #aaacb2;
  border-radius: 8px;
  padding: 6px 8px;
  cursor: pointer;
  a {
    color: #595959;
    text-decoration: none;
  }
`;

const addToCartPermission = 'ORDER_PLACE';

interface IProps {
  product: Product;
  loggedIn: boolean;
  noImage?: boolean;
  permissions: string[];
  onOpenNote: () => void;
  onDeleteNote: () => void;
  addFrom: TrackingAddToCartAddFromType;
  tag?: string | null;
  isHideNote?: boolean
}

const Details: FC<IProps> = (props) => {
  const { loggedIn, noImage, product, permissions, onDeleteNote, onOpenNote, addFrom, tag, isHideNote } = props;
  const { name, inStock, uom, note, private: isPrivate, allowPurchase } = product;
  const lang = localStorage.getItem('lang') || 'vi';
  const onClickDeleteNote = () =>
    Modal.confirm({
      autoFocusButton: null,
      okButtonProps: {
        danger: true,
      },
      title: translations(utilsMessages.titleRemoveNote),
      content: <p style={{ fontWeight: 300, marginTop: 16 }}>*{translations(utilsMessages.noteRemoveNote)}</p>,
      okText: translations(utilsMessages.yes),
      cancelText: translations(utilsMessages.no),
      onOk: () => onDeleteNote(),
    });

  const QuantityComponent = useMemo(() => {
    return inStock ? (
      <QuantityInput
        product={product}
        size="small"
        willOverflow
        allowDecimal={allowDecimalQuantity(uom)}
        addFrom={addFrom}
        tag={tag}
      />
    ) : (
      <Tag
        style={{
          background: '#efefef',
          border: 'none',
          borderRadius: 50,
          color: '#8c8c8c',
          fontWeight: 500,
          marginTop: 10,
          marginRight: 0,
          padding: '7px 12px',
          float: 'right',
          fontSize: 16,
          width: 152,
          textAlign: 'center',
        }}
      >
        <FormattedMessage {...utilsMessages.outStock} />
      </Tag>
    );
  }, [inStock, product, uom, addFrom, tag]);

  return (
    <DetailsContainer>
      <Header title={name} marginRight={noImage ? '44pt' : ''}>
        <Link to={`/products/${generateProductSlug(name, product.supplierInternalCode, lang)}`}>
          <Title>{name}</Title>
        </Link>
      </Header>
      {isPrivate ? (
        allowPurchase ? (
          <PriceContainer>
            <Price product={product} />
          </PriceContainer>
        ) : (
          <TagExclusiveWrapper>
            <TagExclusive>
              <FormattedMessage {...utilsMessages.exclusive} />
            </TagExclusive>
            <FormattedMessage {...utilsMessages.onlyForSpecialBusiness} />
          </TagExclusiveWrapper>
        )
      ) : (
        <PriceContainer>
          <Price product={product} />
        </PriceContainer>
      )}
      <FooterContainer>
        {note && !isHideNote && <NoteSm isShowNote={false}>{note}</NoteSm>}
        <FlexContainer isHomePage={window.location.pathname === '/'}>
          {!isHideNote && <ProductNote
            isShowNote={false}
            note={note}
            onClickOpenNote={onOpenNote}
            onClickDeleteNote={onClickDeleteNote}
          />}
          {permissions.indexOf(addToCartPermission) > -1 &&
            (isPrivate ? (
              allowPurchase ? (
                QuantityComponent
              ) : (
                <SaleContact>
                  <a rel="noopener noreferrer" target="_blank" href="https://zalo.me/2357831519751856428">
                    <FormattedMessage {...utilsMessages.talkToSale} />
                  </a>
                </SaleContact>
              )
            ) : (
              QuantityComponent
            ))}
          {!loggedIn &&
            (isPrivate ? (
              allowPurchase ? (
                <QuantityInput product={product} allowDecimal={false} addFrom={addFrom} tag={tag} />
              ) : (
                <SaleContact>
                  <a rel="noopener noreferrer" target="_blank" href="https://zalo.me/2357831519751856428">
                    <FormattedMessage {...utilsMessages.talkToSale} />
                  </a>
                </SaleContact>
              )
            ) : (
              QuantityComponent
            ))}
        </FlexContainer>
      </FooterContainer>
    </DetailsContainer>
  );
};

export default Details;
