/*
 * CheckoutPage Messages
 *
 * This contains all the text for the CheckoutPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.CheckoutPage';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Đặt hàng',
  },
  headerStep1: {
    id: `${scope}.headerStep1`,
    defaultMessage: 'Giỏ hàng',
  },
  headerStep2: {
    id: `${scope}.headerStep2`,
    defaultMessage: 'Giao hàng',
  },
  headerStep3: {
    id: `${scope}.headerStep3`,
    defaultMessage: 'Xác nhận đơn hàng',
  },
  headerStep4: {
    id: `${scope}.headerStep4`,
    defaultMessage: 'Đặt hàng thành công',
  },
  goToMart: {
    id: `${scope}.goToMart`,
    defaultMessage: 'Vào chợ!',
  },
  goToFavorites: {
    id: `${scope}.goToFavorites`,
    defaultMessage: 'Vào mục yêu thích!',
  },
  emptyCart: {
    id: `${scope}.emptyCart`,
    defaultMessage: 'Giỏ hàng trống',
  },
  yourCartIsEmpty: {
    id: `${scope}.yourCartIsEmpty`,
    defaultMessage: 'Giỏ hàng trống!',
  },
  goBacktoMartPrompt: {
    id: `${scope}.goBacktoMartPrompt`,
    defaultMessage: 'Chúng tôi có rất nhiều sản phẩm trong chợ!',
  },
  gotoTodayOrders: {
    id: `${scope}.gotoTodayOrders`,
    defaultMessage: 'Xem đơn hàng hôm nay',
  },
  yourOrderSent: {
    id: `${scope}.yourOrderSent`,
    defaultMessage: 'Đơn hàng của bạn đã được gửi',
  },
  yourPendingApprovalOrderSent: {
    id: `${scope}.yourPendingApprovalOrderSent`,
    defaultMessage: 'Đơn hàng của bạn đã được tạo và đang chờ phê duyệt',
  },
  saveDeliveryNote: {
    id: `${scope}.saveDeliveryNote`,
    defaultMessage: 'Lưu vào ghi chú giao hàng',
  },
  writeYourNote: {
    id: `${scope}.writeYourNote`,
    defaultMessage: 'Viết ghi chú...',
  },
  underMOQMessage: {
    id: `${scope}.underMOQMessage`,
    defaultMessage: 'Không thể tạo đơn hàng dưới giá trị đơn hàng tối thiểu',
  },
  removeAllOrdersMessage: {
    id: `${scope}.removeAllOrdersMessage`,
    defaultMessage: 'Bạn có chắc muốn xoá hết đơn hàng của nhà cung cấp này?',
  },
  gotoPendingOrders: {
    id: `${scope}.gotoPendingOrders`,
    defaultMessage: 'Xem đơn hàng chờ duyệt',
  },
  exclusiveProduct: {
    id: `${scope}.exclusiveProduct`,
    defaultMessage: 'Sản phẩm không có sẵn',
  },
  exclusiveProductMessage: {
    id: `${scope}.exclusiveProductMessage`,
    defaultMessage: 'Sản phẩm này được bán độc quyền cho doanh nghiệp.',
  },
  product: {
    id: `${scope}.product`,
    defaultMessage: ' sản phẩm',
  },
  products: {
    id: `${scope}.products`,
    defaultMessage: ' sản phẩm',
  },
  exclusive: {
    id: `${scope}.exclusive`,
    defaultMessage: 'Độc quyền',
  },
  outOfStock: {
    id: `${scope}.outOfStock`,
    defaultMessage: 'Hết hàng',
  },
  frequentlyBuyTogetherTitle: {
    id: `${scope}.frequentlyBuyTogetherTitle`,
    defaultMessage: 'Sản phẩm thường mua cùng',
  },
});
