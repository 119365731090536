import { createMatchSelector, push } from 'connected-react-router';
import { call, debounce, fork, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  addCartItemLight,
  addFavorite,
  getAllUoms,
  // getBrands,
  getBrandsWithCategories,
  getBuyer,
  getCart,
  getCategories,
  getOrderDisabled,
  getOrderStatistics,
  getOrigins,
  getProducts,
  getShortSuppliers,
  getUser,
  preloadCategoriesAndRevalidate,
  removeCartItem,
  removeCartItemLight,
  removeFavorite,
  sendRequest,
  setProductNote,
  updateCartDeliveryDate,
  updateCartDeliveryTime,
  getVaAndQrInfoData,
  getListHolidays,
} from 'utils/apollo';
import {
  addedMultipleToCart,
  addedToCart as addedToCartAction,
  addingMultipleToCart,
  addingToCart as addingToCartAction,
  allSupplier as allSuppliersActions,
  brandsByCategory as brandsActions,
  buyer as buyerActions,
  cart as cartActions,
  categories as categoriesActions,
  favorite as favoriteActions,
  favoriteCategories as favoriteCategoriesActions,
  orderDisabled,
  productNote as productNoteActions,
  recentOrders as recentOrdersActions,
  removeItemCart as removeItemCartActions,
  setBuyerWebVersion,
  setPromotionDataPopup,
  setSearchProductResults,
  setSearchProductResultsLoading,
  setSelectedStore,
  sideNavClose,
  signout as signoutActions,
  submitMultipleProductQuantity,
  shortSupplier as suppliersActions,
  updateCart,
  updateItemCart as updateItemCartActions,
  updateNotAvailableProducts,
  updateNoteToCard,
  user as userActions,
  setVaInfo,
  setRecommendationProductsForNewCustomer,
  setSpecialDates,
} from './actions';
import {
  makeSelectLocation,
  selectSearchProductResults,
  selectStore,
  selectSuppliers,
  selectUpdateQuantityProducts,
  selectUserPermissions,
} from './selectors';

import { message } from 'antd';
import { IS_USE_STATIC_POPUP } from 'containers/HomePage';
import messages from 'containers/SignupPage/messages';
import gql from 'graphql-tag';
import { SessionStorageKey } from 'hooks/useSessionStorage';
import moment from 'moment';
import * as qs from 'qs';
import { sendIdentify } from 'split/actions';
import translations from 'translations';
import { AccountBuyerStatus, ProductSortField, QueryProductSearchArgs, SortDirection } from 'types/schema';
import { camelize } from 'utils/camelize';
import { KAMEREO_SUPPLIER_ID } from 'utils/constants';
import utilMessages from 'utils/messages';
import { viewPermissions } from 'utils/permissionMap';
import triggerGA4EcommerceEvent, { EventName, triggerGA4SendUserIdEvent } from 'utils/triggerGA4Event';
import isGoogleBot from 'utils/urls/isGoogleBot';
import { updateDeliveryDetails as updateDeliveryDetailsAction } from '../../containers/CheckoutStep2Page/actions';
import selectCheckoutStep2Page from '../../containers/CheckoutStep2Page/selectors';
import { loadDeliveryDateTime as loadDeliveryDateTimeAction } from '../../containers/DeliveryDateTime/actions';
import ActionTypes from './constants';
import {
  checkHasUnreadNotification,
  markNotificationAsRead,
  notificationsFlow,
  registerToken,
  unregisterToken,
} from './notifications.saga';
import { BranchLinkSearch } from './types';
import { ProductBrandsFilterItem, ProductBrandsForHomeResponse } from '../../types/schema';
import { getPurchasedItemsMightFavorite } from '../HomePage/saga';

function* recentOrders() {
  const store = yield call(getStoreWithRetry);
  if (!store) {
    return;
  }
  const countStart = moment().startOf('day').toISOString();
  const countEnd = moment().endOf('day').toISOString();
  const aggregateStart = moment().startOf('month').toISOString();
  const aggregateEnd = moment().endOf('month').toISOString();
  yield put(recentOrdersActions.request());
  const response = yield call(getOrderStatistics, {
    storeId: store.id,
    countStart: countStart,
    countEnd: countEnd,
    aggregateStart: aggregateStart,
    aggregateEnd: aggregateEnd,
  });
  if (!response.errors) {
    yield put(
      recentOrdersActions.success({
        countPendingOrders: response.orderCountSummary.pendingApproval,
      }),
    );
  } else {
    yield put(recentOrdersActions.failure(response.errors));
  }
}

function* initCartFlow() {
  const store = yield call(getStore);
  if (!store) {
    return;
  }
  yield put(orderDisabled.request());
  yield put(cartActions.request());
  const response = yield call(getCart, { storeId: store.id });

  if (!response.errors) {
    let notAvailableProducts = [];
    let newCart = response;
    if (response?.cartSet && response.cartSet.length > 0) {
      for (let i = 0; i < response.cartSet.length; i += 1) {
        if (response?.cartSet[i]?.supplier) {
          const addtionalProducts = response?.cartSet[i].items.filter(
            (item: any) => !item?.product?.allowPurchase || !item?.product?.inStock,
          );
          let newTotal = response?.cartSet[i].total;
          addtionalProducts.forEach((item: any) => {
            newTotal -= item.product.price * item.quantity;
          });
          notAvailableProducts = notAvailableProducts.concat(addtionalProducts);
          newCart = {
            ...newCart,
            cartSet: [
              ...newCart.cartSet.slice(0, i),
              {
                ...newCart.cartSet[i],
                items: newCart.cartSet[i].items.filter(
                  (item: any) => item?.product?.allowPurchase && item?.product?.inStock,
                ),
                total: newTotal,
              },
              ...newCart.cartSet.slice(i + 1),
            ],
          };
        }
      }
      // if (newCart.cartSet[0].items.length === 0) {
      //   newCart = {
      //     ...newCart,
      //     cartSet: [],
      //   };
      // }
    }
    yield put(updateNotAvailableProducts(notAvailableProducts));
    yield put(cartActions.success(newCart));
  } else {
    yield put(cartActions.failure(response.errors));
  }
}

export const storeSelector = selectStore();

export function* getStore() {
  const store = yield select(storeSelector);
  if (store.loading) {
    return null;
  }
  if (store.id !== localStorage.getItem('store')) {
    localStorage.removeItem('store');
    yield put(push('/change-store'));
    return null;
  }

  // Sanity check passed
  return store;
}

export function* getStoreWithRetry() {
  let store = yield call(getStore);
  if (!store) {
    yield take(ActionTypes.SET_SELECTED_STORE);
    store = yield call(getStore);
  }
  return store;
}

function* initUserFlow() {
  const loggedIn = !!localStorage.getItem('token');

  if (loggedIn) {
    yield put(userActions.request());
    const response = yield call(getUser);
    if (!response.errors) {
      const user = response;
      yield put(userActions.success(user));
      const userPermissions = yield select(selectUserPermissions());
      const paths = Object.keys(viewPermissions);
      for (const path of paths) {
        const route = yield select(createMatchSelector(path));
        if (route) {
          const permissions = viewPermissions[route.path];
          if (permissions) {
            let permissionCounter = 0;
            for (const permission of userPermissions) {
              if (permissions.indexOf(permission) > -1) {
                permissionCounter += 1;
              }
            }
            if (permissionCounter !== permissions.length) {
              yield put(push('/'));
            }
          }
        }
      }
      triggerGA4SendUserIdEvent(user.id);
      if (window?.clarity) {
        window.clarity('set', 'user_email', user.email);
        window.clarity('identify', user.id);
      }
    } else {
      yield put(userActions.failure(response.errors));
    }
  }
}

function* getInfoVaAndQr(buyerName: string, buyerId: number) {
  const response = yield call(getVaAndQrInfoData, { buyerId: buyerId, buyerName: buyerName });
  if (!response?.errors) {
    yield put(setVaInfo(response));
  }
}

function* getRecommendationProductsForNewCustomer() {
  const params: QueryProductSearchArgs = {
    sort: [{ field: ProductSortField.Name, order: SortDirection.Asc }],
    filter: {
      isOnboardingBuyer: true,
      isRecommendation: true,
    },
    pagination: { page: 0, size: 100 },
  };
  const response = yield call(getProducts, params);
  if (!response.errors) {
    yield put(setRecommendationProductsForNewCustomer(response?.data));
  }
}

function* initBuyerFlow() {
  yield put(buyerActions.request());
  const response = yield call(getBuyer);
  const selectedStoreId = window.localStorage.getItem('store');

  if (!response.errors) {
    if (response.status === 'NOT_APPROVED') {
      yield put(signoutActions());
      message.error(translations(messages.waitingApprove));
    }
    if (response.status === 'DELETED' || response.status === 'DISABLED') {
      yield put(signoutActions());
      message.error(translations(messages.accountDeleted));
    } else {
      yield fork(getInfoVaAndQr, response.name, Number(response.id));
      const stores = response.stores || [];
      let store = stores.find((s: any) => s.id === selectedStoreId);
      if (stores.length === 1 && stores[0].status !== 'PENDING') {
        store = stores[0];
      }
      yield put(buyerActions.success(response));
      // if (!stores.length) {
      if (response.accountBuyerStatus === AccountBuyerStatus.NotActivated) {
        yield fork(getRecommendationProductsForNewCustomer);
      }
      // }
      if (!store) {
        window.localStorage.removeItem('store');
        if (stores.length || selectedStoreId) {
          yield put(push('/change-store'));
        }
      } else {
        window.localStorage.setItem('store', store.id);
        yield put(setSelectedStore(store));
        yield call(initCartFlow);
      }
    }
    yield put(sendIdentify());
  } else {
    yield put(signoutActions());
    yield put(buyerActions.failure(response.errors));
  }
}

function* signoutFlow() {
  while (true) {
    yield take(ActionTypes.SIGNOUT);
    yield call(unregisterToken);
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('store');
    window.localStorage.removeItem('checkout_id');
    window.localStorage.removeItem('rememberToken');
    window.localStorage.removeItem('isUnder18');
    yield put(sendIdentify());
    yield put(push('/signin'));
  }
}

function* favoriteFlow() {
  while (true) {
    const {
      payload: { product },
    } = yield take(ActionTypes.SET_FAVORITE);
    const id = product.id;
    yield put(favoriteActions.request());

    let response;
    if (product.isFavorite) {
      response = yield call(removeFavorite, { id: id });
    } else {
      response = yield call(addFavorite, { id: id });
    }

    if (response.errors) {
      yield put(favoriteActions.failure(response.errors));
    } else {
      yield put(favoriteActions.success({ id: id, favorite: !product.isFavorite, ...response }));
    }
  }
}

function* setProductNoteFlow() {
  while (true) {
    const { payload } = yield take(ActionTypes.SET_PRODUCT_NOTE);
    yield put(productNoteActions.request());
    const response = yield call(setProductNote, { input: payload });
    if (!response.errors) {
      yield put(productNoteActions.success(response.product));
      yield put(updateNoteToCard(response.product));
      message.success(translations(utilMessages[payload.note ? 'addItemNoteSuccess' : 'deleteItemNoteSuccess']));
    } else {
      yield put(productNoteActions.failure(response.errors));
      message.error(translations(utilMessages[payload.note ? 'addItemNoteFailed' : 'deleteItemNoteFailed']));
    }
  }
}

function* updateCartFlow(action) {
  const {
    payload: { quantity, product, date, startTime, endTime, supplierId },
  } = action;
  yield put(updateItemCartActions.request());
  const store = yield call(getStore);
  if (!store) {
    return;
  }
  let response;
  if (quantity === 0) {
    response = yield call(removeCartItemLight, {
      input: { storeId: store.id, supplierId: KAMEREO_SUPPLIER_ID, productId: product.id },
    });
  } else if (quantity) {
    const payload = { storeId: store.id, supplierId: KAMEREO_SUPPLIER_ID, productId: product.id, quantity: quantity };
    yield put(addingToCartAction(payload));
    response = yield call(addCartItemLight, {
      input: payload,
    });
    yield put(addedToCartAction({ productId: product.id }));
    const quantityOfItemOnEachEvent = 1;
    triggerGA4EcommerceEvent(EventName.ADD_TO_CART, {
      ...product,
      quantity: quantityOfItemOnEachEvent,
    });
  } else if (date) {
    response = yield call(updateCartDeliveryDate, {
      input: { storeId: store.id, supplierId: KAMEREO_SUPPLIER_ID, date: date },
    });
    if (response?.cart?.cartSet?.length) {
      for (const cartItem of response?.cart?.cartSet) {
        const formatTimeSlots = cartItem.deliveryTimeSlots.map((timeSlot) => ({
          start: moment(timeSlot.start).format('HH:mm'),
          end: moment(timeSlot.end).format('HH:mm'),
        }));
        if (formatTimeSlots.length) {
          yield put(
            loadDeliveryDateTimeAction.success({
              [supplierId]: {
                date: date,
                timeSlots: formatTimeSlots,
                loading: false,
              },
            }),
          );
          const checkoutState = yield select(selectCheckoutStep2Page());
          const deliveryTime = checkoutState.checkoutPage.checkout.timeSlot[supplierId];
          const splitDeliveryTime = deliveryTime?.split(' - ');
          const isDeliveryTimeMatchToTimeSlot =
            splitDeliveryTime &&
            formatTimeSlots.some(
              (timeSlotItem) =>
                timeSlotItem.start === splitDeliveryTime[0] && timeSlotItem.end === splitDeliveryTime[1],
            );
          if (!isDeliveryTimeMatchToTimeSlot) {
            const firstTimeSlot = formatTimeSlots[0];
            yield put(
              updateDeliveryDetailsAction({
                time: `${firstTimeSlot.start} - ${firstTimeSlot.end}`,
                supplierId: supplierId,
              }),
            );
          }
        }
      }
    }
  } else if (startTime) {
    response = yield call(updateCartDeliveryTime, {
      input: { storeId: store.id, supplierId: KAMEREO_SUPPLIER_ID, startTime: startTime, endTime: endTime },
    });
  }

  if (response) {
    if (response.errors) {
      yield put(updateItemCartActions.failure(response.errors));
    } else {
      response?.cart?.carts?.forEach((cart) => {
        cart.items.every((it) => {
          if (it.item.id === product.id) {
            it.item.category = product.category;
            return false;
          }
          return true;
        });
      });
      yield put(updateItemCartActions.success(response.cart));
    }
  }
}

function* removeItemToCartFlow() {
  while (true) {
    const { payload } = yield take(ActionTypes.REMOVE_ITEM_TO_CART);
    yield put(removeItemCartActions.request());

    if (!payload.storeId) {
      const store = yield call(getStore);
      if (!store) {
        return;
      }
      payload.storeId = store.id;
      delete payload.storeName;
    }
    const response = yield call(removeCartItem, { input: payload });
    if (!response.errors) {
      yield put(removeItemCartActions.success(response.cart));
    } else {
      yield put(removeItemCartActions.failure(response.errors));
    }
  }
}

function* initCategoriesFlow() {
  const response = yield call(preloadCategoriesAndRevalidate);
  if (!response?.errors) {
    const refinedCategories = response.map((category) => {
      if ('children' in category) {
        const childrenWithParent = category.children.map((child) => ({
          ...child,
          parent: category,
        }));
        return {
          ...category,
          children: childrenWithParent,
        };
      }
      return category;
    });
    yield put(categoriesActions.success(refinedCategories));
    return response.map((category) => category.id);
  }
  yield put(categoriesActions.failure(response.errors));
  return null;
}

function* initBrandByCategoryFlow(categoryIds) {
  yield put(brandsActions.request());
  const brandsForHome: ProductBrandsForHomeResponse = yield call(getBrandsWithCategories, {
    categoryIds: categoryIds,
    pagination: { page: 0, size: 6 },
  });
  if (!brandsForHome || !brandsForHome.data?.categories) {
    yield put(brandsActions.failure(new Error('Cannot get brands')));
  } else {
    const brands: ProductBrandsFilterItem[] = (brandsForHome.data.categories || []).filter(
      (brand): brand is ProductBrandsFilterItem => brand !== null,
    );
    const refinedAllBrands = brands.map((brand) => ({
      data: brand.productBrands,
      categoryId: brand.key,
    }));
    yield put(brandsActions.success(refinedAllBrands));
  }
}

function* initNavigationFlow() {
  const categoryIds = yield call(initCategoriesFlow);
  if (categoryIds) {
    yield call(initBrandByCategoryFlow, categoryIds);
  }
}

function* initFavoriteCategoriesFlow() {
  yield put(favoriteCategoriesActions.request());
  const response = yield call(getCategories, {
    isFavorite: true,
  });
  if (!response.errors) {
    yield put(favoriteCategoriesActions.success(response));
  } else {
    yield put(favoriteCategoriesActions.failure(response.errors));
  }
}

function* routeChangeFlow() {
  while (true) {
    yield take('@@router/LOCATION_CHANGE');
    if (window.innerWidth < 1025) {
      yield put(sideNavClose());
    }
  }
}

function* comeFromSourceFlow() {
  const location: Location = yield select(makeSelectLocation());
  const camelizableBranchLinkSearch = camelize<BranchLinkSearch>(qs.parse(location.search));
  const isFromBranchIO =
    'utmCampaign' in camelizableBranchLinkSearch ||
    'utmMedium' in camelizableBranchLinkSearch ||
    'utmSource' in camelizableBranchLinkSearch ||
    'tag' in camelizableBranchLinkSearch;

  if (isFromBranchIO) {
    const { branchMatchId, branchReferrer, ...rest } = camelizableBranchLinkSearch;
    window.sessionStorage.setItem(SessionStorageKey.CAMPAIGN_SOURCE, JSON.stringify(rest));
  }
}

function* loadSuppliersFlow() {
  const suppliers = yield select(selectSuppliers());
  if (suppliers.supplierNoFilter.length === 0) {
    yield put(allSuppliersActions.request());
    const response = yield call(getShortSuppliers, { pagination: { page: 0, size: 1000 } });
    if (response.errors) {
      yield put(allSuppliersActions.failure(response.errors));
    } else {
      yield put(allSuppliersActions.success({ supplierNoFilter: response.data }));
    }
  }
}

function* loadSupplierHasOrdersFlow() {
  const suppliers = yield select(selectSuppliers());
  if (suppliers.supplierHasOrders.length === 0) {
    yield put(suppliersActions.request());
    const response = yield call(getShortSuppliers, {
      filter: { hasOrdersOnly: true },
      pagination: { page: 0, size: 1000 },
    });
    if (response.errors) {
      yield put(suppliersActions.failure(response.errors));
    } else {
      yield put(suppliersActions.success({ supplierHasOrders: response.data }));
    }
  }
}

export function* updateProductQuantity(action) {
  yield put(updateCart(action.payload));
}

export function* updateProductQuantityFlow() {
  while (true) {
    const action = yield take(ActionTypes.UPDATE_PRODUCT_QUANTITY);
    yield call(updateProductQuantity as any, action as any);
  }
}

function getUpdateMultipleItemsQuery(items) {
  const variables = {};
  const mutations = items.map((item, index) => {
    if (!item.quantity || item.quantity === 0) {
      delete item.quantity;
      variables[`item${index}`] = item;
      return `item${index}: cartDeleteItemLight(input: $item${index}) {success}`;
    }
    variables[`item${index}`] = item;
    return `item${index}: cartPutItemLight(input: $item${index}) {success}`;
  });
  return {
    variables: variables,
    mutation: gql`mutation addMultipleItemToCart(${items.map((item, index) =>
      item.quantity ? `$item${index}: CartPutItemInput!` : `$item${index}: CartDeleteItemInput!`,
    )}) {
      ${mutations.join('\n')}
    }`,
  };
}

export function* submitProductQuantityFlow() {
  while (true) {
    yield take(ActionTypes.SUBMIT_MULTIPLE_PRODUCT_QUANTITY);
    const products = yield select(selectUpdateQuantityProducts());
    const store = yield call(getStore);
    if (!store) {
      return;
    }
    const productUpdates: string[] = products.map((data) => data.product.id);
    const dataProducts = products.map((data) => {
      const { quantity, product } = data;
      return {
        storeId: store.id,
        supplierId: product.supplier.id,
        productId: product.id,
        quantity: quantity,
      };
    });
    if (dataProducts.length) {
      yield put(
        addingMultipleToCart(
          productUpdates.reduce((prev, curr) => {
            prev[curr] = true;
            return prev;
          }, {}),
        ),
      );
      const query = getUpdateMultipleItemsQuery(dataProducts);
      yield call(sendRequest, query);
      const response = yield call(getCart, { storeId: store.id });
      if (!response.errors) {
        yield put(cartActions.success(response));
      } else {
        yield put(cartActions.failure(response.errors));
      }
      yield put(
        addedMultipleToCart(
          productUpdates.reduce((prev, curr) => {
            prev[curr] = false;
            return prev;
          }, {}),
        ),
      );
    }
  }
}

function* viewPermissionsFlow() {
  while (true) {
    yield take('@@router/LOCATION_CHANGE');
    const userPermissions = yield select(selectUserPermissions());
    const paths = Object.keys(viewPermissions);
    for (const path of paths) {
      const route = yield select(createMatchSelector(path));
      if (route) {
        const permissions = viewPermissions[route.path];
        if (permissions) {
          let permissionCounter = 0;
          for (const permission of userPermissions) {
            if (permissions.indexOf(permission) > -1) {
              permissionCounter += 1;
            }
          }
          if (permissionCounter !== permissions.length) {
            yield put(push('/'));
          }
        }
      }
    }
  }
}

function* debounceSubmitProductQuantityFlow() {
  yield put(submitMultipleProductQuantity());
}

function* fetchOrderDisabled() {
  while (true) {
    yield take(ActionTypes.FETCH_ORDER_DISABLED_REQUEST);
    const response = yield call(getOrderDisabled);
    if (!response.errors) {
      yield put(orderDisabled.success(response));
    } else {
      yield put(orderDisabled.failure(response.errors));
    }
  }
}

export function* getDataPromotionPopup() {
  const lang = window.localStorage.getItem('lang') || 'vi';
  const viPostId = '20408';
  const enPostId = '20414';
  const url = `https://kamereo.vn/blog/wp-json/wp/v2/pages/${lang === 'vi' ? viPostId : enPostId}`;
  const result = yield fetch(url, {
    method: 'GET',
    headers: {
      'Access-Control-Allow-Origin': 'https://kamereo.vn',
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((error) => {
      return error;
    });
  if (result?.status?.code === '404') {
    yield put(setPromotionDataPopup(null));
  } else {
    yield put(setPromotionDataPopup(result));
  }
}

export function* getSearchProductResult() {
  while (true) {
    const {
      payload: { query, clearPreviousData },
    } = yield take(ActionTypes.SET_SEARCH_PRODUCT_RESULTS_REQUEST);
    yield put(setSearchProductResultsLoading(true));
    const searchProductResultsData = yield select(selectSearchProductResults());
    const search: QueryProductSearchArgs = { sort: [], filter: {}, pagination: { page: 0, size: 20 }, ...query };
    const requestParams: QueryProductSearchArgs = { ...search };
    let requestPage = requestParams.pagination.page;

    if (searchProductResultsData.data.length < searchProductResultsData.totalResults) {
      const currentSearchProductResultsQuantity = searchProductResultsData.data.length;
      const productPerPage = requestParams.pagination.size;
      requestPage = Math.ceil(currentSearchProductResultsQuantity / productPerPage) + 1;
    }
    if (clearPreviousData) {
      yield put(setSearchProductResults.success({}));
      requestPage = 0;
    }
    if (!search.sort.length) {
      requestParams.sort = [{ field: ProductSortField.Name, order: SortDirection.Asc }];
    }
    requestParams.pagination = {
      ...requestParams.pagination,
      page: requestPage ? requestPage - 1 : 0,
    };

    const response = yield call(getProducts, requestParams);
    if (!response.errors) {
      yield put(setSearchProductResults.success(response));
    } else {
      yield put(setSearchProductResults.failure(response.errors));
    }
    yield put(setSearchProductResultsLoading(false));
  }
}

export function* fetchBuyerWebVersion() {
  while (true) {
    yield take(ActionTypes.BUYER_WEB_VERSION);
    const url = `${process.env.APP_URL}/api/v1/buyerWebVersion`;
    const response = yield fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((res) => res.json())
      .then((json) => json)
      .catch((error) => error);
    if (response.version) {
      yield put(setBuyerWebVersion(response));
    }
  }
}

function* refreshAfterCreateStore() {
  while (true) {
    yield take(ActionTypes.REFETCH_BUYER);
    yield call(initBuyerFlow);
    yield call(recentOrders);
  }
}

export function* getUoms() {
  const response = yield call(getAllUoms);

  if (!response.errors) {
    const dataJson = {};
    response.data.forEach((uom) => {
      dataJson[uom.code] = uom;
    });

    window.localStorage.setItem('uoms', JSON.stringify(dataJson));
  } else {
    window.localStorage.removeItem('uoms');
  }
}

export function* getAllOrigins() {
  const response = yield call(getOrigins);

  if (!response.errors) {
    const dataJson = {};
    response.data.forEach((origin) => {
      dataJson[origin.name] = origin;
    });

    window.localStorage.setItem('origins', JSON.stringify(dataJson));
  } else {
    window.localStorage.removeItem('origins');
  }
}

function* handleClearMightFavorite() {
  while (true) {
    yield take(ActionTypes.SET_SELECTED_STORE);
    yield call(getPurchasedItemsMightFavorite, {
      type: 'MightFavoriteProductsWidget',
      widgetId: 'MIGHT_FAVORITE_PRODUCTS',
      item: {
        key: 'MIGHT_FAVORITE_PRODUCTS',
        title: '',
      },
    });
  }
}

export function* handleGetListHolidays() {
  const response = yield call(getListHolidays);
  const specialDates = new Map();
  response.data.forEach((item) => {
    specialDates.set(item.date, item);
  });
  yield put(setSpecialDates(specialDates));
}

export default function* mainLayoutSaga() {
  const loggedIn = !!localStorage.getItem('token');

  yield fork(getUoms);
  yield fork(getAllOrigins);
  yield fork(initNavigationFlow);
  yield fork(routeChangeFlow);
  yield fork(comeFromSourceFlow);
  yield fork(initUserFlow);
  yield fork(getSearchProductResult);
  if (loggedIn) {
    yield takeLatest(ActionTypes.REFRESH_CART, initCartFlow);
    yield fork(initBuyerFlow);
    yield fork(initFavoriteCategoriesFlow);
    yield fork(signoutFlow);
    yield takeEvery(ActionTypes.UPDATE_CART, updateCartFlow);
    yield fork(removeItemToCartFlow);
    yield fork(favoriteFlow);
    yield fork(loadSuppliersFlow);
    yield fork(loadSupplierHasOrdersFlow);
    yield fork(updateProductQuantityFlow);
    yield fork(viewPermissionsFlow);
    yield fork(recentOrders);
    yield fork(submitProductQuantityFlow);
    yield fork(setProductNoteFlow);
    yield fork(registerToken);
    yield fork(markNotificationAsRead);
    yield fork(notificationsFlow);
    yield fork(checkHasUnreadNotification);
    yield fork(fetchOrderDisabled);
    yield fork(fetchBuyerWebVersion);
    yield fork(refreshAfterCreateStore);
    yield fork(handleClearMightFavorite);
    yield fork(handleGetListHolidays);
    yield debounce(2000, ActionTypes.UPDATE_MULTIPLE_PRODUCT_QUANTITY, debounceSubmitProductQuantityFlow);
  } else {
    if (!isGoogleBot() && IS_USE_STATIC_POPUP) {
      yield fork(getDataPromotionPopup);
    }
  }
}
