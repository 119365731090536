import { defineMessages } from 'react-intl';

export const scope = 'app.components.MightFavoriteProductsWidget';

export default defineMessages({
  sectionTitle: {
    id: `${scope}.sectionTitle`,
    defaultMessage: 'Sản phẩm đã mua',
  },
  addAllToFavorite: {
    id: `${scope}.addAllToFavorite`,
    defaultMessage: 'Thêm vào yêu thích',
  },
  addedToFavorite: {
    id: `${scope}.addedToFavorite`,
    defaultMessage: 'Đã thêm vào yêu thích',
  },
  addedToFavoriteSubText: {
    id: `${scope}.addedToFavoriteSubText`,
    defaultMessage: 'Mua lại thật dễ dàng ở những lần sau 🎉',
  },
  goToFavorite: {
    id: `${scope}.goToFavorite`,
    defaultMessage: 'Vào yêu thích',
  },
  addedToFavoriteTextMobile: {
    id: `${scope}.addedToFavoriteTextMobile`,
    defaultMessage: 'Mua lại thật dễ dàng!',
  },
  addedToFavoriteSubTextMobile: {
    id: `${scope}.addedToFavoriteSubTextMobile`,
    defaultMessage: 'Đã thêm tất cả sản phẩm vào yêu thích 🎉 ',
  },
  goToFavoriteMobile: {
    id: `${scope}.goToFavoriteMobile`,
    defaultMessage: 'Đến danh sách Yêu thích ngay',
  },
});
