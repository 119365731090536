import { MapCameraProps } from '@vis.gl/react-google-maps';
import { DEFAULT_CENTER_COORDS } from 'containers/LandingPage/DistributionLocationPage/constants';

export const DEFAULT_COUNTRY_NAME = 'Vietnam';

export const INITIAL_CAMERA: MapCameraProps = {
  center: DEFAULT_CENTER_COORDS,
  zoom: 20,
};

export const DEFAULT_REGION_COORDS = {
  HCM: { lat: 10.762622, lng: 106.660172 },
  HN: { lat: 21.028511, lng: 105.804817 },
  DN: { lat: 16.047079, lng: 108.206230 },
};

export const FIELD_NAME_FULL_TEXT = {
  city: ['location', 'city'],
  district: ['location', 'district'],
  addressDetail: ['location', 'address'],
  formattedAddress: ['location', 'formattedAddress'],
  ward: ['location', 'ward'],
  geoLat: ['location', 'geoLat'],
  geoLon: ['location', 'geoLon'],
  noteForAddress: ['location', 'noteForAddress'],
};

export const DEFAULT_MAP_OPTION = {
  zoomControl: true,
  fullscreenControl: true,
  mapTypeControl: true,
  clickableIcons: true,
  disableDefaultUI: true,
  gestureHandling: 'greedy',
  draggable: true,
};

enum ActionTypes {
  HANDLE_STORE_REQUEST = 'app/CreateStorePopup/HANDLE_STORE_REQUEST',
  HANDLE_STORE_SUCCESS = 'app/CreateStorePopup/HANDLE_STORE_SUCCESS',
  HANDLE_STORE_FAILURE = 'app/CreateStorePopup/HANDLE_STORE_FAILURE',
  FIND_FULL_ADDRESS_REQUEST = 'app/CreateStorePopup/FIND_FULL_ADDRESS_REQUEST',
  FIND_FULL_ADDRESS_SUCCESS = 'app/CreateStorePopup/FIND_FULL_ADDRESS_SUCCESS',
  FIND_FULL_ADDRESS_FAILURE = 'app/CreateStorePopup/FIND_FULL_ADDRESS_FAILURE',
}
export default ActionTypes;
