import { AutoCompleteProps, Flex } from 'antd';
import SVGIcon from 'components/SVGIcon/SVGIcon';
import MapPin from './Map-Pin.svg';
import React, { useCallback, useMemo, useState } from 'react';
import translations from 'translations';
import messages from '../messages';
import styled from 'styles/styled-components';
import { convertStringToSlug } from 'utils/generateProductSlug';

const Title = styled(Flex)`
  color: #aaacb2;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

const BoldTitle = styled(Flex)`
  color: #242527;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
`;

const Description = styled.span`
  & .highlight {
    color: #43a047;
  }
`;

const OptionMapPin = styled(SVGIcon)`
  & svg path {
    stroke: #43a047;
  }
`;

const OptionTitle: React.FC<Readonly<{ title?: string; isBold?: boolean }>> = ({ title, isBold }) =>
  isBold ? <BoldTitle align="center">{title}</BoldTitle> : <Title align="center">{title}</Title>;

export const OptionLabel: React.FC<Readonly<{ label?: React.ReactNode }>> = ({ label }) => (
  <Flex gap={4} align="center">
    <SVGIcon src={MapPin} />
    <Description>{label}</Description>
  </Flex>
);

const OptionOpenExtendedMap: React.FC<Readonly<{}>> = () => (
  <Flex gap={4} align="center" justify="center">
    <OptionMapPin src={MapPin} renderAsImg={false} />
    <Description style={{ color: '#43a047' }}>{translations(messages.optionOpenExtendedMap)}</Description>
  </Flex>
);

const INITIAL_OPTIONS = [
  {
    label: <OptionTitle title={translations(messages.optionTitle)} />,
    options: [
      {
        label: <OptionOpenExtendedMap />,
        value: 'open-extended-map',
      },
    ],
  },
];

export interface PlaceAutoSuggestion {
  description: string;
  placeId: string;
}

const useAutoSuggestion = () => {
  const [autoSuggestions, setAutoSuggestions] = useState<PlaceAutoSuggestion[]>([]);
  const [isFetchingSuggestions, setIsFetchingSuggestions] = useState(true);
  const [isRewordingTitle, setIsReWordingTitle] = useState(false);
  const memoizedSetAutoSuggestions = useCallback(setAutoSuggestions, []);
  const suggestionsOption: AutoCompleteProps['options'] = useMemo(() => {
    if (autoSuggestions.length) {
      const options = autoSuggestions?.map((item: PlaceAutoSuggestion) => ({
        value: item.description,
        label: <OptionLabel label={item.description} />,
      }));
      return INITIAL_OPTIONS.map((opt) => ({
        ...opt,
        options: options.concat(opt.options),
        label: isRewordingTitle ? (
          <OptionTitle isBold={true} title={translations(messages.suggestSelectAddress)} />
        ) : (
          opt.label
        ),
      }));
    }
    return INITIAL_OPTIONS;
  }, [autoSuggestions, isRewordingTitle]);

  const handleMatchedSuggestion = useCallback(
    (address: string, placeId?: string) => {
      if (address && suggestionsOption.length) {
        return suggestionsOption.map((item) => ({
          ...item,
          options: item.options.map((opt) => {
            if (opt.label && typeof opt.label.props.label === 'string') {
              let highlightedAddress = opt.label.props.label;
              if (address === highlightedAddress || placeId) {
                highlightedAddress = `<span class="highlight">${highlightedAddress}</span>`;
              } else {
                const words = address.split(' ').filter(Boolean);
                const highlightedWords = highlightedAddress.split(' ').filter(Boolean);
                const highlightedWordsMap = new Map();
                const excludedMatchTags = ['<span class="highlight">', '</span>'];
                highlightedWords.forEach((word) => {
                  const escapedWord = word?.replace(/[.*+?^${}()|[\]\\,]/g, '');
                  const unsignedWord = convertStringToSlug(escapedWord, true);
                  highlightedWordsMap.set(unsignedWord, word);
                });
                words.forEach((word) => {
                  const escapedWord = word?.replace(/[.*+?^${}()|[\]\\,]/g, '');
                  const unsignedWord = convertStringToSlug(escapedWord, true);
                  let originalWord = highlightedWordsMap.get(unsignedWord)?.replace(/[.*+?^${}()|[\]\\,]/g, '');
                  if (!originalWord) {
                    originalWord = highlightedWordsMap
                      .entries()
                      .find(([key, value]) => {
                        return key.includes(unsignedWord);
                      })?.[1]
                      ?.slice(0, unsignedWord.length);
                  }
                  const regex = new RegExp(`(${escapedWord}${originalWord ? `|${originalWord}` : ''}),?`, 'giu');
                  highlightedAddress = highlightedAddress?.replaceAll(regex, (match) => {
                    if (excludedMatchTags.some((tag) => tag.includes(match))) {
                      return match;
                    }
                    return `<span class="highlight">${match}</span>`;
                  });
                });
              }

              return {
                ...opt,
                label: (
                  <Flex gap={4} align="center">
                    <SVGIcon src={MapPin} />
                    <Description dangerouslySetInnerHTML={{ __html: highlightedAddress }} />
                  </Flex>
                ),
              };
            }
            return opt;
          }),
        }));
      }
      return [];
    },
    [suggestionsOption, isRewordingTitle],
  );

  return {
    autoSuggestions: autoSuggestions,
    isFetchingSuggestions: isFetchingSuggestions,
    isRewordingTitle: isRewordingTitle,
    setAutoSuggestions: memoizedSetAutoSuggestions,
    handleMatchedSuggestion: handleMatchedSuggestion,
    setIsFetchingSuggestions: setIsFetchingSuggestions,
    setIsReWordingTitle: setIsReWordingTitle,
  };
};

export default useAutoSuggestion;
