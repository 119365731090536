enum ActionTypes {
  FETCH_PROVINCE_REQUEST = 'app/AddressForMap/FETCH_PROVINCE_REQUEST',
  FETCH_PROVINCE_SUCCESS = 'app/AddressForMap/FETCH_PROVINCE_SUCCESS',
  FETCH_PROVINCE_FAILURE = 'app/AddressForMap/FETCH_PROVINCE_FAILURE',
  FETCH_DISTRICT_REQUEST = 'app/AddressForMap/FETCH_DISTRICT_REQUEST',
  FETCH_DISTRICT_SUCCESS = 'app/AddressForMap/FETCH_DISTRICT_SUCCESS',
  FETCH_DISTRICT_FAILURE = 'app/AddressForMap/FETCH_DISTRICT_FAILURE',
  FETCH_WARDS_REQUEST = 'app/AddressForMap/FETCH_WARDS_REQUEST',
  FETCH_WARDS_SUCCESS = 'app/AddressForMap/FETCH_WARDS_SUCCESS',
  FETCH_WARDS_FAILURE = 'app/AddressForMap/FETCH_WARDS_FAILURE',
  GET_DISTRICT_BY_PROVINCE = 'app/AddressForMap/GET_DISTRICT_BY_PROVINCE',
  GET_WARDS_BY_DISTRICT = 'app/AddressForMap/GET_WARDS_BY_DISTRICT',
}
export default ActionTypes;
