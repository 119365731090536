import * as Sentry from '@sentry/browser';

import React, { Component } from 'react';

import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import Input from 'components/TrackingComponents/Input';
import { setAlcoholState } from 'containers/MainLayout/actions';
import debounce from 'lodash.debounce';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { ApplicationRootState } from 'types';
import { Category, Product, TrackingAddToCartAddFromType } from 'types/schema';
import utilMessages from 'utils/messages';
import messages from './messages';
import { QuantityButton } from './QuantityButton';
import { QuantityInnerInput } from './QuantityInnerInput';
import { QuantityWrapper } from './QuantityWrapper';
import { selectUnder18State } from 'containers/MainLayout/selectors';

interface StateProps {
  isUnder18: boolean;
}
interface IDispatchProps {
  setAlcoholState: (data: boolean) => void;
}

export const QuantityInputContainer = styled.div<any>`
  ${({ willOverflow, isHomePage }) => {
    let result = '';
    if (willOverflow) {
      result = `
      height: 32px;
      overflow: visible;
      position: relative;
      `;
    }
    if (isHomePage) {
      result
        ? (result = `
       ${result}
       flex-grow: 1;
      `)
        : (result = `flex-grow: 1;`);
    }
    return result;
  }};
`;

const ENTER_KEYCODE = 13;
const TAB_KEYCODE = 9;
const ARROW_UP_KEYCODE = 38;
const ARROW_DOWN_KEYCODE = 40;

export interface InputProps {
  loggedIn: boolean;
  productId: string;
  category?: Category | null;
  min?: number;
  max?: number;
  value?: number;
  size?: string;
  willOverflow?: boolean;
  onChange?: (value: any) => void;
  adding?: boolean;
  allowDecimal: boolean;
  orderDisabled: boolean;
  goToSignIn: () => void;
  addFrom: TrackingAddToCartAddFromType;
  tag?: string | null;
  product?: Product;
}
interface InputState {
  quantity: number;
  optimisticQuantity: string;
  adding?: boolean;
  directionPositive: boolean;
  touchCounter: number;
  incorrectQuantity: boolean;
  invalidQuantity: boolean;
  notAllowedDecimal: boolean;
}

const roundNumber = (value: number) => Number(value.toFixed(3));
type Props = InputProps & IDispatchProps;

export class QuantityInput extends Component<Props, InputState> {
  public quantityInput: any;
  public timeoutId: any;

  public static defaultProps = {
    min: 0,
    max: Infinity,
    value: 1,
    size: '',
    overflow: false,
    directionPositive: true,
    allowDecimal: false,
    onChange: (value: any) => {},
  };

  public componentWillReceiveProps = (nextProps) => {
    this.setState({
      quantity: nextProps.value,
      adding: nextProps.adding,
    });
    this.asyncUpdateQuantity.cancel();
    this.asyncUpdateQuantity(nextProps.adding, nextProps.value);
  };

  public asyncUpdateQuantity = debounce((adding, value) => {
    if (!adding) {
      this.setState({
        optimisticQuantity: value,
      });
    }
  }, 200);

  public isAlcoholProduct = () => {
    const isAlcoholCategory = this.props.product?.category?.parent?.name === 'Alcohol';
    const isUnder18FromLocalStorage = window.localStorage.getItem('isUnder18')
      ? window.localStorage.getItem('isUnder18') === 'true'
      : true;
    const isUnder18FromSessionStorage = window.sessionStorage.getItem('isUnder18')
      ? window.sessionStorage.getItem('isUnder18') === 'true'
      : true;
    const isLogin = window.localStorage.getItem('token');
    if (isLogin) {
      if (isAlcoholCategory && isUnder18FromLocalStorage) {
        this.props.setAlcoholState(true);
        return true;
      }
    } else {
      if (isAlcoholCategory && isUnder18FromSessionStorage) {
        this.props.setAlcoholState(true);
        return true;
      }
    }
    return false;
  };

  constructor(props) {
    super(props);
    this.state = {
      quantity: props.value || 0,
      optimisticQuantity: props.value || 0,
      adding: false,
      touchCounter: 0,
      directionPositive: true,
      incorrectQuantity: false,
      notAllowedDecimal: false,
      invalidQuantity: false,
    };
    this.quantityInput = React.createRef();
  }

  public computeQuantity = (value: number) => {
    const { min = 0, max = Infinity } = this.props as any;
    const { quantity, optimisticQuantity } = this.state;

    if (value > max || value < min) {
      return Number(optimisticQuantity) || quantity;
    }

    return value;
  };

  public setQuantity = (desiredQuantity, count = true) => {
    if (!this.props.loggedIn) {
      this.props.goToSignIn();
      return;
    }

    const quantity = roundNumber(this.computeQuantity(desiredQuantity));

    const { optimisticQuantity, directionPositive, touchCounter } = this.state;
    const oldOptimisticQuantity = Number(optimisticQuantity);

    if (oldOptimisticQuantity === quantity) {
      if (oldOptimisticQuantity !== this.state.quantity) {
        this.asyncChange(oldOptimisticQuantity);
        this.asyncChange.flush();
      }
      return;
    }

    const changeDirection = directionPositive ? quantity < oldOptimisticQuantity : quantity > oldOptimisticQuantity;
    const newDirectionPositive = changeDirection ? !directionPositive : directionPositive;
    const newTouchCounter = changeDirection ? 0 : count ? touchCounter + 1 : 0;

    this.setState({
      optimisticQuantity: String(quantity),
      touchCounter: newTouchCounter,
      directionPositive: newDirectionPositive,
    });

    this.asyncChange(quantity);
  };

  public asyncChange = debounce((quantity) => {
    let value = +quantity;
    if (value < 0.1 && value !== 0) {
      value = 0.1;
    }
    const newValue = Number(value.toFixed(1));

    if (this.props.onChange) {
      this.props.onChange(+newValue);
    }

    this.setState({
      quantity: +newValue,
      optimisticQuantity: String(newValue),
      touchCounter: 0,
    });
  }, 1000);

  public handleKeyDown = (event: any) => {
    if (event.keyCode === ENTER_KEYCODE || event.keyCode === TAB_KEYCODE) {
      this.updateQuantity(Number(this.state.optimisticQuantity));
      this.asyncChange.flush();
      this.quantityInput.current.blur();
    } else if (event.keyCode === ARROW_DOWN_KEYCODE) {
      event.preventDefault();
      this.downQuantity(false);
    } else if (event.keyCode === ARROW_UP_KEYCODE) {
      event.preventDefault();
      this.upQuantity(false);
    }
  };

  public updateQuantity = (value: number) => {
    let newQuantity = 0;
    try {
      newQuantity = value || 0;
      this.setQuantity(newQuantity);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  public concludeEdit = () => {
    const isAlcoholProduct = this.isAlcoholProduct();
    if (isAlcoholProduct) {
      return;
    }
    const { optimisticQuantity } = this.state;
    this.updateQuantity(Number(optimisticQuantity));
  };

  public downQuantity = (count = true) => {
    const isAlcoholProduct = this.isAlcoholProduct();
    if (isAlcoholProduct) {
      return;
    }
    const { optimisticQuantity } = this.state;
    const newQuantity = Number(optimisticQuantity) >= 1 ? Number(optimisticQuantity) - 1 : 0;
    this.setQuantity(newQuantity, count);
  };

  public upQuantity = (count = true) => {
    const isAlcoholProduct = this.isAlcoholProduct();
    if (isAlcoholProduct) {
      return;
    }
    const { optimisticQuantity } = this.state;
    const newQuantity = Number(optimisticQuantity) + 1;
    this.setQuantity(newQuantity, count);
  };

  public preventDefaultEvent = (event: Event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  public inputChanged = (event: any) => {
    let inputValue = event.target.value;

    if (inputValue.includes(',')) {
      inputValue = inputValue.replace(',', '.');
    }

    if (!/^\d*(\.)?(\d{0,2})?$/.test(inputValue)) {
      this.setState({ invalidQuantity: true });
      setTimeout(() => {
        this.setState({ invalidQuantity: false });
      }, 2000);
      return;
    }

    if (!this.props.allowDecimal && !/^\d*$/.test(inputValue)) {
      this.setState({ notAllowedDecimal: true });
      setTimeout(() => {
        this.setState({ notAllowedDecimal: false });
      }, 2000);
      return;
    }

    if (!/^\d*(\.)?(\d{0,1})?$/.test(inputValue)) {
      this.setState({ incorrectQuantity: true });
      setTimeout(() => {
        this.setState({ incorrectQuantity: false });
      }, 2000);
      return;
    }

    this.setState({ optimisticQuantity: inputValue });
  };

  public cancelUpdate = (event: any) => {
    this.asyncChange.cancel();
  };

  public messageTooltip = () => {
    const { touchCounter, incorrectQuantity, notAllowedDecimal, invalidQuantity } = this.state;
    switch (true) {
      case touchCounter > 5:
        return <FormattedMessage {...messages.inputQuantity} />;
      case incorrectQuantity:
        return <FormattedMessage {...utilMessages.decimalWithOneNumber} />;
      case notAllowedDecimal:
        return <FormattedMessage {...messages.notAllowedDecimal} />;
      case invalidQuantity:
        return <FormattedMessage {...messages.invalidQuantity} />;
      default:
        return '';
    }
  };

  public render() {
    const { productId, willOverflow, size = '', min = 0, max = Infinity, orderDisabled } = this.props;
    const {
      quantity,
      optimisticQuantity,
      adding,
      touchCounter,
      incorrectQuantity,
      notAllowedDecimal,
      invalidQuantity,
    } = this.state;

    const visibleTooltip = touchCounter > 5 || incorrectQuantity || notAllowedDecimal || invalidQuantity;

    return (
      <QuantityInputContainer willOverflow={willOverflow} isHomePage={window.location.pathname === '/'}>
        <QuantityWrapper
          size={size}
          willOverflow={willOverflow}
          onKeyDown={this.handleKeyDown}
          onClick={this.preventDefaultEvent}
        >
          <QuantityButton
            data-test="minus-button"
            className="minus"
            onClick={this.downQuantity}
            disabled={orderDisabled || quantity < min || adding}
            loading={adding}
            btnSize={size}
            icon={<MinusOutlined />}
          />
          <Tooltip data-test="tool-tip" open={visibleTooltip} placement="top" title={<>{this.messageTooltip()}</>}>
            <Input
              data-test="quantity-input"
              key={`quantity-input-${productId}`}
              onRef={this.quantityInput}
              InputComponent={QuantityInnerInput}
              trackingCategory="Quantity Input"
              trackingAction="Enter Quantity"
              type="text"
              onKeyDown={this.handleKeyDown}
              value={optimisticQuantity}
              onChange={this.inputChanged}
              onBlur={this.concludeEdit}
              onFocus={this.cancelUpdate}
              disabled={orderDisabled || adding}
              readOnly={adding}
              min={0}
              style={{
                transition: 'opacity 0.2s ease',
                backgroundColor: '#FAFAFA',
                color: 'inherit',
                fontSize: '16px',
                width: 44,
              }}
            />
          </Tooltip>
          <QuantityButton
            data-test="plus-button"
            className="plus"
            onClick={this.upQuantity}
            disabled={orderDisabled || quantity + 1 > max || adding}
            loading={adding}
            icon={<PlusOutlined />}
            btnSize={size}
          />
        </QuantityWrapper>
      </QuantityInputContainer>
    );
  }
}

const mapStateToProps = createStructuredSelector<ApplicationRootState, StateProps>({
  isUnder18: selectUnder18State(),
});

function mapDispatchToProps(dispatch: Dispatch): IDispatchProps {
  return {
    setAlcoholState: (data) => dispatch(setAlcoholState(data)),
  };
}
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(QuantityInput);
