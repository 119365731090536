// context/GoogleMapsContext.tsx
import React, { createContext, useCallback, useContext, useState } from 'react';
import {
  APIProvider,
  MapCameraChangedEvent,
  MapCameraProps,
  useApiLoadingStatus,
  APILoadingStatus,
} from '@vis.gl/react-google-maps';
import { Skeleton, Space } from 'antd';

interface GoogleMapsContextProps {
  cameraProps: Partial<MapCameraChangedEvent['detail']>;
  setCameraProps: (cameraProps: Partial<MapCameraChangedEvent['detail']>) => void;
  handleCameraChange: (ev: MapCameraChangedEvent) => void;
}

interface APIComponentProps {
  apiKey: string;
  language: string;
  region: string;
  children: React.ReactNode;
}

export type AddressLocation = {
  province: string;
  district: string;
  ward: string;
  // surroundDistrict: string[];
  // surroundWard: string[];
  formattedAddress: string;
  pluscode: string;
  isPOI: boolean;
  fullAddress: string;
  description: string
};

interface AddressLocationContextType {
  addressComponents: AddressLocation | null;
  setAddressComponents: React.Dispatch<React.SetStateAction<AddressLocation | null>>;
}

const AddressLocationContext = createContext<AddressLocationContextType | undefined>(undefined);

export const useAddressLocation = (): AddressLocationContextType => {
  const context = useContext(AddressLocationContext);
  if (!context) {
    throw new Error('useAddressLocation must be used within an GoogleMapsContextProvider');
  }
  return context;
};

const GoogleMapsContext = createContext<GoogleMapsContextProps | undefined>(undefined);

export const useGoogleMapsContext = () => {
  const context = useContext(GoogleMapsContext);
  if (!context) {
    throw new Error('useGoogleMapsContext must be used within a GoogleMapsProvider');
  }
  return context;
};

export const GoogleMapsContextProvider: React.FC<{
  apiKey: string;
  mapCameraProps: MapCameraProps;
  mapOptions?: google.maps.MapOptions;
  libraries?: string[];
  language?: string;
  region?: string;
}> = ({ apiKey, mapCameraProps, libraries, language = 'en', region = '', children }) => {
  const [cameraProps, setCameraProps] = useState<Partial<MapCameraChangedEvent['detail']>>(mapCameraProps);
  const [addressComponents, setAddressComponents] = useState<AddressLocation | null>(null);
  const handleCameraChange = useCallback(
    (ev: MapCameraChangedEvent) => {
      const bounds = new google.maps.LatLngBounds();
      const newCenter = ev.detail.center;
      bounds.extend(newCenter);
      setCameraProps({
        ...ev.detail,
      });
    },
    [setCameraProps],
  );

  return (
    <GoogleMapsContext.Provider
      value={{ cameraProps: cameraProps, setCameraProps: setCameraProps, handleCameraChange: handleCameraChange }}
    >
      <APIProvider
        apiKey={apiKey || ''}
        version="beta"
        authReferrerPolicy="origin"
        libraries={libraries}
        language={language}
        region={region}
      >
        <APIComponent apiKey={apiKey} language={language} region={region}>
          <AddressLocationContext.Provider
            value={{ addressComponents: addressComponents, setAddressComponents: setAddressComponents }}
          >
            {children}
          </AddressLocationContext.Provider>
        </APIComponent>
      </APIProvider>
    </GoogleMapsContext.Provider>
  );
};

const APIComponent: React.FC<APIComponentProps> = ({ apiKey, language, region, children }) => {
  // const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const status = useApiLoadingStatus();

  // useEffect(() => {
  //   const loadGoogleMapsScript = async () => {
  //     try {
  //       await loadScript(apiKey, language, region);
  //       setIsScriptLoaded(true);
  //     } catch (error) {}
  //   };

  //   loadGoogleMapsScript();
  // }, [apiKey, language, region]);

  // if (!isScriptLoaded) {
  //   return <Skeleton.Image active style={{ height: 180, width: '100%' }} />;
  // }

  switch (status) {
    case APILoadingStatus.FAILED:
      return <h2>Map cannot be loaded right now, sorry.</h2>;
    case APILoadingStatus.LOADING:
      // return <Skeleton.Image active style={{ height: 180, width: '100%', minWidth: '100%' }} />;
      return (
        <Space size="middle" style={{ width: '100%', height: 180 }} direction="vertical">
          {Array.from({ length: 3 }).map((_, index) => (
            <Skeleton.Input key={`Map-skeleton-${index}`} active size="default" block />
          ))}
        </Space>
      );
    case APILoadingStatus.LOADED:
    default:
      return <>{children}</>;
  }
};
