import { Category } from 'types/schema';
import findCategory from 'utils/findCategory';
import { parse } from './queryString';

/**
 * Return a Category base on the category search query
 * @param categories is an array of Category type, location is a location from RouteProps in react router
 * @returns A category based on search property in location object
 */

const getSelectedCategory = (searchQuery: string, categories?: Category[]): Category | undefined => {
  const search = parse(searchQuery);
  if (!categories || categories.length === 0 || !search.filter || !search.filter.categoryIds) {
    return undefined;
  }

  const categoryId = search.filter.categoryIds?.[0];
  return findCategory(categories, categoryId);
};
export default getSelectedCategory;
