import styled from 'styled-components';

export const QuantityWrapper = styled.div<any>`
  background-color: #fafafa;
  border-radius: 25px;
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  height: ${({ size }) => (size === 'small' ? '32px' : '40px')};
`;
