import { flatMap, get, identity, invert, pickBy, set } from 'lodash';

import { compose } from 'redux';
import decodeQuery from './decodeQuery';
import qs, { IParseOptions } from 'qs';
import { ProductFilter } from 'types/schema';

const stringifyMaping = {
  'filter.categoryName': 'category',
  'filter.categoryIds': 'categoryIds',
  'filter.query': 'q',
  'filter.supplierNames': 'suppliers',
  'filter.supplierName': 'supplier',
  'filter.preservation': 'preservation',
  'filter.hasOrdersOnly': 'orderedOnly',
  'filter.isFavorite': 'favorite',
  'filter.storeId': 'storeId',
  'filter.status': 'status',
  'filter.statuses': 'statuses',
  'pagination.page': 'page',
  'pagination.size': 'size',
  'filter.orderDateRange.start': 'orderDateStart',
  'filter.orderDateRange.end': 'orderDateEnd',
  'filter.deliveryDateRange.start': 'deliveryDateStart',
  'filter.deliveryDateRange.end': 'deliveryDateEnd',
  'filter.tags': 'tags',
  'filter.withexclusive': 'withexclusive',
};

const parseMaping = invert(stringifyMaping);

const forceFilterQueryToString = <T extends { filter?: ProductFilter }>(object: T): T => {
  if (get(object, 'filter.query')) {
    return {
      ...object,
      filter: pickBy({ ...object.filter, query: `${get(object, 'filter.query')}` }, identity),
    };
  }
  return object;
};

const getKeys = (obj, prefix = '') => {
  return flatMap(Object.keys(obj || {}), (key) => {
    const keyPath = prefix ? `${prefix}.${key}` : key;
    if (typeof obj[key] === 'object' && !Array.isArray(obj[key]) && !(obj[key] instanceof Date)) {
      return getKeys(obj[key], keyPath);
    }
    return keyPath;
  });
};

const mapKey = (mapping) => (obj) => {
  return getKeys(obj).reduce((agg, key) => {
    const newKey = mapping[key] || key;
    set(agg, newKey, get(obj, key));
    return agg;
  }, {});
};

const forceFilterSupplierToArray = <T extends { filter?: ProductFilter }>(object: T): T => {
  if (get(object, 'filter.supplierNames')) {
    let supplierNames = get(object, 'filter.supplierNames');
    if (typeof supplierNames === 'string') {
      supplierNames = [supplierNames];
    }
    return { ...object, filter: pickBy({ ...object.filter, supplierNames: supplierNames }, identity) };
  }
  return object;
};

const forceFilterTagsToArray = <T extends { filter?: ProductFilter }>(object: T): T => {
  if (get(object, 'filter.tags')) {
    let tags = get(object, 'filter.tags');
    if (typeof tags === 'string') {
      tags = tags.split(',');
    }
    return { ...object, filter: pickBy({ ...object.filter, tags: tags }, identity) };
  }
  return object;
};

const forceFilterCategoryIdsToArray = <T extends { filter?: ProductFilter }>(object: T): T => {
  if (get(object, 'filter.categoryIds')) {
    let categoryIds = get(object, 'filter.categoryIds');
    if (typeof categoryIds === 'number') {
      categoryIds = [categoryIds];
    }
    return { ...object, filter: pickBy({ ...object.filter, categoryIds: categoryIds }, identity) };
  };
  return object;
}

export const parse = <T = any>(search?: string, options?: IParseOptions): T => {
  return compose(
    forceFilterSupplierToArray,
    forceFilterQueryToString,
    forceFilterTagsToArray,
    forceFilterCategoryIdsToArray,
    mapKey(parseMaping),
  )(
    search &&
    qs.parse(search, {
      ignoreQueryPrefix: true,
      decoder: decodeQuery,
      ...options,
    }),
  );
};

export const stringify = (object: object, options?: object) => {
  return qs.stringify(mapKey(stringifyMaping)(object), {
    encodeValuesOnly: true,
    ...options,
    arrayFormat: 'repeat',
  });
};
