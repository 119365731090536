import gql from 'graphql-tag';

export default gql`
  query listHolidays {
    listHolidays {
      data {
        date
        holidayNameVn
        holidayNameEn
      }
    }
  }
`;
