import { Col, Form, Modal, Row, Input as AInput, Flex, message } from 'antd';
import React, { FC, useMemo, useEffect, useState } from 'react';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Dispatch, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styles/styled-components';
import { ApplicationRootState } from 'types';
import utilsMessages from 'utils/messages';
import orderMessage from '../../containers/OrdersPage/messages';
import storeMessage from '../../components/StoreForm/messages';

import createStoreBanner from './createStoreThumbnail.png';
import Input from 'components/TrackingComponents/Input';

import { selectBuyer } from 'containers/MainLayout/selectors';
import selectCreateStorePopupComponent from './selectors';
import { handleStore, findFullAddress } from './action';
import reducer from './reducer';
import saga from './saga';
import translations from 'translations';
import SVGIcon from '../SVGIcon/SVGIcon';
import LightBuldCircle from './LightbulbCircle.svg';
import Dismiss from './images/Dismiss.svg';

import StoreMapWrapper from './components/StoreMapWrapper';
import { DEFAULT_REGION_COORDS, FIELD_NAME_FULL_TEXT, INITIAL_CAMERA } from './constants';
import messages from './messages';
import Button from 'components/Button/Button';
import { Store } from '../../types/schema';
import useWatchFieldChange from './hooks/useWatchFieldChange';
import media from 'utils/mediaStyle';
import AddressForMap from 'components/AddressForMap';
import { GoogleMapsContextProvider } from 'containers/LandingPage/DistributionLocationPage/components/Map/hooks/GoogleMapScriptContext';
import { CheckCircleOutlined } from '@ant-design/icons';

// import LocationFormSection from '../../components/LocationFormSection';
const { confirm } = Modal;
interface IStateProps {
  buyer: any;
  createStorePopupState: any;
}

interface IDispatchProps {
  handleStoreInfo: (payload: any) => void;
  findFullAddress: (payload: any) => void;
}

interface IOwnProps {
  isUpdate?: boolean;
  currentStoreData?: Store;
}

const PopupCreateStore = styled(Modal)`
  width: 100% !important;
  max-width: 652px;
  .ant-modal-content {
    padding: 24px !important;
  }
  top: 0;
  ${media.sm`
    top: 100px;
  `}
`;

const CreateStoreBannerImage = styled.div`
  background-image: url(${createStoreBanner});
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  padding-top: 30%;
`;

const FormItem = styled(Form.Item)`
  margin-bottom: 12px !important;
`;
const TitleWrapper = styled.div`
  text-align: center;
  margin-inline: 0;
  margin-bottom: 20px;
`;
const FormTitle = styled.h3`
  font-size: 20px;
  font-weight: 500;
`;
const FormSubTitle = styled.span`
  font-size: 14px;
  font-weight: 400;
`;
const Note = styled.div`
  display: flex;
  align-items: start;
  gap: 4px;
  background: #fffbeb;
  padding: 6px 4px;
  border-radius: 3px;
`;

const NoteIcon = styled(SVGIcon)`
  & svg path {
    stroke: #f5ce47;
  }
`;

const NoteText = styled.div`
  line-height: 16px;
  font-size: 14px;
  font-weight: 400;
`;

const Spacing = styled.div`
  width: 100%;
  height: ${({ height }) => height || '36px'};
`;

const StoreNameViewOnly = styled(Col)`
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-align: center;
`;
const SeparateLine = styled.div`
  position: relative;
  top: 3px;
  width: 1px;
  height: 18px;
  background-color: #cacbce;
  margin: 0 auto;
`;

const AntdInput = styled(AInput)<any>``;

type Props = IStateProps & IDispatchProps & IOwnProps;
const CreateStorePopup: FC<Props> = (props) => {
  const { buyer, handleStoreInfo, findFullAddress, createStorePopupState, isUpdate, currentStoreData } = props;
  const regions = buyer?.region ? [buyer?.region] : [];
  const [form] = Form.useForm();
  const [openExtendedMap, setOpenExtendedMap] = useState(false);
  const [placeId, setPlaceId] = useState<string>('');
  const [coords, setCoords] = useState<google.maps.LatLngLiteral | null>(null);
  const [isOutOfRegion, setIsOutOfRegion] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [messageApi, contextHolder] = message.useMessage();
  const { isReadyToSubmit, isMissingWard } = useWatchFieldChange(form, regions);

  const currentAddressDetail = isUpdate ? currentStoreData?.location?.address : buyer?.location?.address;

  // useEffect(() => {
  //   if (currentStoreData && Object.keys(currentStoreData).length && isUpdate) {
  //     if (!currentStoreData?.provinceId && currentStoreData?.districtId && currentStoreData?.wardId) {
  //       findFullAddress({
  //         district: currentStoreData?.districtId,
  //         ward: currentStoreData?.wardId,
  //         cb: (data: FullAddress) => {
  //           setFullAddress(data);
  //         },
  //       });
  //     } else {
  //       setFullAddress({
  //         provinceCode: currentStoreData?.provinceId,
  //         districtCode: currentStoreData?.districtId,
  //         wardCode: currentStoreData?.wardId,
  //       });
  //     }
  //   }
  // }, [currentStoreData, isUpdate]);

  const isOpenPopup = useMemo(() => {
    if (isUpdate) {
      return currentStoreData && currentStoreData.isForceUpdate;
    }
    return !buyer.loading && buyer?.id && !buyer.stores?.length;
  }, [buyer, currentStoreData, isUpdate]);

  useEffect(() => {
    form.setFieldValue('geoLat', coords?.lat);
    form.setFieldValue('geoLon', coords?.lng);
  }, [coords?.lat, coords?.lng]);

  useEffect(() => {
    const defaultCoords = regions?.length ? DEFAULT_REGION_COORDS[regions[0]] : null;
    setCoords(defaultCoords);
  }, [regions[0]]);

  const onSubmit = () => {
    form.submit();
  };

  const handleShowWarning = () => {
    if (!openExtendedMap) {
      messageApi.open({
        type: 'error',
        content: (
          <FormattedMessage
            {...messages.warningTitle}
            values={{
              region: translations(utilsMessages[regions?.[0] || '']),
            }}
          />
        ),
        icon: (
          <SVGIcon
            style={{ cursor: 'pointer' }}
            src={Dismiss}
            renderAsImg={false}
            onClick={() => messageApi.destroy()}
          />
        ),
        className: 'out-of-region-warning',
        style: {
          marginInline: 'auto',
        },
      });
    }
  };

  const formatPhoneNumber = () => {
    if (form.getFieldError('telephone').length || !form.getFieldValue('telephone')) {
      return;
    }
    const regex = /[()+\-.\s]/g;
    const phoneNumberValue = form.getFieldValue('telephone');
    form.setFieldValue('telephone', phoneNumberValue.replace(regex, ''));
  };

  const showPromiseConfirm = () => {
    confirm({
      icon: null,
      title: `${translations(messages.storeLocationConfirm)}`,
      content: `${translations(messages.storeLocationConfirmDescription)}`,
      onOk: async () => {
        form.submit();
      },
      style: { textAlign: 'center', color: '#242527', fontSize: 16, fontWeight: 400, lineHeight: 24 },
      onCancel: () => {},
      centered: true,
      okButtonProps: { loading: createStorePopupState.loading, style: { width: '100%', background: '#43A047' } },
      cancelButtonProps: { style: { width: '100%' } },
      okText: `${translations(messages.confirm)}`,
      cancelText: `${translations(messages.notConfirm)}`,
      footer: (_, { OkBtn, CancelBtn }) => (
        <Flex gap={12}>
          <CancelBtn />
          <OkBtn />
        </Flex>
      ),
    });
  };

  useEffect(() => {
    if (isOpenPopup) {
      if (isUpdate && currentStoreData && Object.keys(currentStoreData).length) {
        form.setFieldsValue({
          name: currentStoreData?.name || undefined,
          telephone: currentStoreData?.telephone || undefined,
        });
      } else {
        form.setFieldsValue({
          name: buyer.name || undefined,
          telephone: buyer.telephone || undefined,
        });
      }
    }
  }, [buyer.id, isOpenPopup]);
  return (
    <GoogleMapsContextProvider
      apiKey={process.env.GOOGLE_MAP_API || ''}
      language="vi"
      region="VN"
      libraries={['maps', 'geocoding', 'marker', 'places']}
      mapCameraProps={INITIAL_CAMERA}
    >
      {contextHolder}
      <PopupCreateStore
        open={isOpenPopup}
        closeIcon={false}
        footer={[
          <Button
            key="submit"
            type="bg-primary"
            onClick={onSubmit}
            disabled={!isReadyToSubmit && loading}
            loading={createStorePopupState?.loading || loading}
            style={{
              cursor: isReadyToSubmit && !loading ? 'pointer' : 'not-allowed',
              opacity: isReadyToSubmit && !loading ? 1 : 0.5,
            }}
            icon={isReadyToSubmit && !loading ? <CheckCircleOutlined /> : ''}
            block
          >
            <FormattedMessage {...utilsMessages.finish} />
          </Button>,
        ]}
        confirmLoading={createStorePopupState?.loading}
      >
        {/* <CreateStoreBannerImage /> */}
        <TitleWrapper>
          <FormTitle>
            <FormattedMessage {...utilsMessages.updateStoreAddress} />
          </FormTitle>
          {isReadyToSubmit && isMissingWard && placeId && (
            <FormSubTitle>
              <FormattedMessage {...utilsMessages.storeConfimSubText} />
              <p style={{ color: '#E56910' }}>
                <FormattedMessage {...utilsMessages.updateStoreNoteText} />
              </p>
            </FormSubTitle>
          )}
        </TitleWrapper>
        <Form
          form={form}
          layout="vertical"
          size="large"
          onFinish={(values) => {
            delete values.provinceId;
            delete values.districtId;
            delete values.addressDetail;
            const address = values.location?.formattedAddress || values.location?.address;
            delete values.location?.formattedAddress;
            const location = {
              ...values.location,
              address: address,
            };
            if (isUpdate && currentStoreData && Object.keys(currentStoreData).length) {
              const updatedData = {
                id: currentStoreData.id,
                disabled: currentStoreData.disabled,
                imageUrl: currentStoreData.imageUrl,
                ...values,
                location: location,
                name: currentStoreData.name,
                telephone: currentStoreData.telephone,
              };
              handleStoreInfo({ isUpdate: isUpdate, data: updatedData });
            } else {
              handleStoreInfo({
                isUpdate: isUpdate,
                data: {
                  ...values,
                  location: location,
                  buyer: buyer,
                },
              });
            }
          }}
          style={{
            maxHeight: 500,
            overflowY: 'auto',
            overflowX: 'hidden',
            paddingRight: 10,
          }}
        >
          {!isUpdate && (
            <Row gutter={12}>
              <Col span={12}>
                <FormattedMessage {...orderMessage.storeName}>
                  {(storeName: string) => (
                    <FormattedMessage {...utilsMessages.fieldRequired} values={{ field: storeName }}>
                      {(fieldRequired) => (
                        <FormattedMessage {...utilsMessages.storeNamePlaceHolder}>
                          {(storeNamePlaceHolder) => (
                            <FormItem
                              name="name"
                              colon={false}
                              required={false}
                              label={storeName}
                              hasFeedback
                              rules={[
                                {
                                  required: true,
                                  message: fieldRequired,
                                },
                              ]}
                            >
                              <Input
                                InputComponent={AntdInput}
                                trackingCategory="Create Store Form | Input"
                                trackingAction="Enter store name"
                                type="text"
                                placeHolder={storeNamePlaceHolder}
                                disabled={isUpdate}
                              />
                            </FormItem>
                          )}
                        </FormattedMessage>
                      )}
                    </FormattedMessage>
                  )}
                </FormattedMessage>
              </Col>
              <Col span={12}>
                <FormattedMessage {...utilsMessages.phone}>
                  {(phone: string) => (
                    <FormattedMessage {...utilsMessages.fieldRequired} values={{ field: phone }}>
                      {(fieldRequired) => (
                        <FormattedMessage {...utilsMessages.phonePlaceHolder}>
                          {(phonePlaceHolder) => (
                            <Form.Item
                              name="telephone"
                              label={phone}
                              colon={false}
                              rules={[
                                { required: true, message: fieldRequired },
                                // {
                                //   pattern: /^(?=(?:[()+\-.\s]*\d){9,12}$)[()+\-.,\s\d]*$/,
                                //   message: translations(storeMessage.invalidPhoneNumber),
                                // },
                              ]}
                            >
                              <Input
                                InputComponent={AntdInput}
                                trackingCategory="Create Store Form | Input"
                                trackingAction="Enter Phone"
                                type="text"
                                onBlur={formatPhoneNumber}
                                placeHolder={phonePlaceHolder}
                                disabled={isUpdate}
                              />
                            </Form.Item>
                          )}
                        </FormattedMessage>
                      )}
                    </FormattedMessage>
                  )}
                </FormattedMessage>
              </Col>
            </Row>
          )}
          {isUpdate && (
            <Row gutter={8} style={{ flexWrap: 'nowrap', marginBottom: '10px', justifyContent: 'center' }}>
              <StoreNameViewOnly>{currentStoreData?.name}</StoreNameViewOnly>
              <Col>
                <SeparateLine span={1} />
              </Col>
              <Col>
                <span>
                  <FormattedMessage {...utilsMessages.phoneNumber} />
                </span>
                :{currentStoreData?.telephone}
              </Col>
            </Row>
          )}
          <AddressForMap
            form={form}
            fieldNameFullText={FIELD_NAME_FULL_TEXT}
            fieldName={{
              city: 'provinceId',
              district: 'districtId',
              addressDetail: 'addressDetail',
              ward: 'wardId',
              wardId: 'wardId',
              geoLat: 'geoLat',
              geoLon: 'geoLon',
              geoPlusCode: 'geoPlusCode',
            }}
            // provinceValue={isUpdate ? fullAddress?.provinceCode : buyer?.fullAddress?.provinceCode}
            // districtValue={isUpdate ? fullAddress?.districtCode : buyer?.fullAddress?.districtCode}
            // wardValue={isUpdate ? fullAddress?.wardCode : buyer?.fullAddress?.wardCode}
            currentAddressDetail={currentAddressDetail}
            regions={regions}
            handleShowWarning={handleShowWarning}
            setOpenExtendedMap={setOpenExtendedMap}
            isOpenExtendedMap={openExtendedMap}
            setCoords={setCoords}
            setPlaceId={setPlaceId}
            placeId={placeId}
            setIsOutOfRegion={setIsOutOfRegion}
            setLoading={setLoading}
            isUpdate={isUpdate}
          />
          {!placeId && <Spacing />}
          {openExtendedMap && coords && (
            <StoreMapWrapper
              form={form}
              fieldNameFullText={{
                addressDetail: FIELD_NAME_FULL_TEXT.addressDetail,
                address: 'addressDetail',
                geoLat: 'geoLat',
                geoLon: 'geoLon',
                geoPlusCode: 'geoPlusCode',
              }}
              hideMarker={!coords}
              coords={coords}
              setCoords={setCoords}
              placeId={placeId}
              setPlaceId={setPlaceId}
              isOutOfRegion={isOutOfRegion}
              setIsOutOfRegion={setIsOutOfRegion}
              openExtendedMap={openExtendedMap}
              setOpenExtendedMap={setOpenExtendedMap}
              currentRegion={regions?.[0] || ''}
            />
          )}
          {(!isReadyToSubmit || !isMissingWard || !placeId) && (
            <Note>
              <NoteIcon src={LightBuldCircle} renderAsImg={false} />
              <NoteText>{translations(utilsMessages.storeNoteText)}</NoteText>
            </Note>
          )}
        </Form>
      </PopupCreateStore>
    </GoogleMapsContextProvider>
  );
};

const mapStateToProps = createStructuredSelector<ApplicationRootState, IStateProps>({
  buyer: selectBuyer(),
  createStorePopupState: selectCreateStorePopupComponent(),
});

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => ({
  handleStoreInfo: (payload: any) => {
    dispatch(handleStore.request(payload));
  },
  findFullAddress: (payload: any) => dispatch(findFullAddress.request(payload)),
});

const withConnect = connect<IStateProps, IDispatchProps>(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer<IOwnProps>({ key: 'createStorePopupComponent', reducer: reducer });

const withSaga = injectSaga<IOwnProps>({ key: 'createStorePopupComponent', saga: saga });

export default compose(withReducer, withSaga, withConnect)(CreateStorePopup);
