import React, { useRef, useCallback } from 'react';
import StoreMap from './StoreMap';
import { INITIAL_CAMERA, DEFAULT_MAP_OPTION } from '../constants';
import LocationAddressPopup from '../../LocationAddressPopup';
import { FormInstance, Form } from 'antd';
import { FormFieldNameFullText } from '../utils';
import { MarkerRefProps } from '../type';
import styled from 'styled-components';

const StoreMapContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
`;

interface Props {
  form: FormInstance<any>;
  coords: google.maps.LatLngLiteral | null;
  setCoords: React.Dispatch<React.SetStateAction<google.maps.LatLngLiteral | null>>;
  placeId: string;
  setPlaceId: React.Dispatch<React.SetStateAction<string>>;
  fieldNameFullText: Record<FormFieldNameFullText, string[] | string>;
  openExtendedMap: boolean;
  setOpenExtendedMap: React.Dispatch<React.SetStateAction<boolean>>;
  isOutOfRegion: boolean;
  setIsOutOfRegion: React.Dispatch<React.SetStateAction<boolean>>;
  currentRegion: string;
  isOnlyPopup?: boolean;
  fullHeight?: boolean;
  hideMarker?: boolean;
}

const StoreMapWrapper: React.FC<Props> = ({
  form,
  coords,
  setCoords,
  placeId,
  setPlaceId,
  fieldNameFullText,
  openExtendedMap,
  setOpenExtendedMap,
  isOutOfRegion,
  setIsOutOfRegion,
  currentRegion,
  fullHeight,
  isOnlyPopup = true,
  hideMarker = false,
}) => {
  const addressValue = Form.useWatch(fieldNameFullText.addressDetail, form) as string;
  const markerRef = useRef<MarkerRefProps>(null);
  const [isOnDrag, setIsOnDrag] = React.useState(false);

  const handleUpdateAddress = useCallback(
    (description: string, value: string) => {
      if (fieldNameFullText?.addressDetail) {
        form.setFieldValue(fieldNameFullText?.addressDetail, `${description}, ${value}`);
        form.setFieldValue(fieldNameFullText?.address, value);
      }
    },
    [form],
  );

  const validateAddress = useCallback(() => {
    if (fieldNameFullText?.addressDetail) {
      form.validateFields([fieldNameFullText?.address]);
    }
  }, [form]);

  return (
    <>
      {!isOnlyPopup && (
        <StoreMapContainer>
          <StoreMap
            mapOptions={{ ...DEFAULT_MAP_OPTION, draggable: false }}
            setOpenExtendedMap={setOpenExtendedMap}
            styles={{ height: fullHeight ? 505 : 180, borderRadius: 8, cursor: 'pointer' }}
            center={coords}
            setCenter={setCoords}
            setPlaceId={setPlaceId}
            openExtendedMap={openExtendedMap}
            hideMarker={hideMarker}
            ref={markerRef}
            isOutOfRegion={isOutOfRegion}
            setIsOutOfRegion={setIsOutOfRegion}
            currentRegion={currentRegion}
            setIsOnDrag={setIsOnDrag}
          />
        </StoreMapContainer>
      )}
      <LocationAddressPopup
        addressValue={addressValue}
        center={coords}
        setCenter={setCoords}
        setPlaceId={setPlaceId}
        setOpen={setOpenExtendedMap}
        open={openExtendedMap}
        markerRef={markerRef}
        handleUpdateAddress={handleUpdateAddress}
        isOutOfRegion={isOutOfRegion}
        isOnDrag={isOnDrag}
        setIsOnDrag={setIsOnDrag}
        validateAddress={validateAddress}
      >
        <StoreMap
          mapOptions={{ ...DEFAULT_MAP_OPTION, fullscreenControl: false, scrollwheel: false }}
          setOpenExtendedMap={setOpenExtendedMap}
          styles={{ height: 505 }}
          center={coords}
          setCenter={setCoords}
          setPlaceId={setPlaceId}
          openExtendedMap={openExtendedMap}
          hideMarker={hideMarker}
          ref={markerRef}
          isOutOfRegion={isOutOfRegion}
          setIsOutOfRegion={setIsOutOfRegion}
          currentRegion={currentRegion}
          setIsOnDrag={setIsOnDrag}
        />
      </LocationAddressPopup>
    </>
  );
};

export default StoreMapWrapper;
