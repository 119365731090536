/*
 *
 * LanguageProvider
 *
 * this component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `app/translations`)
 */

import * as React from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { IntlProvider } from 'react-intl';

import { makeSelectLocale } from './selectors';
import { changeLocale } from './actions';
import { Dispatch } from 'redux';

export interface ILanguageProviderProps {
  locale: string;
  onChangeLocale: (languageLocale: string) => void;
  messages: { [locale: string]: { [id: string]: string } };
  children?: React.ReactNode;
}

export class LanguageProvider extends React.PureComponent<ILanguageProviderProps, {}> {
  componentDidMount(): void {
    const lang = window.localStorage.getItem('lang') || this.props.locale;
    this.props.onChangeLocale(lang);
  }

  public render() {
    const lang = window.localStorage.getItem('lang') || this.props.locale;
    return (
      <IntlProvider defaultLocale="vi" locale={lang} key={lang} messages={this.props.messages[lang as string]}>
        {React.Children.only(this.props.children)}
      </IntlProvider>
    );
  }
}

const mapStateToProps = createSelector(makeSelectLocale(), (locale) => ({
  locale: locale,
}));

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    onChangeLocale: (languageLocale: string) => dispatch(changeLocale(languageLocale)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(LanguageProvider);
