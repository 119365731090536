import gql from 'graphql-tag';

export default gql`
  query getVaAndQrInfo($buyerId: Int!, $buyerName: String!) {
    getVaAndQrInfo(buyerId: $buyerId, buyerName: $buyerName) {
      vaAccountNumber
      vaName
      qrDataBase64
    }
  }
`;
