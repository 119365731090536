import { defineMessages } from 'react-intl';

export const scope = 'app.components.CreateStorePopup';

export default defineMessages({
  warningTitle: {
    id: `${scope}.warningTitle`,
    defaultMessage: 'Vui lòng chọn địa chỉ đúng với khu vực: {region}',
  },
  warningDescription: {
    id: `${scope}.warningDescription`,
    defaultMessage: 'Hãy chắc chắn vị trí trên bản đồ được ghim đúng bạn nhé!',
  },
  errorTitle: {
    id: `${scope}.errorTitle`,
    defaultMessage: 'Không thể tìm thấy vị trên bản đồ',
  },
  addressPinInfo: {
    id: `${scope}.addressPinInfo`,
    defaultMessage: 'Địa chỉ của bạn ở đây',
  },
  proposedLocation: {
    id: `${scope}.proposedLocation`,
    defaultMessage: 'Địa chỉ đề xuất',
  },
  optionTitle: {
    id: `${scope}.optionTitle`,
    defaultMessage: 'Chọn địa chỉ bên dưới',
  },
  optionOpenExtendedMap: {
    id: `${scope}.optionOpenExtendedMap`,
    defaultMessage: 'Chọn trên bản đồ',
  },
  openExtendedMap: {
    id: `${scope}.openExtendedMap`,
    defaultMessage: 'Xem bản đồ',
  },
  locationAddressConfirm: {
    id: `${scope}.locationAddressConfirm`,
    defaultMessage: 'Xác nhận vị trí trên bản đồ',
  },
  storeLocationConfirm: {
    id: `${scope}.storeLocationConfirm`,
    defaultMessage: 'Vui lòng xác nhận địa chỉ của bạn',
  },
  storeLocationConfirmDescription: {
    id: `${scope}.storeLocationConfirmDescription`,
    defaultMessage: 'Vị trí trên bản đồ phải khớp với địa chỉ của bạn để thuận tiện giao hàng',
  },
  confirm: {
    id: `${scope}.confirm`,
    defaultMessage: 'Xác nhận',
  },
  notConfirm: {
    id: `${scope}.notConfirm`,
    defaultMessage: 'Thay đổi',
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'Hủy',
  },
  selectPin: {
    id: `${scope}.selectPin`,
    defaultMessage: 'Chọn địa chỉ này',
  },
  suggestSelectAddress: {
    id: `${scope}.suggestSelectAddress`,
    defaultMessage: 'Vui lòng chọn từ danh sách gợi ý để xác nhận địa chỉ cửa hàng',
  },
});
