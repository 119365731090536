import React, { useCallback } from 'react';
import { debounce } from 'lodash';
import useDeepCompareEffectForMaps from 'containers/LandingPage/DistributionLocationPage/components/Map/hooks/useDeepCompareEffectForMaps';
import * as Sentry from '@sentry/browser';
import { NearBySuggestion } from '../../LocationAddressPopup';
import { AddressLocation } from 'containers/LandingPage/DistributionLocationPage/components/Map/hooks/GoogleMapScriptContext';
import { useMap } from '@vis.gl/react-google-maps';
import { generateRawText } from 'components/CreateStorePopup/utils';
import { convertStringToSlug } from 'utils/generateProductSlug';

// const unsignedExceptionalDistrict = ['quan 2', 'quan 9', 'thu duc'];

const DEFAULT_COUNTRY_CODE = 'VN';
const DEFAULT_REGION = 'vn';

function processGMAutoSuggestions(
  predictions: google.maps.places.AutocompletePrediction[],
  setAutoSuggestions: React.Dispatch<React.SetStateAction<{ description: string; placeId: string }[]>>,
) {
  if (predictions.length) {
    const suggestions = predictions.map((item) => ({
      description: item.description,
      placeId: item.place_id,
    }));
    setAutoSuggestions(suggestions);
  } else {
    setAutoSuggestions([]);
  }
}

function processGMNearBySuggestions(
  results: google.maps.places.PlaceResult[],
  setAutoSuggestions: React.Dispatch<React.SetStateAction<NearBySuggestion[]>>,
) {
  if (results.length) {
    const suggestions = results
      .filter((result) => {
        const splittedVicinity = result?.vicinity?.split(', ');
        return splittedVicinity && splittedVicinity.length > 2 && result.business_status === 'OPERATIONAL';
      })
      .map((item) => {
        return {
          name: item.name || '',
          address: item.vicinity || '',
          placeId: item.place_id || '',
        };
      });
    setAutoSuggestions(suggestions);
  } else {
    setAutoSuggestions([]);
  }
}

export function processGMCoordinates(
  results: google.maps.GeocoderResult[],
  address: { formatted: string; description: string },
  setAddress: React.Dispatch<React.SetStateAction<AddressLocation | null>>,
  placePlusCode: string,
  isPOI: boolean,
) {
  const representativeLocation = {
    province: '',
    district: '',
    ward: '',
    formattedAddress: '',
    // surroundDistrict: [] as string[],
    // surroundWard: [] as string[],
    pluscode: '',
    isPOI: isPOI,
    fullAddress: '',
    description: '',
  };
  representativeLocation.fullAddress = address?.formatted;
  representativeLocation.description = address?.description;
  // const addressParts = address?.formatted?.split(',')?.map((part) => convertStringToSlug(part, true));
  const isLocationMatchingWithAddressDetail = (location: string) => {
    const unsignedAddressParts = address?.formatted
      .split(',')
      .map((part) => generateRawText(convertStringToSlug(part.trim(), true)));
    return unsignedAddressParts.some((field) => {
      try {
        const fieldRegex = new RegExp(`^${field}(\\s+\\d+)?$`, 'i'); // Match field with optional numbers
        return fieldRegex.test(generateRawText(convertStringToSlug(location, true)));
      } catch {
        return false;
      }
    });
  };
  results.forEach((item) => {
    if (!representativeLocation.province || !representativeLocation.district || !representativeLocation.ward) {
      item?.address_components?.forEach((component) => {
        if (!representativeLocation.province && component.types.includes('administrative_area_level_1')) {
          representativeLocation.province = component.long_name;
        }
        if (
          !representativeLocation.district &&
          component.types.includes('administrative_area_level_2') &&
          isLocationMatchingWithAddressDetail(component.long_name)
          // unsignedExceptionalDistrict.some((district) =>
          //   convertStringToSlug(component.long_name, true).includes(district),
          // ))
        ) {
          representativeLocation.district = component.long_name;
          const exceptionalDistrict = ['quận 2', 'quận 9'];
          if (exceptionalDistrict.includes(representativeLocation.district.toLocaleLowerCase().trim())) {
            representativeLocation.district = 'Thu Duc';
          }
        }
        if (
          !representativeLocation.ward &&
          (component.types.includes('administrative_area_level_3') ||
            component.types.includes('sublocality_level_1')) &&
          isLocationMatchingWithAddressDetail(component.long_name)
        ) {
          representativeLocation.ward = component.long_name;
        }
      });
      if (!representativeLocation.pluscode && item?.plus_code) {
        representativeLocation.pluscode = item.plus_code?.compound_code || '';
      }
      if (!representativeLocation.formattedAddress) {
        representativeLocation.formattedAddress = item.formatted_address || '';
      }
    } /* else if (item?.geometry?.location_type !== 'APPROXIMATE') {
      item?.address_components?.forEach((component) => {
        if (component.types.includes('administrative_area_level_2') && !representativeLocation.surroundDistrict.includes(component.long_name)) {
          representativeLocation.surroundDistrict = [...representativeLocation.surroundDistrict, component.long_name];
        }
        if ((component.types.includes('administrative_area_level_3') || component.types.includes('sublocality_level_1')) && !representativeLocation.surroundWard.includes(component.long_name)) {
          representativeLocation.surroundWard = [...representativeLocation.surroundWard, component.long_name];
        }
      });
    } */
  });
  if (!representativeLocation.pluscode && placePlusCode) {
    representativeLocation.pluscode = placePlusCode;
  }
  setAddress(representativeLocation);
}

function useConvertAddrToCoords(address: string | null, options: any, type: 'autocomplete' | 'nearby') {
  const apiKey = process.env.GOOGLE_MAP_API;
  const map = useMap();
  const { setAutoSuggestions, setAddress, setCoords, coords, placeId, isOnDrag, setIsFetchingSuggestions } = options;

  const fetchSuggestions = useCallback(
    debounce(async () => {
      if (apiKey && address) {
        if (type === 'autocomplete') {
          const autocompleteService = new google.maps.places.AutocompleteService();
          autocompleteService
            .getPlacePredictions(
              {
                input: address,
                componentRestrictions: { country: DEFAULT_COUNTRY_CODE },
                language: 'vi',
                region: DEFAULT_REGION,
              },
              (predictions, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK) {
                  if (predictions) {
                    processGMAutoSuggestions(predictions, setAutoSuggestions);
                  } else {
                    setAutoSuggestions([]);
                  }
                } else {
                  setAutoSuggestions([]);
                  Sentry.captureException(`Error fetching autocomplete suggestions: ${status}`, {
                    level: Sentry.Severity.Warning,
                  });
                }
              },
            )
            .finally(() => setIsFetchingSuggestions(false));
        } else if (type === 'nearby') {
          // How to use google places nearby search
          if (map) {
            const placesService = new google.maps.places.PlacesService(map);
            placesService.nearbySearch(
              {
                location: new google.maps.LatLng(coords.lat, coords.lng),
                // radius: 500,
                rankBy: google.maps.places.RankBy.DISTANCE,
                // take all type of places
                type: 'route, street_address, point_of_interest, establishment',
                componentRestrictions: { country: DEFAULT_COUNTRY_CODE },
                language: 'vi',
              },
              (results, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK) {
                  if (results) {
                    processGMNearBySuggestions(results, setAutoSuggestions);
                  } else {
                    setAutoSuggestions([]);
                  }
                } else {
                  setAutoSuggestions([]);
                  Sentry.captureException(`Error fetching nearby suggestions: ${status}`, {
                    level: Sentry.Severity.Warning,
                  });
                }
              },
            );
          } else {
            setAutoSuggestions([]);
            Sentry.captureException(`Map is not available`, { level: Sentry.Severity.Warning });
          }
        }
      } else {
        setAutoSuggestions([]);
        Sentry.captureException(`API key is missing`, { level: Sentry.Severity.Warning });
      }
    }, 200),
    [address, map, type, coords?.lat, coords?.lng, isOnDrag],
  );

  const fetchCoords = useCallback(
    debounce(async (placeId: string) => {
      if (apiKey) {
        const map = new google.maps.Map(document.createElement('div'));
        const placesService = new google.maps.places.PlacesService(map);
        placesService.getDetails(
          {
            placeId: placeId,
            fields: ['address_components', 'geometry', 'plus_code', 'formatted_address', 'types'],
          },
          (place, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK && place && place.geometry) {
              const regex = /\b\d{5,8}\b/g; // remove both ZIP codes (5-8 digits)
              const addressDetail = {
                formatted: address?.replace(regex, '') || '',
                description: '',
              };
              if (place.formatted_address) {
                addressDetail.formatted = place.formatted_address.replace(regex, '') || '';
                if (
                  place.types?.some((type) =>
                    ['point_of_interest', 'establishment', 'restaurant', 'food', 'bar', 'tourist_attraction'].includes(
                      type,
                    ),
                  )
                ) {
                  addressDetail.description = address?.replace(regex, '').split(', ')[0] || '';
                }
              }
              const geometry = place.geometry;
              const geocoder = new google.maps.Geocoder();
              geocoder
                .geocode({
                  location: geometry?.location,
                  language: 'vi',
                })
                .then((data) => {
                  if (data.results.length) {
                    if (data.results) {
                      const isPOI = place.types?.includes('point_of_interest') || false;
                      processGMCoordinates(
                        data.results,
                        addressDetail,
                        setAddress,
                        place.plus_code?.compound_code || data.plus_code?.compound_code || '',
                        isPOI,
                      );
                    }
                  } else {
                    Sentry.captureException(`Error fetching place details: ${status}`, {
                      level: Sentry.Severity.Warning,
                    });
                  }
                });
              setCoords({ lat: geometry?.location?.lat(), lng: geometry?.location?.lng() });
            } else {
              setCoords(null);
              Sentry.captureException(`Error fetching place details: ${status}`, { level: Sentry.Severity.Warning });
            }
          },
        );
      } else {
        setCoords(null);
        Sentry.captureException(`API key is missing`, { level: Sentry.Severity.Warning });
      }
    }, 500),
    [address],
  );

  useDeepCompareEffectForMaps(() => {
    if (address) {
      if (!placeId || type === 'nearby') {
        fetchSuggestions();
      } else {
        fetchCoords(placeId);
      }
    }
  }, [address, fetchSuggestions, fetchCoords, placeId, coords]);
}

export default useConvertAddrToCoords;