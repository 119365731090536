import { Skeleton, Typography } from 'antd';
import { Viewport } from 'components/ViewportProvider';
import VisuallyHidden from 'components/VisuallyHidden';
import { push } from 'connected-react-router';
import { handleAddAllToFavorite, setHomeFavirote, setHomeProductNote } from 'containers/HomePage/actions';
import { selectLoading, selectNoteItemLoading, selectSections } from 'containers/HomePage/selectors';
import {
  IHomeFavoritePayload,
  IHomeProductNotePayload,
  ILoadingSection,
  ISection,
  ISectionData,
} from 'containers/HomePage/types';
import {
  selectAddedProducts,
  selectAddingProducts,
  selectBuyer,
  selectUserPermissions,
} from 'containers/MainLayout/selectors';
import { AddedProduct, AddingProduct } from 'containers/MainLayout/types';
import { getImageByCategory } from 'containers/MarketPage/ImageByCategory';
import React, { FC, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styles/styled-components';
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/mousewheel';
import 'swiper/css/navigation';
import { Grid, Mousewheel, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ApplicationRootState } from 'types';
import { Buyer, Product } from 'types/schema';
import generateProductSlug from 'utils/generateProductSlug';
import SVGIcon from '../SVGIcon/SVGIcon';
import ArrowRight from './ArrowRight.svg';
import FavoriteIcon from './FavoriteIcon.svg';
import FavoriteImage from './FavoriteImage.png';
import { Image } from './Image';
import messages from './messages';

const { Title } = Typography;

const SectionTitle = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const SectionHeading = styled(Title)`
  &.ant-typography {
    font-size: 16px;
    line-height: 1.4;
    font-weight: 500;
  }
`;

const WidgetWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 16px 16px 24px;
  background-color: #ffffff;
  margin-bottom: 16px;
  border-radius: 4px;
  ${({ isShowBeside }) =>
    isShowBeside &&
    `
    width: 260px;
    flex-shrink: 0;
    flex-grow: 1;
  `}
`;

const ImageContainer = styled.div`
  position: relative;
  width: 106px;
`;

const ProductName = styled.div`
  overflow: hidden;
  font-size: 14px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-height: 14px;
  height: 29px;
  color: rgb(89, 89, 89);
  font-weight: 500;
  margin-top: 6px;
  max-width: 106px;
`;

const SwiperElement = styled(Swiper)`
  max-width: 1136px;
  margin: 0px !important;
  padding: 1px 5px !important;
  .swiper-button-next,
  .swiper-button-prev {
    top: 60px !important;
    width: 30px !important;
    height: 30px !important;
    border-radius: 50% !important;
    background-color: #ffffff !important;
    box-shadow: 0px 0px 6.6px 0px rgba(0, 0, 0, 0.12);
    &:after {
      font-size: 14px !important;
      color: #43a047 !important;
    }
    &.swiper-button-disabled {
      display: none !important;
    }
    ${({ isShowBeside }) =>
      isShowBeside &&
      `
      top: 120px !important;
    `}
  }
  .swiper-button-next {
    right: 4px;
    &:after {
      margin-left: 1px;
    }
  }
  .swiper-button-prev {
    left: 4px;
    &:after {
      margin-right: 1px;
    }
  }
`;

const SwiperSlideElement = styled(SwiperSlide)`
  ${({ isShowBeside }) =>
    !isShowBeside &&
    `
    width: fit-content !important;
  `}
`;
const AddAllToFavoriteWrapper = styled.div`
  display: flex;
  margin-top: auto;
`;
const AddAllToFavorite = styled.div`
  display: flex;
  align-items: center;
  background: #f0f9f1;
  padding: 6px 8px;
  border-radius: 8px;
  height: 32px;
  flex-shrink: 0;
  cursor: pointer;
  ${({ isShowBeside }) =>
    isShowBeside &&
    `
    padding: 6px 34px;
    width: 100%;
    justify-content: center;
    margin-top: 6px;
  `}
  gap: 4px;
`;

const AddAllToFavoriteText = styled.div`
  color: #2a652c;
  font-size: 14px;
  font-weight: 500;
  flex-shrink: 0;
`;

const AddedToFavoriteSection = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
`;

const AddedToFavoriteImage = styled.img`
  width: 96px;
`;

const AddedToFavoriteText = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: #242527;
  margin-top: 20px;
`;

const AddedToFavoriteTextMobile = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: #242527;
  margin-top: 10px;
`;

const AddedToFavoriteSubText = styled.div`
  font-size: 14px;
  color: #585a60;
  margin: 4px 0 24px;
  text-align: center;
`;

const AddedToFavoriteSubTextMobile = styled.div`
  font-size: 14px;
  color: #585a60;
  margin: 4px 0 8px;
`;

const GoToFavoriteButton = styled.div`
  background: #f0f9f1;
  padding: 6px 34px;
  border-radius: 8px;
  height: 32px;
  cursor: pointer;
  color: #2a652c;
  text-align: center;
  width: 100%;
`;

const AddedToFavoriteSectionMobile = styled.div``;

const GoToFavoriteButtonMobile = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: #43a047;
  cursor: pointer;
  & > div {
    margin-top: 2px;
  }
`;

interface IOwnProps {
  showcaseId: string;
  isShowBeside?: boolean;
  // showcase: ShowcaseItem | RecommendationItem;
}

interface IStateProps {
  sections: ISection;
  loading: ILoadingSection | boolean;
  addedProducts: AddedProduct;
  addingProducts: AddingProduct;
  permissions: string[];
  noteItemLoading: boolean;
  buyer: Buyer;
}

interface IDispatchProps {
  onToggleFavorite: (data: IHomeFavoritePayload) => void;
  onSetProductNote: (data: IHomeProductNotePayload) => void;
  gotoSignIn: () => void;
  handleAddAllItemsToFavorite: (data: string[]) => void;
}

type Props = IStateProps & IDispatchProps & IOwnProps & RouteComponentProps;

const MightFavoriteProductsWidget: FC<Props> = (props) => {
  const { history, showcaseId, sections, loading, isShowBeside, handleAddAllItemsToFavorite } = props;
  const swiperRef: any = useRef();
  const lang = localStorage.getItem('lang') || 'vi';
  const section = sections[showcaseId] as ISectionData;

  const generateImage = (product) => {
    return product?.category && !product?.imageUrl ? getImageByCategory(product?.category) : product?.imageUrl;
  };

  const handleAddItemsToFavorite = () => {
    const itemIds = section?.data?.map((item) => item.id);
    if (itemIds?.length) {
      handleAddAllItemsToFavorite(itemIds);
    }
  };
  if ((!loading[showcaseId] && !section?.data?.length) || sections[showcaseId].error) {
    return null;
  }
  return section?.data?.length ? (
    <Viewport.Consumer>
      {({ width }) =>
        width > 1023 || !isShowBeside ? (
          <WidgetWrapper isShowBeside={isShowBeside}>
            {!section?.isAdded ? (
              <>
                <SectionTitle>
                  <SectionHeading level={2}>
                    <FormattedMessage {...messages.sectionTitle} />
                  </SectionHeading>
                  {!isShowBeside && (
                    <AddAllToFavorite onClick={handleAddItemsToFavorite}>
                      <SVGIcon src={FavoriteIcon} />
                      <AddAllToFavoriteText>
                        <FormattedMessage {...messages.addAllToFavorite} />
                      </AddAllToFavoriteText>
                    </AddAllToFavorite>
                  )}
                </SectionTitle>
                <SwiperElement
                  onSwiper={(swiper) => {
                    swiperRef.current = swiper;
                  }}
                  spaceBetween={12}
                  navigation={true}
                  mousewheel={{ forceToAxis: true }}
                  simulateTouch={true}
                  modules={[Navigation, Grid, Mousewheel]}
                  slidesPerView={isShowBeside ? 2 : 'auto'}
                  grid={{
                    rows: isShowBeside ? 2 : 1,
                    fill: 'row',
                  }}
                  className="mySwiper"
                  isShowBeside={isShowBeside}
                  slidesPerGroup={2}
                >
                  {(section?.data as Product[]).map((product: Product, index: number) => (
                    <SwiperSlideElement isShowBeside={isShowBeside} key={product?.id || index}>
                      {product?.id ? (
                        <Link
                          to={`/products/${generateProductSlug(product?.name, product?.supplierInternalCode, lang)}`}
                        >
                          <ImageContainer style={{ position: 'relative' }}>
                            <Image src={generateImage(product)} alt={String(name)} />
                            <VisuallyHidden asComponent="span">{product?.name}</VisuallyHidden>
                          </ImageContainer>
                          <ProductName>{product?.name}</ProductName>
                        </Link>
                      ) : (
                        <Skeleton.Image active={true} style={{ width: 106, height: 106 }} />
                      )}
                    </SwiperSlideElement>
                  ))}
                </SwiperElement>
                {isShowBeside && (
                  <AddAllToFavoriteWrapper>
                    <AddAllToFavorite isShowBeside onClick={handleAddItemsToFavorite}>
                      <SVGIcon src={FavoriteIcon} />
                      <AddAllToFavoriteText>
                        <FormattedMessage {...messages.addAllToFavorite} />
                      </AddAllToFavoriteText>
                    </AddAllToFavorite>
                  </AddAllToFavoriteWrapper>
                )}
              </>
            ) : isShowBeside ? (
              <AddedToFavoriteSection>
                <AddedToFavoriteImage src={FavoriteImage} />
                <AddedToFavoriteText>
                  <FormattedMessage {...messages.addedToFavorite} />
                </AddedToFavoriteText>
                <AddedToFavoriteSubText>
                  <FormattedMessage {...messages.addedToFavoriteSubText} />
                </AddedToFavoriteSubText>
                <GoToFavoriteButton onClick={() => history.push('/favorite')}>
                  <FormattedMessage {...messages.goToFavorite} />
                </GoToFavoriteButton>
              </AddedToFavoriteSection>
            ) : (
              <AddedToFavoriteSectionMobile>
                <AddedToFavoriteTextMobile>
                  <FormattedMessage {...messages.addedToFavoriteTextMobile} />
                </AddedToFavoriteTextMobile>
                <AddedToFavoriteSubTextMobile>
                  <FormattedMessage {...messages.addedToFavoriteSubTextMobile} />
                </AddedToFavoriteSubTextMobile>
                <GoToFavoriteButtonMobile onClick={() => history.push('/favorite')}>
                  <FormattedMessage {...messages.goToFavoriteMobile} />
                  <SVGIcon src={ArrowRight} />
                </GoToFavoriteButtonMobile>
              </AddedToFavoriteSectionMobile>
            )}
          </WidgetWrapper>
        ) : (
          <></>
        )
      }
    </Viewport.Consumer>
  ) : null;
};

const mapStateToProps = createStructuredSelector<ApplicationRootState, IStateProps>({
  sections: selectSections(),
  loading: selectLoading(),
  addedProducts: selectAddedProducts(),
  addingProducts: selectAddingProducts(),
  permissions: selectUserPermissions(),
  noteItemLoading: selectNoteItemLoading(),
  buyer: selectBuyer(),
});

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => ({
  onToggleFavorite: (data: IHomeFavoritePayload) => dispatch(setHomeFavirote(data)),
  onSetProductNote: (data: IHomeProductNotePayload) => dispatch(setHomeProductNote(data)),
  gotoSignIn: () => dispatch(push('/signin')),
  handleAddAllItemsToFavorite: (data: string[]) => dispatch(handleAddAllToFavorite(data)),
});

const withConnect = connect<IStateProps, IDispatchProps, IOwnProps>(mapStateToProps, mapDispatchToProps);

export default withConnect(withRouter(MightFavoriteProductsWidget));
