import React, { useEffect, useState } from 'react';
import { Button, Divider, Flex, List, Modal } from 'antd';
import styled from 'styled-components';
import { LeftOutlined } from '@ant-design/icons';
import messages from 'components/CreateStorePopup/messages';
import translations from 'translations';
import { MarkerRefProps } from 'components/CreateStorePopup/type';
import { Viewport } from 'components/ViewportProvider';
import media from 'utils/mediaStyle';
import { FormattedMessage } from 'react-intl';
import { OptionLabel } from 'components/CreateStorePopup/hooks/useAutoSuggestion';
import useAddressToCoords from 'components/CreateStorePopup/hooks/useAddressToCoords';
import { useAddressLocation } from 'containers/LandingPage/DistributionLocationPage/components/Map/hooks/GoogleMapScriptContext';
import SVGIcon from 'components/SVGIcon/SVGIcon';
import MapPin from 'components/CreateStorePopup/hooks/Map-Pin.svg';

const LocationAddressModal = styled(Modal)`
  max-width: 672px;
  min-width: fit-content;
  ${media.sm`
    min-width: 672px;
    max-width: auto;
  `}
  box-shadow: 0px 1px 3px 0px rgba(166, 175, 195, 0.4);
  border-radius: 8px;
  background: #f9fafb;
  color: #344054;
  font-family: 'Maven Pro';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  padding-bottom: 0;
  top: 0;
  ${media.sm`
    top: 100px;
  `}

  & .ant-modal-header {
    margin-bottom: 16px;
  }

  & .ant-modal-content {
    padding: 16px;
  }

  & .ant-modal-body {
    margin-inline: -16px;
  }

  & .ant-modal-footer {
    display: flex;
    justify-content: space-between;
    ${media.sm`
      display: block;
      `}
    button {
      width: 140px;
    }
  }
`;

const LocationAddressConfirm = styled(Button)`
  display: flex;
  justify-content: start;
  align-items: center;
  padding-inline: 0;
  padding-block: 16px;
  width: 100%;
  gap: 16px;
  color: #344054;
  font-family: 'Maven Pro';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
`;

const ProposedLocationList = styled(List)`
  position: absolute;
  z-index: 1;
  padding: 12px 24px;
  display: flex;
  flex-direction: column;
  background: #fff;
  width: 100%;
  max-height: 174px;
  overflow-y: auto;
  gap: 6px;
  &.ant-list .ant-list-header {
    padding: 0;
    border: none;
  }
  &.ant-list .ant-list-items > *:not(:last-child) {
    margin-bottom: 6px;
  }

  &.ant-list .ant-list-items .ant-list-item {
    border: none;
  }
`;

const ProposedLocationItem = styled(ProposedLocationList.Item.Meta)`
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: 'Maven Pro';
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  padding: 4px;
  &:hover,
  &:active,
  &:focus {
    color: #43a047;
    border-radius: 6px;
    background: #f0f9f1;
    cursor: pointer;
  }

  ${({ isSelected }: { isSelected: boolean }) =>
    isSelected &&
    `
      color: #43a047;
      border-radius: 6px;
      background: #f0f9f1;
  `}

  & ${OptionLabel} {
    color: #585a60;
    font-weight: 400;
  }
`;

const ItemLabel = styled.p`
  color: #585a60;
  font-weight: 400;
`;

const Header = styled.div`
  border: none;
  padding: 0;
  color: #808289;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

const buttonStyle = {
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  minWidth: 90,
  padding: '10px 12px',
  borderRadius: 6,
  border: '1px solid #D0D5DD',
  textAlign: 'center',
  fontFamily: 'Maven Pro',
  fontSize: 14,
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '130%',
};

export interface NearBySuggestion {
  name: string;
  address: string;
  // coords: google.maps.LatLngLiteral;
  placeId: string;
}

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  addressValue: string;
  handleUpdateAddress: (description: string, value: string) => void;
  center: google.maps.LatLngLiteral | null;
  setCenter: React.Dispatch<React.SetStateAction<google.maps.LatLngLiteral | null>>;
  setPlaceId: React.Dispatch<React.SetStateAction<string>>;
  validateAddress: () => void;
  isOutOfRegion: boolean;
  isOnDrag: boolean;
  setIsOnDrag: React.Dispatch<React.SetStateAction<boolean>>;
  markerRef?: React.RefObject<MarkerRefProps>;
  children: React.ReactNode;
}

const LocationAddressPopup: React.FC<Props> = ({
  open,
  setOpen,
  addressValue,
  handleUpdateAddress,
  center,
  setCenter,
  setPlaceId,
  validateAddress,
  isOutOfRegion,
  isOnDrag,
  setIsOnDrag,
  children,
}) => {
  const onCancel = () => {
    setOpen(false);
    setCenter(null);
    setIsOnDrag(false);
    setPlaceId('');
    validateAddress();
  };

  const onOk = (item: NearBySuggestion) => {
    setPlaceId(item.placeId);
    handleUpdateAddress(item.name, item.address);
    setOpen(false);
    setIsOnDrag(false);
  };

  const { setAddressComponents } = useAddressLocation();
  const [nearBySuggestions, setNearBySuggestions] = useState<NearBySuggestion[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<NearBySuggestion['placeId']>('');
  useAddressToCoords({
    addressValue: addressValue,
    setCoords: setCenter,
    setAutoSuggestions: setNearBySuggestions,
    setAddressComponents: setAddressComponents,
    coords: center,
    type: 'nearby',
    isOnDrag: isOnDrag,
  });

  function handleSelectLocation(item: NearBySuggestion) {
    setSelectedLocation(item.placeId);
  }

  useEffect(() => {
    if (nearBySuggestions.length > 0) {
      setSelectedLocation(nearBySuggestions[0].placeId);
    }
  }, [nearBySuggestions]);

  return (
    <Viewport.Consumer>
      {({ width }) => (
        <LocationAddressModal
          title={
            <LocationAddressConfirm
              icon={<LeftOutlined />}
              color="default"
              variant="text"
              style={{
                border: 'none',
                boxShadow: 'none',
                height: 'fit-content',
                padding: 0,
              }}
              onClick={onCancel}
            >
              {translations(messages.locationAddressConfirm)}
            </LocationAddressConfirm>
          }
          open={open}
          onCancel={onCancel}
          onOk={() => {
            const targetItem = nearBySuggestions.find((item) => item.placeId === selectedLocation);
            if (targetItem) {
              onOk(targetItem);
            }
          }}
          okText={translations(messages.selectPin)}
          cancelText={translations(messages.cancel)}
          okButtonProps={{
            type: 'primary',
            style: {
              ...buttonStyle,
              background: '#43A047',
              opacity: selectedLocation ? 1 : 0.5,
            },
            disabled: !isOutOfRegion && !selectedLocation,
          }}
          cancelButtonProps={{
            type: 'default',
            style: {
              ...buttonStyle,
              border: '1px solid #D0D5DD',
            },
          }}
          centered={width > media.sm}
          closable={false}
        >
          <Divider style={{ margin: '0 -16px', width: 'calc(100% + 32px)' }} />
          <Flex vertical>
            {nearBySuggestions.length && !isOutOfRegion ? (
              <ProposedLocationList
                size="small"
                itemLayout="horizontal"
                header={
                  <Header>
                    <FormattedMessage {...messages.proposedLocation} />
                  </Header>
                }
                dataSource={nearBySuggestions}
                renderItem={(item: NearBySuggestion) => (
                  <ProposedLocationItem
                    onClick={() => handleSelectLocation(item)}
                    avatar={<SVGIcon src={MapPin} style={{ paddingBlock: 4 }} />}
                    title={
                      <p
                        style={{
                          color: '#585A60',
                        }}
                      >
                        {item.name}
                      </p>
                    }
                    description={<ItemLabel>{item.address}</ItemLabel>}
                    isSelected={selectedLocation === item.placeId}
                  >
                    <OptionLabel label={item} />
                  </ProposedLocationItem>
                )}
              />
            ) : null}
            {children}
          </Flex>
        </LocationAddressModal>
      )}
    </Viewport.Consumer>
  );
};

export default LocationAddressPopup;
