interface ITag {
  name: string;
  nameLocal: string;
  value: string;
}

export interface IWidgetTag {
  id: string | number;
  color: string;
  url: string;
}

export const tags: ITag[] = [
  {
    name: 'Combo',
    nameLocal: 'Combo',
    value: 'SUPER_COMBO',
  },
];

export const STATIC_CATEGORY = [
  {
    id: "ShopByBrand",
    pathname: "/thuong-hieu",
    key: "shopByBrand"
  }
]

export const WIDGET_TAGS: IWidgetTag[] = [
  {
    id: "GYOMU_SUPER",
    color: "#D3F1A7",
    url: "",
  },
  {
    id: "BEST_DEALS",
    color: "#FDD0EC",
    url: "",
  },
  {
    id: "NEW_PRODUCTS",
    color: "#F8E6A0",
    url: "",
  },
  {
    id: "BEST_PRICES",
    color: "#C6EDFB",
    url: "",
  },
  {
    id: "THUONG_HIEU_KAMEREO",
    color: "#D4EDD5",
    url: "",
  },
]

export const ALL_PRODUCTS = 'All';
