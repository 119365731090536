import { Form, FormInstance } from "antd";
import { DEFAULT_REGION_COORDS, FIELD_NAME_FULL_TEXT } from "../constants";

const useWatchFieldChange = (form: FormInstance, regions?: string[]) => {
  const address = Form.useWatch(FIELD_NAME_FULL_TEXT.addressDetail, form);
  const ward = Form.useWatch(FIELD_NAME_FULL_TEXT.ward, form);
  const geoLat = Form.useWatch('geoLat', form);
  const geoLon = Form.useWatch('geoLon', form);
  const isReadyForMap = !!address;
  const defaultCoords = regions?.length ? DEFAULT_REGION_COORDS[regions[0]] : null;
  const isNotDefaultCoords = defaultCoords && defaultCoords.geoLat !== geoLat && defaultCoords.geoLon !== geoLon;
  const isReadyToSubmit = !!isReadyForMap && !!ward && !!geoLat && !!geoLon && !!isNotDefaultCoords;
  return {
    isReadyForMap: isReadyForMap,
    isMissingWard: !ward,
    isReadyToSubmit: isReadyToSubmit,
  }
}

export default useWatchFieldChange;