import { Skeleton, Tag } from 'antd';
import CardLink from 'components/CardLink/CardLink';
import { QuantityInputContainer } from 'components/MarketItem/QuantityInput/QuantityInput';
import { getImageByCategory } from 'containers/MarketPage/ImageByCategory';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Product, TrackingAddToCartProperty, TrackingProductImpressionProperty } from 'types/schema';
import { getLimitedTimeOfferPercent } from 'utils/discount';
import generateProductSlug from 'utils/generateProductSlug';
import media from 'utils/mediaStyle';
import utilsMessages from 'utils/messages';
import { Card } from '../Card/Card';
import Details from './Details/';
import Favorite, { Favorite as FavoriteContainer, FavoriteIcon } from './Favorite';
import Heart from './Heart.svg';
import { Image } from './Image';
import VisuallyHidden from 'components/VisuallyHidden';
import generateResponsiveImage from 'utils/generateResponsiveImage';
import { RESPONSIVE_IMAGE_SIZES } from 'utils/urls/constants';

const Container = styled(Card)<any>`
  position: relative;
  border: none;
  height: 100%;

  @media (hover: hover) {
    ${QuantityInputContainer} {
      transition: all 0.2s ease-in-out;
      &.empty {
        opacity: 1;
      }
    }

    &:hover {
      ${QuantityInputContainer} {
        &.empty {
          opacity: 1;
        }
      }
    }
  }

  ${media.md`
    width: 100%;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  `};
`;

const TagContainer = styled.div`
  position: absolute;
  bottom: 0px;
  right: 0;
  gap: 4px;
  flex-direction: column;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  ${media.md`
    bottom: 16px;
  `};
`;

const CustomTag = styled(Tag)`
  &.ant-tag {
    background: ${(props) => (props.bgcolor ? props.bgcolor : '#ff4852')};
    border: none;
    color: #ffffff;
    font-size: 12px;
    margin-right: 0;
    padding: 2px;
    border-radius: 4px 0px;

    &.discount-tag {
      top: 0;
      border: none;
      border-radius: 0 4px 4px 0;
      position: absolute;
      top: 0;
      left: 0;

      ${media.md`
        top: 16px;
      `};
    }

    ${media.md`
      padding: 4px;
      font-size: 14px;
      border-radius: 4px 0px 0px 4px;
    `};
  }
`;

const CardLinkContent = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.vertialOnly ? 'column' : 'row')};
  position: relative;
  height: 100%;
  ${media.md`
    flex-direction: column;
  `};
`;

const ImageContainer = styled.div`
  position: relative;
  padding: ${(props) => (props.vertialOnly ? '4px' : '8px 0px 0 8px')};
  width: ${(props) => (props.vertialOnly ? '100%' : '100px')};
  ${media.md`
    width: 100%;
    padding: 0;
  `};
`;

type ImpressionProperty = Pick<TrackingProductImpressionProperty, 'type' | 'tag' | 'keyword'>;

type AddToCardProperty = Pick<TrackingAddToCartProperty, 'addFrom' | 'tag'>;
export interface MarketItemProps {
  product: Product;
  noImage?: boolean;
  position?: number;
  onToggleFavorite: () => void;
  maxQuantity?: number;
  added: number;
  adding: boolean;
  loggedIn: boolean;
  loading?: boolean;
  listName?: string;
  favorite?: boolean | null;
  permissions: string[];
  note?: string | null;
  onOpenNote: () => void;
  onDeleteNote: () => void;
  gotoSignIn: () => void;
  vertialOnly?: boolean;
  impressionProperties: ImpressionProperty;
  addToCartProperties: AddToCardProperty;
  isHideNote?: boolean
}

const addFavoritePermission = 'BUYER_FAVORITE_ITEM_ADD';

const MarketItem = (props: MarketItemProps) => {
  const {
    loggedIn,
    addToCartProperties,
    gotoSignIn,
    product: { price, originalPrice, imageUrl, supplier, category, name, tags, supplierInternalCode },
    isHideNote
  } = props;

  const responsiveImageUrl = useMemo(() => {
    return imageUrl
      ? generateResponsiveImage(imageUrl, RESPONSIVE_IMAGE_SIZES.SMALL, RESPONSIVE_IMAGE_SIZES.SMALL)
      : imageUrl;
  }, [imageUrl]);
  const image = category && !imageUrl ? getImageByCategory(category) : responsiveImageUrl;
  const favorite = props.favorite ? 'true' : 'false';
  const supplierId = supplier && supplier.id;
  const supplierName = supplier && supplier.name;
  const itemDiscountExists = originalPrice > price;
  const lang = localStorage.getItem('lang') || 'vi';
  const detailProps = {
    ...props,
    ...addToCartProperties,
    loggedIn: loggedIn,
    product: {
      ...props.product,
      image: image,
      favorite: favorite,
      supplierId: supplierId,
      supplierName: supplierName,
      note: props.note,
    },
  };

  const onClickToggleFavorite = () => {
    props.onToggleFavorite();
  };

  return (
    <Container title={name} bordered={false}>
      {props.loading ? (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {!props.noImage && (
            <Skeleton className="card-skeleton" title={false} active avatar={{ shape: 'square' }} paragraph={false} />
          )}
          <Skeleton title={false} paragraph={{ rows: 4, width: '100%' }} active />
        </div>
      ) : (
        <CardLink>
          <CardLinkContent vertialOnly={props.vertialOnly}>
            {!loggedIn && (
              <FavoriteContainer type="secondary" shape="circle" onClick={gotoSignIn}>
                <FavoriteIcon src={Heart} width="16px" height="14px" />
              </FavoriteContainer>
            )}
            {props.permissions.indexOf(addFavoritePermission) > -1 && (
              <Favorite onClick={onClickToggleFavorite} favorite={favorite} />
            )}
            <div>
              <Link to={`/products/${generateProductSlug(name, supplierInternalCode, lang)}`}>
                <ImageContainer style={{ position: 'relative' }} vertialOnly={props.vertialOnly}>
                  {!props.noImage && image && <Image zoomIn src={image} alt={String(name)} />}
                  <VisuallyHidden asComponent="span">{props.product.name}</VisuallyHidden>
                  {itemDiscountExists && (
                    <CustomTag aria-hidden="true" className="discount-tag">
                      {getLimitedTimeOfferPercent(originalPrice, price)}
                    </CustomTag>
                  )}
                  <TagContainer>
                    {(tags || []).includes('NEW_PRODUCTS') && (
                      <>
                        <CustomTag aria-hidden="true" bgcolor="#40A9FF">
                          <FormattedMessage {...utilsMessages.newTag} />
                        </CustomTag>
                      </>
                    )}
                    {(tags || []).includes('BOOK_IN_ADVANCE') && (
                      <div>
                        <CustomTag aria-hidden="true" bgcolor="#E5C03C">
                          <FormattedMessage {...utilsMessages.bookInAdvanceTag} />
                        </CustomTag>
                      </div>
                    )}
                  </TagContainer>
                </ImageContainer>
              </Link>
            </div>
            <div style={{ flex: 1 }}>
              <Details {...detailProps} />
            </div>
          </CardLinkContent>
        </CardLink>
      )}
    </Container>
  );
};

const areEqual = (prevProps, nextProps) => {
  return (
    prevProps.product.id === nextProps.product.id &&
    prevProps.favorite === nextProps.favorite &&
    prevProps.loading === nextProps.loading &&
    prevProps.added === nextProps.added &&
    prevProps.adding === nextProps.adding &&
    prevProps.note === nextProps.note &&
    prevProps?.permissions?.length === nextProps?.permissions?.length
  );
};

export default React.memo(MarketItem, areEqual);
