import parseSlug from 'utils/parseSlug';
import { suffixCategoriesRegexp } from './constants';
import { Category } from 'types/schema';
import generateProductSlug, { convertStringToSlug } from 'utils/generateProductSlug';

export const getPropertyFromCategory = (category: Category, nameType: string) => {
  const isParent = category.parent;
  return isParent ? category.parent?.[nameType] : category[nameType];
};

export const getCategoryIDFromSlug = (slug: string) => {
  const categoryCodeName = `-${parseSlug(slug)}`;
  const codename = categoryCodeName?.match(suffixCategoriesRegexp.categories.codename)?.[0];
  const categoryID = codename ? parseInt(codename.slice(2), 10) : 0;
  return categoryID;
};

export const generateSlugFromID = (category: Category, lang: string) => {
  if (!category) return '';
  if (category.parent) {
    const parentSlug = convertStringToSlug(category.parent.nameLocal);
    return `${parentSlug}/${generateProductSlug(category.nameLocal, `c${category.id || 0}`, lang)}`;
  }
  return generateProductSlug(category.nameLocal, `c${category.id || 0}`, lang);
};
