import React, { memo, useCallback } from 'react';
import Banner from '../Banner';
import match from 'utils/match';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  BannerItemUnion,
  Category,
  GoToScreenBannerItem,
  ProductFilterType,
  ShowPopupBannerItem,
  ShowWebViewBannerItem,
} from 'types/schema';
import { generateSlugFromID } from 'utils/urls/categoriesPathSegment';
import { connect } from 'react-redux';
import generateProductSlug from 'utils/generateProductSlug';
import { ContainerState as MainState } from 'containers/MainLayout/types';
import { generateUrlBrandDetail } from 'utils/generateBrandDetailUrl';
interface IProps extends RouteComponentProps {
  banner: BannerItemUnion;
  onGoToPage?: (banner: GoToScreenBannerItem) => void;
  onOpenPopup?: (popupData: ShowPopupBannerItem | ShowWebViewBannerItem) => void;
  main: MainState;
}

const BannerWidget = (props: IProps) => {
  const { banner, onOpenPopup, history, main } = props;

  const onClickGoToPage = useCallback(() => {
    const lang = localStorage.getItem('lang') || 'vi';
    const bannerGotoPageItem = banner as GoToScreenBannerItem;
    switch (bannerGotoPageItem?.screen?.filter?.filterType) {
      case ProductFilterType.Tag: {
        if (bannerGotoPageItem?.screen?.filter?.tags?.length) {
          history.push(`/${(bannerGotoPageItem.screen.filter.tags || '').toString()}`);
        }
        break;
      }
      case ProductFilterType.Category: {
        if (bannerGotoPageItem?.screen?.filter?.categoryInfo?.id) {
          const handleFlattenCategories = (categories: Category[]) => {
            return categories.reduce((accumulate: any[], category: Category) => {
              const route = {
                ...category,
                path: `/${generateSlugFromID(category, lang)}`,
              };
              accumulate.push(route);
              if ('children' in category) {
                const childrenRoutes = handleFlattenCategories(category.children);
                accumulate.push(...childrenRoutes);
              }
              return accumulate;
            }, []);
          };
          const flattenCategories = handleFlattenCategories(main.categories);
          const targetCategory = flattenCategories.find(
            (category) => Number(category.id) === Number(bannerGotoPageItem.screen?.filter?.categoryInfo?.id),
          );
          if (targetCategory) {
            history.push(targetCategory?.path);
          }
        }
        break;
      }
      case ProductFilterType.Brand: {
        if (bannerGotoPageItem?.screen?.filter?.brandInfo?.id) {
          const { name, id } = bannerGotoPageItem?.screen?.filter?.brandInfo;
          if (id) {
            history.push(`${generateUrlBrandDetail(id.toString(), name || '')}`);
          }
        }
        break;
      }
      case ProductFilterType.Product: {
        if (bannerGotoPageItem?.screen?.filter?.productInfo?.id) {
          const { name, supplierInternalCode } = bannerGotoPageItem?.screen?.filter?.productInfo;
          history.push(`/products/${generateProductSlug(name, supplierInternalCode, lang)}`);
        }
        break;
      }
      case ProductFilterType.Url: {
        if (bannerGotoPageItem?.screen?.filter?.urlInfo?.url) {
          window.open(bannerGotoPageItem?.screen.filter.urlInfo?.url, '_blank');
        }
        break;
      }
    }
  }, [banner, history]);

  const onClickOpenPopup = useCallback(() => {
    const bannerPopupContent: ShowPopupBannerItem = banner as ShowPopupBannerItem;
    if (onOpenPopup) onOpenPopup(bannerPopupContent);
  }, [banner, onOpenPopup]);

  const onClickOpenIframePopup = useCallback(() => {
    const bannerPopupIframeContent = banner as ShowPopupBannerItem;
    if (onOpenPopup) onOpenPopup(bannerPopupIframeContent);
  }, [banner, history]);

  return (
    <>
      {match(banner, {
        GoToScreenBannerItem: () => {
          return <Banner imageUrl={banner.imageUrl} onClick={onClickGoToPage} />;
        },
        ShowPopupBannerItem: () => <Banner imageUrl={banner.imageUrl} onClick={onClickOpenPopup} />,
        ShowWebViewBannerItem: (banner: ShowWebViewBannerItem) => (
          <Banner imageUrl={banner.imageUrl} onClick={onClickOpenIframePopup} />
        ),
      })}
    </>
  );
};

// export default withRouter(memo(BannerWidget));

export default connect((state) => state)(withRouter(memo(BannerWidget)));
