import { createAsyncAction } from 'typesafe-actions';
import ActionTypes from './constants';

export const findFullAddress = createAsyncAction(
  ActionTypes.FIND_FULL_ADDRESS_REQUEST,
  ActionTypes.FIND_FULL_ADDRESS_SUCCESS,
  ActionTypes.FIND_FULL_ADDRESS_FAILURE,
)<void, {}, Error>();

export const handleStore = createAsyncAction(
  ActionTypes.HANDLE_STORE_REQUEST,
  ActionTypes.HANDLE_STORE_SUCCESS,
  ActionTypes.HANDLE_STORE_FAILURE,
)<void, {}, Error>();

