import { pathnameChecker } from './url';
import createSinglePathSegment from './urls/createSinglePathSegment';

const SEGMENT_PARAMETERS = ['categoryIds', 'tags'];

interface SegmentObject {
  categoryIds: number;
  tags: string;
  page: string;
}

export const getPathSegmentFromSlug = (location: any) => {
  const segmentObject: SegmentObject = { categoryIds: 0, tags: '', page: '' };
  if (location?.pathname && pathnameChecker.isProductPage(location?.pathname)) return segmentObject;

  const pathname = location?.pathname?.slice(1);

  segmentObject.categoryIds = createSinglePathSegment(pathname, 'category') as SegmentObject['categoryIds'];
  segmentObject.tags = createSinglePathSegment(pathname, 'tags') as SegmentObject['tags'];
  segmentObject.page = createSinglePathSegment(location.search, 'page') as SegmentObject['page'];

  return segmentObject;
};

export const createSearchQueryfromSlug = (location: any, segment: SegmentObject) => {
  const categoryQueryString = SEGMENT_PARAMETERS.reduce((qs, segmentName) => {
    const segmentValue = segment[segmentName];
    if (segmentValue) {
      return qs.concat(`&${segmentName}=${segmentName !== 'page' ? encodeURIComponent(segmentValue) : segmentValue}`);
    }
    return qs;
  }, '');
  return location.search && !location.search.includes('?lang=')
    ? `${location.search}${categoryQueryString}`
    : `?${segment.page && `page=${segment.page}`}${categoryQueryString}`;
};
