/*
 * MarketPage Messages
 *
 * This contains all the text for the MarketPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.HomePage';

export default defineMessages({
  kamereo: {
    id: `${scope}.kamereo`,
    defaultMessage: 'KAMEREO',
  },
  dontShowThisAgain: {
    id: `${scope}.dontShowThisAgain`,
    defaultMessage: 'Không hiển thị thông tin này lần nữa',
  },
  homePage: {
    id: `${scope}.homePage`,
    defaultMessage: 'Trang chủ',
  },
  welcomeToKamereo: {
    id: `${scope}.welcomeToKamereo`,
    defaultMessage: 'Chào mừng bạn đến với kamereo',
  },
  recommendationTitle: {
    id: `${scope}.recommendationTitle`,
    defaultMessage: 'Sản phẩm đề xuất',
  },
  recommendationSubTitle: {
    id: `${scope}.recommendationSubTitle`,
    defaultMessage: 'Được mua nhiều nhất trong danh mục kinh doanh Nhà Hàng Âu',
  },
  viewCart: {
    id: `${scope}.viewCart`,
    defaultMessage: 'Xem giỏ hàng',
  },
});
