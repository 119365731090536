import gql from 'graphql-tag'

export default gql`
  mutation putItemsToFavorite($itemUuids: [String]!) {
    putItemsToFavorite(itemUuids: $itemUuids) {
      success
      userErrors {
        field
        message
      }
    }
  }
`
