import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import Button from 'components/Button/Button';
import utilMessages from 'utils/messages';
import { FormattedMessage } from 'react-intl';

export const NoteSm = styled.p`
  font-weight: 300;
  color: #8c8c8c;
  margin-top: 2px;

  ${({ isShowNote }) =>
    !isShowNote &&
    `
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
  `}
`;

const NoteAction = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
`;

const ActionContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
`;

const AddNoteButton = styled(Button)`
  color: #2f904f;
  font-size: 12px !important;
  padding: 0 !important;
  text-align: left;
`;

const DeleteNoteButton = styled(Button)`
  color: #f5222d;
  font-size: 12px !important;
  padding: 0 !important;
`;

interface IProps {
  isShowNote?: boolean;
  note?: string | ReactNode;
  onClickOpenNote: () => void;
  onClickDeleteNote: () => void;
}

const ProductNote: FC<IProps> = (props) => {
  const { isShowNote = true, note, onClickOpenNote, onClickDeleteNote } = props;

  if (!!note) {
    return (
      <NoteAction>
        {isShowNote && <NoteSm isShowNote={isShowNote}>{note}</NoteSm>}
        <ActionContainer>
          <AddNoteButton type="link" size="small" onClick={onClickOpenNote}>
            <FormattedMessage {...utilMessages.edit} />
          </AddNoteButton>
          <DeleteNoteButton type="link" size="small" onClick={onClickDeleteNote}>
            <FormattedMessage {...utilMessages.remove} />
          </DeleteNoteButton>
        </ActionContainer>
      </NoteAction>
    );
  }
  return (
    <NoteAction>
      <AddNoteButton type="link" size="small" onClick={onClickOpenNote}>
        <FormattedMessage {...utilMessages.note} />
      </AddNoteButton>
    </NoteAction>
  );
};

export default ProductNote;
