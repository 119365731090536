import { Avatar, Flex, Skeleton, Typography } from 'antd';
import { selectLoading, selectSections } from 'containers/HomePage/selectors';
import { ILoadingSection, ISection } from 'containers/HomePage/types';
import React, { FC, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import styled from 'styles/styled-components';
import { ApplicationRootState } from 'types';
import utilsMessages from 'utils/messages';
import SVGIcon from '../SVGIcon/SVGIcon';
import Arrow from './Arrow.svg';
import { getInitials } from 'utils/getInitialsBrandName';
import { generateUrlBrandDetail } from '../../utils/generateBrandDetailUrl';

const { Title } = Typography;

const SectionTitle = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const SectionHeading = styled(Title)`
  &.ant-typography {
    font-size: 20px;
    line-height: 1.4;
  }
`;

const WidgetWrapper = styled('div')`
  display: grid;
  padding: 16px 16px 0 16px;
  background-color: #fff;
  margin-bottom: 16px;
  border-radius: 4px;
  @media (max-width: 576px) {
    &:last-child {
      margin-bottom: 32px;
    }
  }
`;

const List = styled('div')`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding: 1px;
`;

const Item = styled('div')`
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  margin: 0 16px 24px 0;
  flex: 0 0 152px;
  height: 152px;
  padding-bottom: 10px;
  width: 152px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  color: #595959;
`;
const ItemImg = styled(Avatar)`
  flex: 1 1 0%;
  margin-top: 8px;
  img {
    object-fit: contain;
  }
`;
const BrandName = styled('div')`
  flex: 1 1 0%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  color: #242527;
`;
const BrandButton = styled.div`
  width: 120px;
  padding: 8px;
  border-radius: 8px;
  margin-top: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(158, 158, 158, 0.1);
`;
const BrandButtonName = styled.span`
  font-size: 14px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  flex-grow: 1;
  text-align: center;
`;
const SkeletonWrapper = styled.div`
  background: #ffffff;
  padding: 16px;
  overflow: hidden;
`;
interface IStateProps {
  sections: ISection;
  loading: ILoadingSection | boolean;
}

type Props = IStateProps;

const BrandWidget: FC<Props> = (props) => {
  const { sections } = props;

  const brandsData = useMemo(() => {
    return (sections && sections?.TRUSTED_BRANDS?.data) || [];
  }, [sections]);

  const SkeletonLoading = () => {
    return (
      <>
        <Skeleton.Input active={true} style={{ marginBottom: '12px', height: '25px', width: '180px' }} />
        <Flex gap={16}>
          {/* {Array.from({ length: 16 }, (_, index) => index).map((item) => ( */}
          <Flex vertical align="center" justify="center">
            <Skeleton.Image active={true} style={{ marginBottom: '12px' }} />
            <Skeleton.Button active={true} style={{ height: '25px', width: '80px' }} />
          </Flex>
          <Flex vertical align="center" justify="center">
            <Skeleton.Image active={true} style={{ marginBottom: '12px' }} />
            <Skeleton.Button active={true} style={{ height: '25px', width: '80px' }} />
          </Flex>
          <Flex vertical align="center" justify="center">
            <Skeleton.Image active={true} style={{ marginBottom: '12px' }} />
            <Skeleton.Button active={true} style={{ height: '25px', width: '80px' }} />
          </Flex>
          <Flex vertical align="center" justify="center">
            <Skeleton.Image active={true} style={{ marginBottom: '12px' }} />
            <Skeleton.Button active={true} style={{ height: '25px', width: '80px' }} />
          </Flex>
          <Flex vertical align="center" justify="center">
            <Skeleton.Image active={true} style={{ marginBottom: '12px' }} />
            <Skeleton.Button active={true} style={{ height: '25px', width: '80px' }} />
          </Flex>
          <Flex vertical align="center" justify="center">
            <Skeleton.Image active={true} style={{ marginBottom: '12px' }} />
            <Skeleton.Button active={true} style={{ height: '25px', width: '80px' }} />
          </Flex>
          {/* ))} */}
        </Flex>
      </>
    );
  };

  return (
    <>
      {!!brandsData?.length ? (
        <WidgetWrapper>
          <SectionTitle id="trusted-brands">
            <SectionHeading level={2}>
              <FormattedMessage {...utilsMessages.trustedBrands} />
            </SectionHeading>
            <Link to="/thuong-hieu">
              <FormattedMessage {...utilsMessages.seeAll} />
            </Link>
          </SectionTitle>
          <List>
            {brandsData.map((item) => (
              <Link key={item.id} to={generateUrlBrandDetail(item.id, item?.name as string)}>
                <Item key={item.id}>
                  {!item.imageUrl ? (
                    <BrandName>{getInitials(item.name)}</BrandName>
                  ) : (
                    <ItemImg size={92} src={item.imageUrl} shape="square" />
                  )}
                  <BrandButton>
                    <BrandButtonName>{item.name}</BrandButtonName>
                    <SVGIcon src={Arrow} />
                  </BrandButton>
                </Item>
              </Link>
            ))}
          </List>
        </WidgetWrapper>
      ) : (
        <SkeletonWrapper>
          <SkeletonLoading />
        </SkeletonWrapper>
      )}
    </>
  );
};

const mapStateToProps = createStructuredSelector<ApplicationRootState, IStateProps>({
  sections: selectSections(),
  loading: selectLoading(),
});

const withConnect = connect<IStateProps>(mapStateToProps);

export default withConnect(BrandWidget);
