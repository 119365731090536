import { Category } from 'types/schema';
/**
 * Find category from categories
 * @param categories is a list of categories
 * @param categoryId is a category id
 * @returns A category object
 */

function findCategory(categories: Category[], categoryId: number): Category | undefined {
  let targetCategory: Category | undefined;
  targetCategory = categories.find((c: Category) => c.id === categoryId);
  if (!targetCategory) {
    categories.forEach((c: Category) => {
      if (c.children && c.children.length) {
        const child = c.children.find((child: Category) => child.id === categoryId);
        if (child) {
          targetCategory = child;
        }
      }
    });
  }
  return targetCategory;
}

export default findCategory;