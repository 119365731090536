export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An ISO-8601 encoded date string */
  Date: any;
  /** An ISO-8601 encoded UTC date string. */
  DateTime: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};


export type ApiResponse = MutationResponse & {
  __typename?: 'APIResponse';
  success: Scalars['Boolean'];
  userErrors: Array<UserError>;
};

export enum AccountBuyerStatus {
  Normal = 'NORMAL',
  Blocked = 'BLOCKED',
  NotActivated = 'NOT_ACTIVATED'
}

export type AddStoreDisableResponse = {
  __typename?: 'AddStoreDisableResponse';
  addStoreDisable: Scalars['Boolean'];
  message: Scalars['String'];
};

export type AddressComponent = {
  __typename?: 'AddressComponent';
  long_name?: Maybe<Scalars['String']>;
  short_name?: Maybe<Scalars['String']>;
  types?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum AggregationInterval {
  Day = 'DAY',
  Month = 'MONTH'
}

export type AllUomsResponse = {
  __typename?: 'AllUomsResponse';
  data?: Maybe<Array<Maybe<UomElement>>>;
};

export type AppVersionResponse = {
  __typename?: 'AppVersionResponse';
  version: Scalars['String'];
  force: Scalars['Boolean'];
};

export type AvailableVouchersForStoreInput = {
  storeId: Scalars['Int'];
  storeUuid: Scalars['String'];
  buyerId?: Maybe<Scalars['Int']>;
  draftOrderId?: Maybe<Scalars['String']>;
  cartTotal?: Maybe<Scalars['Float']>;
};

export type AvailableVouchersForStoreResponse = {
  __typename?: 'AvailableVouchersForStoreResponse';
  data?: Maybe<Array<Maybe<Voucher>>>;
};

export type BannerItemUnion = GoToScreenBannerItem | ShowPopupBannerItem | ShowWebViewBannerItem;

export type BannerProductFilterBrandInfo = {
  __typename?: 'BannerProductFilterBrandInfo';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export type BannerProductFilterCategoryInfo = {
  __typename?: 'BannerProductFilterCategoryInfo';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type BannerProductFilterProductInfo = {
  __typename?: 'BannerProductFilterProductInfo';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  supplierInternalCode?: Maybe<Scalars['String']>;
};

export type BannerProductFilterUrlInfo = {
  __typename?: 'BannerProductFilterUrlInfo';
  url?: Maybe<Scalars['String']>;
};

export type BrandInfoItem = {
  __typename?: 'BrandInfoItem';
  id?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export enum BusinessBarPubCategory {
  BarPub = 'BAR_PUB',
  NightClub = 'NIGHT_CLUB'
}

export enum BusinessCafeCategory {
  CafeBakeryIndependent = 'CAFE_BAKERY_INDEPENDENT',
  CafeBakeryChain = 'CAFE_BAKERY_CHAIN'
}

export enum BusinessFoodServiceCategory {
  CateringAndCanteen = 'CATERING_AND_CANTEEN',
  Factory = 'FACTORY',
  School = 'SCHOOL',
  Hospital = 'HOSPITAL',
  Office = 'OFFICE',
  Gym = 'GYM',
  ConventionCenter = 'CONVENTION_CENTER'
}

export enum BusinessHotelCategory {
  Hotel_1_3Star = 'HOTEL_1_3_STAR',
  Hotel_4_5Star = 'HOTEL_4_5_STAR',
  HotelNormal = 'HOTEL_NORMAL'
}

export enum BusinessReSellerCategory {
  ConvenientStore = 'CONVENIENT_STORE',
  SuperMarket = 'SUPER_MARKET',
  FineRetail = 'FINE_RETAIL',
  TraditionalRetail = 'TRADITIONAL_RETAIL',
  RetailECommerce = 'RETAIL_E_COMMERCE',
  Wholesaler = 'WHOLESALER'
}

export enum BusinessRestaurantCategory {
  RestaurantVietnamese = 'RESTAURANT_VIETNAMESE',
  RestaurantWestern = 'RESTAURANT_WESTERN',
  RestaurantJapanese = 'RESTAURANT_JAPANESE',
  RestaurantKorean = 'RESTAURANT_KOREAN',
  RestaurantChinese = 'RESTAURANT_CHINESE',
  RestaurantThai = 'RESTAURANT_THAI',
  RestaurantVegetarian = 'RESTAURANT_VEGETARIAN',
  RestaurantChain = 'RESTAURANT_CHAIN'
}

export enum BusinessSmallBusinessCategory {
  TakeAwayOnlineKitchen = 'TAKE_AWAY_ONLINE_KITCHEN',
  DrinkRestaurant = 'DRINK_RESTAURANT',
  FoodStore = 'FOOD_STORE'
}

export enum BusinessTeam {
  Sme = 'SME',
  KaModernTrade = 'KA_MODERN_TRADE',
  KaFoodChain = 'KA_FOOD_CHAIN',
  KaSchoolFactory = 'KA_SCHOOL_FACTORY',
  KaHotelConvention = 'KA_HOTEL_CONVENTION'
}

export enum BusinessTypes {
  Restaurant = 'RESTAURANT',
  Cafe = 'CAFE',
  BarClub = 'BAR_CLUB',
  Hotel = 'HOTEL',
  Reseller = 'RESELLER',
  FoodService = 'FOOD_SERVICE',
  SmallBusiness = 'SMALL_BUSINESS'
}

export type Buyer = {
  __typename?: 'Buyer';
  name: Scalars['String'];
  id: Scalars['ID'];
  telephone?: Maybe<Scalars['String']>;
  location?: Maybe<Location>;
  status?: Maybe<BuyerStatus>;
  imageUrl?: Maybe<Scalars['String']>;
  stores: Array<Store>;
  redInvoiceInfo?: Maybe<CompanyRedInvoiceInfo>;
  userGroups: Array<UserGroup>;
  enabledFeatures: Array<FeatureFlag>;
  fullAddress?: Maybe<FullAddress>;
  isFreeshipOffer?: Maybe<Scalars['Boolean']>;
  region?: Maybe<Scalars['String']>;
  businessCategory?: Maybe<Scalars['String']>;
  accountBuyerStatus?: Maybe<AccountBuyerStatus>;
};

export type BuyerCreateAccountInput = {
  companyName: Scalars['String'];
  telephone: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  lang: Language;
  redInvoiceInfo?: Maybe<CompanyCreateRedInvoiceInput>;
  wardId?: Maybe<Scalars['String']>;
  businessType?: Maybe<Scalars['String']>;
  businessCategory?: Maybe<Scalars['String']>;
  addressNo?: Maybe<Scalars['String']>;
  roleAtBusiness?: Maybe<Scalars['String']>;
  acquisitionSource?: Maybe<Scalars['String']>;
  buyerCustomerContactRequestId?: Maybe<Scalars['Int']>;
  companyWebsite?: Maybe<Scalars['String']>;
  branchNumber: Scalars['Int'];
  workingHoursStart: Scalars['String'];
  workingHoursEnd: Scalars['String'];
  salePicId: Scalars['String'];
  businessTeam: Scalars['String'];
  buyerRepresentative: Scalars['String'];
  buyerRepresentativeEmail: Scalars['String'];
  linkHubspot: Scalars['String'];
  createdBy: Scalars['String'];
};

export enum BuyerCustomerContactRequestStatus {
  New = 'NEW',
  Rejected = 'REJECTED',
  Done = 'DONE'
}

export enum BuyerPriority {
  VipCustomer = 'VIP_CUSTOMER',
  KeyCustomer = 'KEY_CUSTOMER',
  RegularCustomer = 'REGULAR_CUSTOMER'
}

export enum BuyerStatus {
  Disabled = 'DISABLED',
  Deleted = 'DELETED',
  Public = 'PUBLIC',
  Private = 'PRIVATE',
  NotApproved = 'NOT_APPROVED'
}

export type BuyerUpdateInput = {
  name: Scalars['String'];
  telephone: Scalars['String'];
  location: LocationInput;
  imageUrl: Scalars['String'];
  userId: Scalars['String'];
};

export type BuyerWebVersionResponse = {
  __typename?: 'BuyerWebVersionResponse';
  version: Scalars['String'];
};

export type Csv = {
  __typename?: 'CSV';
  content: Scalars['String'];
};

export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}

export type CalculateVoucherDiscountInput = {
  draftOrderId?: Maybe<Scalars['String']>;
  storeId: Scalars['String'];
  voucherIds: Array<Maybe<Scalars['Int']>>;
  userId: Scalars['String'];
  buyerId: Scalars['Int'];
};

export type CalculateVoucherDiscountResponse = {
  __typename?: 'CalculateVoucherDiscountResponse';
  total?: Maybe<Scalars['Float']>;
};

export type CarouselBanner = {
  __typename?: 'CarouselBanner';
  items: Array<BannerItemUnion>;
};

export type CarouselBannerWidget = {
  __typename?: 'CarouselBannerWidget';
  type: Scalars['String'];
  widgetId: Scalars['String'];
  banner: CarouselBanner;
};

export type Cart = {
  __typename?: 'Cart';
  buyer?: Maybe<Buyer>;
  redInvoiceInfo?: Maybe<RedInvoiceInfo>;
  deliveryNotes: Array<Scalars['String']>;
  user?: Maybe<User>;
  store?: Maybe<Store>;
  cartSet: Array<SingleSupplierCart>;
};

export type CartCreateOrderDraftInput = {
  storeId: Scalars['String'];
};

export type CartCreateOrderDraftResponse = MutationResponse & {
  __typename?: 'CartCreateOrderDraftResponse';
  userErrors: Array<UserError>;
  success: Scalars['Boolean'];
  draftToken?: Maybe<Scalars['String']>;
};

export type CartDeleteItemInput = {
  storeId: Scalars['String'];
  productId: Scalars['ID'];
  supplierId: Scalars['Int'];
};

export type CartItem = {
  __typename?: 'CartItem';
  product?: Maybe<Product>;
  quantity?: Maybe<Scalars['Float']>;
  /** @deprecated Use product unitPrice field */
  price?: Maybe<Scalars['Float']>;
  /** @deprecated This doesn't make sense. */
  orderedPrice?: Maybe<Scalars['Float']>;
};

export type CartPutItemInput = {
  storeId: Scalars['String'];
  productId: Scalars['ID'];
  supplierId: Scalars['Int'];
  quantity: Scalars['Float'];
};

export type CartResponse = MutationResponse & {
  __typename?: 'CartResponse';
  userErrors: Array<UserError>;
  success: Scalars['Boolean'];
  cart?: Maybe<Cart>;
};

export type Category = {
  __typename?: 'Category';
  nameLocal: Scalars['String'];
  name: Scalars['String'];
  parent?: Maybe<Category>;
  children: Array<Category>;
  productCount?: Maybe<Scalars['Int']>;
  sortOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

export type CategoryByGroup = {
  __typename?: 'CategoryByGroup';
  cateId?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
};

export type CategoryDetail = {
  __typename?: 'CategoryDetail';
  id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  nameLocal: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  descriptionVn?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['Int']>;
};

export type CategoryScreen = {
  __typename?: 'CategoryScreen';
  name: Scalars['String'];
  title: Scalars['String'];
  filter: WidgetFilter;
  sort: Array<WidgetSort>;
  headerImageUrl?: Maybe<Scalars['String']>;
};

export type CheckStoreIsDuplicateInput = {
  name: Scalars['String'];
  location: LocationInput;
};

export type CheckStoreIsDuplicateResponse = {
  __typename?: 'CheckStoreIsDuplicateResponse';
  isDuplicate: Scalars['Boolean'];
};

export type ClosePopupModal = {
  __typename?: 'ClosePopupModal';
  type: Scalars['String'];
};

export type CompanyCreateRedInvoiceInput = {
  taxCode: Scalars['String'];
  location: LocationInput;
  companyName: Scalars['String'];
  emailToReceiveEInvoice: Scalars['String'];
  wardId: Scalars['String'];
};

export type CompanyRedInvoiceInfo = {
  __typename?: 'CompanyRedInvoiceInfo';
  companyName?: Maybe<Scalars['String']>;
  location?: Maybe<Location>;
  mst?: Maybe<Scalars['String']>;
};

export type CompanyUpdateRedInvoiceInput = {
  mst: Scalars['String'];
  location: LocationInput;
  companyName: Scalars['String'];
};

export type CreateNewProductRequestInput = {
  productName: Scalars['String'];
  productLinkRef?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  isNotifyPhoneForStock: Scalars['Boolean'];
  storeUuid?: Maybe<Scalars['String']>;
  accountEmail?: Maybe<Scalars['String']>;
};

export type CreateStoreInput = {
  name: Scalars['String'];
  telephone: Scalars['String'];
  location: LocationInput;
  imageUrl?: Maybe<Scalars['String']>;
  wardId: Scalars['String'];
  geoLat?: Maybe<Scalars['Float']>;
  geoLon?: Maybe<Scalars['Float']>;
  geoPlusCode?: Maybe<Scalars['String']>;
  googleAddress?: Maybe<Scalars['String']>;
};

export enum CustomerAcquisitionSource {
  Google = 'GOOGLE',
  Facebook = 'FACEBOOK',
  Youtube = 'YOUTUBE',
  Tiktok = 'TIKTOK',
  Instagram = 'INSTAGRAM',
  OtherWebsite = 'OTHER_WEBSITE',
  KamereoSalesman = 'KAMEREO_SALESMAN',
  FriendsColleaguesAcquaintance = 'FRIENDS_COLLEAGUES_ACQUAINTANCE',
  OohAds = 'OOH_ADS',
  OfflineEvents = 'OFFLINE_EVENTS',
  Other = 'OTHER'
}

export type CustomerContact = {
  companyName: Scalars['String'];
  userName: Scalars['String'];
  phoneNumber: Scalars['String'];
  email: Scalars['String'];
};

export type CustomerContactRequest = {
  companyName: Scalars['String'];
  userName: Scalars['String'];
  phoneNumber: Scalars['String'];
  email: Scalars['String'];
  lang: Language;
  acquisitionSource?: Maybe<CustomerAcquisitionSource>;
  acquisitionSourceText?: Maybe<Scalars['String']>;
  businessType?: Maybe<Scalars['String']>;
  businessCategory?: Maybe<Scalars['String']>;
  provinceId: Scalars['String'];
  provinceName: Scalars['String'];
  districtId: Scalars['String'];
  districtName: Scalars['String'];
  wardId: Scalars['String'];
  wardName: Scalars['String'];
  addressNo: Scalars['String'];
  roleAtBusiness?: Maybe<RepresentativePersonRoles>;
};


export enum DateName {
  WeekDay = 'WeekDay',
  Saturday = 'Saturday',
  Sunday = 'Sunday'
}

export type DateRangeInput = {
  /** since is inclusive */
  since: Scalars['Date'];
  /** until is exclusive */
  until: Scalars['Date'];
};


export type DatetimeRange = {
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
};

export type DeliveredItemInput = {
  itemId: Scalars['ID'];
  deliveredQuantity: Scalars['Float'];
  deliveredNote?: Maybe<Scalars['String']>;
};

export type DeliveryDateInput = {
  storeId: Scalars['String'];
  supplierId: Scalars['Int'];
  date: Scalars['String'];
};

export type DeliveryDatetime = {
  __typename?: 'DeliveryDatetime';
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
};

export type DeliveryFee = {
  __typename?: 'DeliveryFee';
  fee?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Float']>;
  active?: Maybe<Scalars['Boolean']>;
};

export type DeliveryNote = {
  __typename?: 'DeliveryNote';
  id: Scalars['ID'];
  text: Scalars['String'];
};

export type DeliveryNoteInput = {
  storeId: Scalars['String'];
  supplierId: Scalars['Int'];
  note: Scalars['String'];
};

export type DeliveryTime = {
  __typename?: 'DeliveryTime';
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
};

export type DeliveryTimeInput = {
  storeId: Scalars['String'];
  supplierId: Scalars['Int'];
  startTime: Scalars['String'];
  endTime: Scalars['String'];
};

export type DeliveryTimeSlot = {
  __typename?: 'DeliveryTimeSlot';
  date?: Maybe<Scalars['String']>;
  timeSlots?: Maybe<Array<Maybe<TimeSlot>>>;
};

export type District = {
  __typename?: 'District';
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nameEn?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  fullNameEn?: Maybe<Scalars['String']>;
  codeName?: Maybe<Scalars['String']>;
  administrativeUnitId?: Maybe<Scalars['Int']>;
  administrativeRegionId?: Maybe<Scalars['Int']>;
  provinceCode?: Maybe<Scalars['String']>;
};

export type DistrictSearchResponse = {
  __typename?: 'DistrictSearchResponse';
  total: Scalars['Int'];
  data?: Maybe<Array<Maybe<District>>>;
};

export type EmptyPopup = {
  __typename?: 'EmptyPopup';
  type: Scalars['String'];
};

export enum FeatureFlag {
  OrderApproval = 'ORDER_APPROVAL'
}

export type FeatureFlagToggleInput = {
  buyerId: Scalars['ID'];
  featureFlag: FeatureFlag;
  enabled: Scalars['Boolean'];
};

export type FeatureFlagToggleResponse = MutationResponse & {
  __typename?: 'FeatureFlagToggleResponse';
  userErrors: Array<UserError>;
  success: Scalars['Boolean'];
  buyer: Buyer;
};

export type FindFullAddressResponse = {
  __typename?: 'FindFullAddressResponse';
  data?: Maybe<Array<Maybe<FullAddressMap>>>;
};

export type FulfillmentTime = {
  __typename?: 'FulfillmentTime';
  hoursToDelivery?: Maybe<Scalars['Int']>;
  days?: Maybe<Scalars['Int']>;
  cutoffTime?: Maybe<Scalars['String']>;
};

export type FullAddress = {
  __typename?: 'FullAddress';
  provinceCode?: Maybe<Scalars['String']>;
  districtCode?: Maybe<Scalars['String']>;
  wardCode?: Maybe<Scalars['String']>;
  provinceFullName?: Maybe<Scalars['String']>;
  districtFullName?: Maybe<Scalars['String']>;
  wardFullName?: Maybe<Scalars['String']>;
  provinceFullNameEn?: Maybe<Scalars['String']>;
  districtFullNameEn?: Maybe<Scalars['String']>;
  wardFullNameEn?: Maybe<Scalars['String']>;
  kmrRegion?: Maybe<Scalars['String']>;
};

export type FullAddressMap = {
  __typename?: 'FullAddressMap';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<FullAddress>;
};

export type GeoCodingResponse = {
  __typename?: 'GeoCodingResponse';
  results?: Maybe<Array<Maybe<GeocodeResult>>>;
  plus_code?: Maybe<PlusCode>;
};

export type GeoLocation = {
  __typename?: 'GeoLocation';
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

export type GeocodeResult = {
  __typename?: 'GeocodeResult';
  address_components?: Maybe<Array<Maybe<AddressComponent>>>;
  formatted_address?: Maybe<Scalars['String']>;
  geometry?: Maybe<Geometry>;
  place_id?: Maybe<Scalars['String']>;
  plus_code?: Maybe<PlusCode>;
  types?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Geometry = {
  __typename?: 'Geometry';
  location?: Maybe<GeoLocation>;
  location_type?: Maybe<Scalars['String']>;
  viewport?: Maybe<Viewport>;
};

export type GetAllKamereoRegionsResponse = {
  __typename?: 'GetAllKamereoRegionsResponse';
  data?: Maybe<Array<Maybe<RegionItem>>>;
};

export type GetDeliveryFeeInput = {
  storeId: Scalars['String'];
  totalPrice: Scalars['Float'];
};

export type GetDeliveryFeeResponse = {
  __typename?: 'GetDeliveryFeeResponse';
  deliveryFee?: Maybe<Scalars['Float']>;
  isFreeDelivery?: Maybe<Scalars['Boolean']>;
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type GetFrequentlyBuyTogetherResponse = {
  __typename?: 'GetFrequentlyBuyTogetherResponse';
  products?: Maybe<Array<Maybe<Product>>>;
};

export type GetIpRegionResponse = {
  __typename?: 'GetIpRegionResponse';
  region?: Maybe<Scalars['String']>;
};

export type GetVaAndQrInfoResponse = {
  __typename?: 'GetVaAndQrInfoResponse';
  vaAccountNumber?: Maybe<Scalars['String']>;
  vaName?: Maybe<Scalars['String']>;
  qrDataBase64?: Maybe<Scalars['String']>;
};

export type GoToScreenBannerItem = {
  __typename?: 'GoToScreenBannerItem';
  type: Scalars['String'];
  imageUrl: Scalars['String'];
  screen: ScreenUnion;
  order?: Maybe<Scalars['Int']>;
};

export type GoToScreenPopup = {
  __typename?: 'GoToScreenPopup';
  key: Scalars['String'];
  type: Scalars['String'];
  imageUrl: Scalars['String'];
  screen: ScreenUnion;
};

export type GoogleNearbySearchResponse = {
  __typename?: 'GoogleNearbySearchResponse';
  results?: Maybe<Array<Maybe<PlaceResult>>>;
};

export type GooglePlaceAutocompleteResponse = {
  __typename?: 'GooglePlaceAutocompleteResponse';
  predictions?: Maybe<Array<Maybe<PlacePrediction>>>;
};

export type GroupMemberAddInput = {
  groupId: Scalars['String'];
  memberIds: Array<Scalars['String']>;
};

export type GroupMemberAddResponse = MutationResponse & {
  __typename?: 'GroupMemberAddResponse';
  userErrors: Array<UserError>;
  success: Scalars['Boolean'];
};

export type GroupMemberRemoveInput = {
  groupId: Scalars['String'];
  memberIds: Array<Scalars['String']>;
};

export type GroupMemberRemoveResponse = MutationResponse & {
  __typename?: 'GroupMemberRemoveResponse';
  userErrors: Array<UserError>;
  success: Scalars['Boolean'];
};

export type Holiday = {
  __typename?: 'Holiday';
  date?: Maybe<Scalars['String']>;
  holidayNameVn?: Maybe<Scalars['String']>;
  holidayNameEn?: Maybe<Scalars['String']>;
};

export type HomePagePopupUnion = GoToScreenPopup | ShowPopup | ShowWebViewPopup | EmptyPopup;

export type HorizontalScrollBanner = {
  __typename?: 'HorizontalScrollBanner';
  items: Array<BannerItemUnion>;
};

export type HorizontalScrollBannerWidget = {
  __typename?: 'HorizontalScrollBannerWidget';
  type: Scalars['String'];
  widgetId: Scalars['String'];
  banner: HorizontalScrollBanner;
};

export type Image = {
  __typename?: 'Image';
  id: Scalars['ID'];
  url: Scalars['String'];
  alt: Scalars['String'];
};

export type Invitation = {
  __typename?: 'Invitation';
  id: Scalars['ID'];
  name: Scalars['String'];
  telephone: Scalars['String'];
  location?: Maybe<Location>;
  supplier?: Maybe<Supplier>;
  stores?: Maybe<Array<Maybe<InvitationStore>>>;
};

export type InvitationAsNewBuyerInput = {
  invitationId: Scalars['ID'];
  companyName: Scalars['String'];
  telephone: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  lang: Language;
  location: LocationInput;
};

export type InvitationStore = {
  __typename?: 'InvitationStore';
  name: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  telephone: Scalars['String'];
  location: Location;
};

export type InvitationStoreInput = {
  name: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  telephone: Scalars['String'];
  location: LocationInput;
};

export type InvitationStoresInput = {
  invitationId: Scalars['ID'];
  stores: Array<InvitationStoreInput>;
};

export enum Language {
  En = 'en',
  Vi = 'vi'
}

export type LightCart = {
  __typename?: 'LightCart';
  id: Scalars['ID'];
  buyer?: Maybe<Buyer>;
  store?: Maybe<Store>;
  /** @deprecated Calculate on client */
  total?: Maybe<Scalars['String']>;
  carts: Array<SingleSupplierLightCart>;
};

export type LightCartItem = {
  __typename?: 'LightCartItem';
  item?: Maybe<LightProduct>;
  orderedQty?: Maybe<Scalars['String']>;
  /** @deprecated Calculate on client */
  total?: Maybe<Scalars['String']>;
};

export type LightCartResponse = MutationResponse & {
  __typename?: 'LightCartResponse';
  userErrors: Array<UserError>;
  success: Scalars['Boolean'];
  cart?: Maybe<LightCart>;
};

export type LightProduct = {
  __typename?: 'LightProduct';
  id: Scalars['ID'];
  vat?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  price: Scalars['Int'];
  originalPrice: Scalars['Int'];
  /** @deprecated Use unitPrice field instead */
  grossPrice?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  nameVn?: Maybe<Scalars['String']>;
  uom?: Maybe<Scalars['String']>;
  uomLocal?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
};

export type LimitedTimeOffer = {
  __typename?: 'LimitedTimeOffer';
  price: UnitPrice;
  period: LimitedTimeOfferPeriod;
};

export type LimitedTimeOfferInput = {
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
};

export type LimitedTimeOfferPeriod = {
  __typename?: 'LimitedTimeOfferPeriod';
  start: Scalars['String'];
  end: Scalars['String'];
};

export type ListHolidaysResponse = {
  __typename?: 'ListHolidaysResponse';
  data?: Maybe<Array<Maybe<Holiday>>>;
};

export type ListSalesResponse = {
  __typename?: 'ListSalesResponse';
  data?: Maybe<Array<Maybe<SalesPic>>>;
};

export type LocalizedContent = {
  __typename?: 'LocalizedContent';
  en: Scalars['String'];
  vi: Scalars['String'];
};

export type Location = {
  __typename?: 'Location';
  city: Scalars['String'];
  district: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  ward?: Maybe<Scalars['String']>;
  noteForAddress?: Maybe<Scalars['String']>;
};

export type LocationInput = {
  city: Scalars['String'];
  district: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  ward?: Maybe<Scalars['String']>;
  noteForAddress?: Maybe<Scalars['String']>;
};

export type LoginResponse = MutationResponse & {
  __typename?: 'LoginResponse';
  success: Scalars['Boolean'];
  userErrors: Array<UserError>;
  token?: Maybe<Scalars['String']>;
  rememberMeToken?: Maybe<Scalars['String']>;
};

export type MeDeleteInput = {
  userId: Scalars['ID'];
};

export type MeUpdateInput = {
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  lang?: Maybe<Language>;
};

export type MeUpdatePasswordInput = {
  currentPassword: Scalars['String'];
  password: Scalars['String'];
};

export type MediaType = {
  __typename?: 'MediaType';
  type?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
};

export enum MessageType {
  Info = 'INFO',
  Failed = 'FAILED',
  Success = 'SUCCESS',
  Late = 'LATE'
}

export type Mutation = {
  __typename?: 'Mutation';
  invitationAccept?: Maybe<ApiResponse>;
  invitationAcceptAsNewBuyer?: Maybe<ApiResponse>;
  invitationSetStores?: Maybe<ApiResponse>;
  userRemove?: Maybe<ApiResponse>;
  userGroupCreate?: Maybe<UserGroupCreateResponse>;
  userGroupUpdate?: Maybe<UserGroupUpdateResponse>;
  userGroupDisable?: Maybe<UserGroupDisableResponse>;
  userGroupDelete?: Maybe<UserGroupDeleteResponse>;
  groupMemberAdd?: Maybe<GroupMemberAddResponse>;
  groupMemberRemove?: Maybe<GroupMemberRemoveResponse>;
  productSetNote?: Maybe<ProductNoteResponse>;
  featureFlagToggle?: Maybe<FeatureFlagToggleResponse>;
  orderCreate?: Maybe<OrderCreateResponse>;
  orderCancel?: Maybe<OrderMutationResponse>;
  orderReceive?: Maybe<OrderMutationResponse>;
  orderUpdateDeliveredItem?: Maybe<OrderMutationResponse>;
  orderUpdateDeliveredItems?: Maybe<OrderMutationResponse>;
  orderUpdateUnitPrice?: Maybe<OrderMutationResponse>;
  orderUpdateItem?: Maybe<OrderMutationResponse>;
  orderPendingApprovalUpdateItem?: Maybe<OrderMutationResponse>;
  orderPendingApprovalDeleteItem?: Maybe<OrderMutationResponse>;
  orderReorder?: Maybe<CartResponse>;
  orderApprove?: Maybe<OrderMutationResponse>;
  orderDisapprove?: Maybe<OrderMutationResponse>;
  cartClearSupplier?: Maybe<CartResponse>;
  cartPutItem?: Maybe<CartResponse>;
  cartDeleteItem?: Maybe<CartResponse>;
  cartClearSupplierLight?: Maybe<LightCartResponse>;
  cartPutItemLight?: Maybe<LightCartResponse>;
  cartDeleteItemLight?: Maybe<LightCartResponse>;
  cartPutDeliveryTime?: Maybe<CartResponse>;
  cartPutDeliveryNote?: Maybe<CartResponse>;
  cartDeleteDeliveryNote?: Maybe<CartResponse>;
  cartPutDeliveryDate?: Maybe<CartResponse>;
  cartCreateOrderDraft?: Maybe<CartCreateOrderDraftResponse>;
  signImageUpload?: Maybe<SignUploadResponse>;
  meLogin?: Maybe<LoginResponse>;
  meRelogin?: Maybe<LoginResponse>;
  meUpdateLang?: Maybe<ApiResponse>;
  meUpdatePassword?: Maybe<ApiResponse>;
  meForgetPassword?: Maybe<ApiResponse>;
  meResetPassword?: Maybe<ApiResponse>;
  meUpdate?: Maybe<ApiResponse>;
  meDelete?: Maybe<ApiResponse>;
  userUpdate?: Maybe<UserUpdateResponse>;
  buyerCreateAccount?: Maybe<LoginResponse>;
  buyerUpdate?: Maybe<ApiResponse>;
  companyUpdateRedInvoice?: Maybe<ApiResponse>;
  companyInviteUsers?: Maybe<ApiResponse>;
  customerContactRequest?: Maybe<ApiResponse>;
  favoriteAddItem?: Maybe<ApiResponse>;
  favoriteRemoveItem?: Maybe<ApiResponse>;
  putItemsToFavorite?: Maybe<ApiResponse>;
  deliveryNoteCreate?: Maybe<ApiResponse>;
  deliveryNoteUpdate?: Maybe<ApiResponse>;
  deliveryNoteDelete?: Maybe<ApiResponse>;
  storeCreate?: Maybe<ApiResponse>;
  storeUpdate?: Maybe<ApiResponse>;
  storeDelete?: Maybe<ApiResponse>;
  checkEmail?: Maybe<ApiResponse>;
  notificationRegister?: Maybe<NotificationRegisterResponse>;
  notificationUnregister?: Maybe<ApiResponse>;
  notificationMarkAsRead?: Maybe<ApiResponse>;
  notificationMarkAllAsRead?: Maybe<ApiResponse>;
  createNewProductRequest?: Maybe<ApiResponse>;
  checkStoreIsDuplicate?: Maybe<CheckStoreIsDuplicateResponse>;
};


export type MutationInvitationAcceptArgs = {
  buyerId: Scalars['Int'];
  invitationId: Scalars['ID'];
};


export type MutationInvitationAcceptAsNewBuyerArgs = {
  input: InvitationAsNewBuyerInput;
};


export type MutationInvitationSetStoresArgs = {
  input: InvitationStoresInput;
};


export type MutationUserRemoveArgs = {
  id: Scalars['ID'];
};


export type MutationUserGroupCreateArgs = {
  input: UserGroupCreateInput;
};


export type MutationUserGroupUpdateArgs = {
  input: UserGroupUpdateInput;
};


export type MutationUserGroupDisableArgs = {
  input: UserGroupDisableInput;
};


export type MutationUserGroupDeleteArgs = {
  input: UserGroupDeleteInput;
};


export type MutationGroupMemberAddArgs = {
  input: GroupMemberAddInput;
};


export type MutationGroupMemberRemoveArgs = {
  input: GroupMemberRemoveInput;
};


export type MutationProductSetNoteArgs = {
  input: ProductNoteInput;
};


export type MutationFeatureFlagToggleArgs = {
  input: FeatureFlagToggleInput;
};


export type MutationOrderCreateArgs = {
  draftToken: Scalars['String'];
  storeId: Scalars['String'];
  voucherIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  totalAfterAppliedVouchers?: Maybe<Scalars['Float']>;
};


export type MutationOrderCancelArgs = {
  id: Scalars['ID'];
};


export type MutationOrderReceiveArgs = {
  id: Scalars['ID'];
};


export type MutationOrderUpdateDeliveredItemArgs = {
  input: OrderUpdateDeliveredItemInput;
};


export type MutationOrderUpdateDeliveredItemsArgs = {
  input: OrderUpdateDeliveredItemInputs;
};


export type MutationOrderUpdateUnitPriceArgs = {
  input: OrderUpdateUnitPriceInput;
};


export type MutationOrderUpdateItemArgs = {
  input: OrderUpdateItemInput;
};


export type MutationOrderPendingApprovalUpdateItemArgs = {
  input: OrderUpdateItemInput;
};


export type MutationOrderPendingApprovalDeleteItemArgs = {
  input: OrderDeleteItemInput;
};


export type MutationOrderReorderArgs = {
  storeId: Scalars['String'];
  orderId: Scalars['String'];
};


export type MutationOrderApproveArgs = {
  orderId: Scalars['ID'];
};


export type MutationOrderDisapproveArgs = {
  orderId: Scalars['ID'];
};


export type MutationCartClearSupplierArgs = {
  supplierId: Scalars['Int'];
  storeId: Scalars['String'];
};


export type MutationCartPutItemArgs = {
  input: CartPutItemInput;
};


export type MutationCartDeleteItemArgs = {
  input: CartDeleteItemInput;
};


export type MutationCartClearSupplierLightArgs = {
  supplierId: Scalars['Int'];
  storeId: Scalars['String'];
};


export type MutationCartPutItemLightArgs = {
  input: CartPutItemInput;
};


export type MutationCartDeleteItemLightArgs = {
  input: CartDeleteItemInput;
};


export type MutationCartPutDeliveryTimeArgs = {
  input: DeliveryTimeInput;
};


export type MutationCartPutDeliveryNoteArgs = {
  input: DeliveryNoteInput;
};


export type MutationCartDeleteDeliveryNoteArgs = {
  input: DeliveryNoteInput;
};


export type MutationCartPutDeliveryDateArgs = {
  input: DeliveryDateInput;
};


export type MutationCartCreateOrderDraftArgs = {
  input?: Maybe<CartCreateOrderDraftInput>;
};


export type MutationSignImageUploadArgs = {
  resourceGroup: ResourceGroup;
  contentType: Scalars['String'];
  fileName: Scalars['String'];
};


export type MutationMeLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  rememberMe?: Maybe<Scalars['Boolean']>;
};


export type MutationMeReloginArgs = {
  token: Scalars['String'];
};


export type MutationMeUpdateLangArgs = {
  lang: Language;
};


export type MutationMeUpdatePasswordArgs = {
  input: MeUpdatePasswordInput;
};


export type MutationMeForgetPasswordArgs = {
  email: Scalars['String'];
};


export type MutationMeResetPasswordArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
  key: Scalars['String'];
};


export type MutationMeUpdateArgs = {
  input?: Maybe<MeUpdateInput>;
};


export type MutationMeDeleteArgs = {
  userId: Scalars['ID'];
};


export type MutationUserUpdateArgs = {
  input?: Maybe<UserUpdateInput>;
};


export type MutationBuyerCreateAccountArgs = {
  input?: Maybe<BuyerCreateAccountInput>;
};


export type MutationBuyerUpdateArgs = {
  input?: Maybe<BuyerUpdateInput>;
};


export type MutationCompanyUpdateRedInvoiceArgs = {
  input?: Maybe<CompanyUpdateRedInvoiceInput>;
};


export type MutationCompanyInviteUsersArgs = {
  email: Array<Scalars['String']>;
};


export type MutationCustomerContactRequestArgs = {
  input: CustomerContactRequest;
};


export type MutationFavoriteAddItemArgs = {
  id: Scalars['ID'];
};


export type MutationFavoriteRemoveItemArgs = {
  id: Scalars['ID'];
};


export type MutationPutItemsToFavoriteArgs = {
  itemUuids: Array<Maybe<Scalars['String']>>;
};


export type MutationDeliveryNoteCreateArgs = {
  text: Scalars['String'];
};


export type MutationDeliveryNoteUpdateArgs = {
  id: Scalars['ID'];
  text: Scalars['String'];
};


export type MutationDeliveryNoteDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationStoreCreateArgs = {
  input: CreateStoreInput;
};


export type MutationStoreUpdateArgs = {
  input: UpdateStoreInput;
};


export type MutationStoreDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationCheckEmailArgs = {
  email?: Maybe<Scalars['String']>;
};


export type MutationNotificationRegisterArgs = {
  input: NotificationRegisterInput;
};


export type MutationNotificationUnregisterArgs = {
  input: NotificationUnregisterInput;
};


export type MutationNotificationMarkAsReadArgs = {
  input: NotificationMarkAsReadInput;
};


export type MutationNotificationMarkAllAsReadArgs = {
  input: NotificationMarkAllAsReadInput;
};


export type MutationCreateNewProductRequestArgs = {
  input: CreateNewProductRequestInput;
};


export type MutationCheckStoreIsDuplicateArgs = {
  input: CheckStoreIsDuplicateInput;
};

export type MutationResponse = {
  success: Scalars['Boolean'];
  userErrors: Array<UserError>;
};

export type NearbySearchResponse = {
  __typename?: 'NearbySearchResponse';
  results?: Maybe<Array<Maybe<PlaceResult>>>;
  status?: Maybe<Scalars['String']>;
};

export type Node = {
  id: Scalars['ID'];
};

export type NotificationMarkAllAsReadInput = {
  readStatus: ReadStatus;
};

export type NotificationMarkAsReadInput = {
  messageId: Scalars['String'];
  readStatus: ReadStatus;
};

export type NotificationMessage = {
  __typename?: 'NotificationMessage';
  id: Scalars['ID'];
  readStatus: ReadStatus;
  messageType: MessageType;
  title: Scalars['String'];
  content: Scalars['String'];
  data?: Maybe<NotificationMessageData>;
  /** @deprecated Use data instead */
  messageData?: Maybe<NotificationMessageDataUnion>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NotificationMessageData = {
  __typename?: 'NotificationMessageData';
  type?: Maybe<NotificationMessageOrderStatusType>;
  orderId?: Maybe<Scalars['String']>;
  navType?: Maybe<NotificationMessageNavigationType>;
  navValue?: Maybe<Scalars['String']>;
};

export type NotificationMessageDataUnion = PushNotificationConfigData | PushNotificationOrderData;

export enum NotificationMessageNavigationType {
  Product = 'PRODUCT',
  Tag = 'TAG',
  Category = 'CATEGORY',
  Url = 'URL'
}

export enum NotificationMessageOrderStatusType {
  OrderStatusUpdate = 'ORDER_STATUS_UPDATE',
  OrderMissingItem = 'ORDER_MISSING_ITEM'
}

export type NotificationMessagesResponse = {
  __typename?: 'NotificationMessagesResponse';
  totalResults: Scalars['Int'];
  totalPage: Scalars['Int'];
  data?: Maybe<Array<NotificationMessage>>;
};

export type NotificationRegisterInput = {
  token: Scalars['String'];
  tokenType: TokenType;
  platform: Platform;
  deviceId?: Maybe<Scalars['String']>;
};

export type NotificationRegisterResponse = {
  __typename?: 'NotificationRegisterResponse';
  id: Scalars['ID'];
};

export type NotificationUnregisterInput = {
  id: Scalars['ID'];
};

export type OpeningHours = {
  __typename?: 'OpeningHours';
  open_now?: Maybe<Scalars['Boolean']>;
};

export type Order = {
  __typename?: 'Order';
  id: Scalars['ID'];
  total: Scalars['Float'];
  totalItemPrice?: Maybe<Scalars['Float']>;
  /** @deprecated Calculate from items */
  grossTotal: Scalars['Float'];
  items: Array<OrderItem>;
  orderDate: Scalars['String'];
  deliveryTime: DeliveryTime;
  status: OrderStatus;
  statusText: Scalars['String'];
  paymentMethod: PaymentMethod;
  note?: Maybe<Scalars['String']>;
  declineReason?: Maybe<Scalars['String']>;
  creator: User;
  buyer: Buyer;
  store: Store;
  supplier: Supplier;
  approvedAt?: Maybe<Scalars['String']>;
  approvedBy?: Maybe<User>;
  /** @deprecated Use orderType instead */
  redelivery?: Maybe<Scalars['Boolean']>;
  orderType: OrderType;
  zeroOrderInfo?: Maybe<ZeroOrderInfo>;
  deliveryFee?: Maybe<DeliveryFee>;
};

export type OrderBy = {
  field?: Maybe<Scalars['String']>;
  isDesc?: Maybe<Scalars['Boolean']>;
};

export type OrderCountSummary = {
  __typename?: 'OrderCountSummary';
  orderedWithinPeriod: Scalars['Int'];
  deliverWithinPeriod: Scalars['Int'];
  pending: Scalars['Int'];
  pendingApproval: Scalars['Int'];
};

export type OrderCreateResponse = MutationResponse & {
  __typename?: 'OrderCreateResponse';
  userErrors: Array<UserError>;
  success: Scalars['Boolean'];
  orders: Array<Order>;
  cart?: Maybe<Cart>;
};

export type OrderDeleteItemInput = {
  orderId: Scalars['ID'];
  itemIds: Array<Scalars['String']>;
};

export type OrderDisabledResponse = {
  __typename?: 'OrderDisabledResponse';
  orderDisabled: Scalars['Boolean'];
  message: Scalars['String'];
};

export type OrderDraft = {
  __typename?: 'OrderDraft';
  total: Scalars['Float'];
  items: Array<OrderItem>;
  deliveryNotes: Array<Scalars['String']>;
  supplier: Supplier;
  deliveryDatetime: DeliveryDatetime;
  paymentMethod?: Maybe<PaymentMethod>;
};

export type OrderDraftList = {
  __typename?: 'OrderDraftList';
  redInvoice?: Maybe<RedInvoiceInfo>;
  buyer: Buyer;
  user: User;
  store: Store;
  total: Scalars['Float'];
  orderDrafts: Array<OrderDraft>;
};

export type OrderFilterInput = {
  supplierId?: Maybe<Scalars['Int']>;
  storeId?: Maybe<Scalars['String']>;
  creatorId?: Maybe<Scalars['String']>;
  statuses?: Maybe<Array<OrderStatus>>;
  orderDateRange?: Maybe<DatetimeRange>;
  deliveryDateRange?: Maybe<DatetimeRange>;
};

export type OrderItem = {
  __typename?: 'OrderItem';
  total?: Maybe<Scalars['Float']>;
  product: Product;
  orderedQuantity: Scalars['String'];
  deliveryQuantity: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  buyerNote?: Maybe<Scalars['String']>;
  deliveredNote?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
  /** @deprecated Use orderedQuantity or deliveryQuantity */
  quantity?: Maybe<Scalars['Float']>;
  /** @deprecated Use orderedUnitPrice field */
  orderedGrossPrice?: Maybe<Scalars['Float']>;
  /** @deprecated Calculate in the client from unitPrice field */
  grossTotal?: Maybe<Scalars['Float']>;
  /** @deprecated Should not be used any more */
  previousQuantity?: Maybe<Scalars['Float']>;
  /** @deprecated Use unitPrice field */
  grossPrice?: Maybe<Scalars['Float']>;
  /** @deprecated Should not be used any more */
  deliveredQuantity?: Maybe<Scalars['Float']>;
  /** @deprecated Should not be used any more */
  orderedPrice?: Maybe<Scalars['String']>;
};

export type OrderListResponse = {
  __typename?: 'OrderListResponse';
  totalResults: Scalars['Int'];
  totalPage: Scalars['Int'];
  data?: Maybe<Array<Maybe<Order>>>;
};

export type OrderMutationResponse = MutationResponse & {
  __typename?: 'OrderMutationResponse';
  userErrors: Array<UserError>;
  success: Scalars['Boolean'];
  order?: Maybe<Order>;
};

export enum OrderStatus {
  All = 'ALL',
  ApprovalDisapproved = 'APPROVAL_DISAPPROVED',
  PendingAcceptance = 'PENDING_ACCEPTANCE',
  PendingApproval = 'PENDING_APPROVAL',
  Accepted = 'ACCEPTED',
  Canceled = 'CANCELED',
  Declined = 'DECLINED',
  Sent = 'SENT',
  InProgress = 'IN_PROGRESS',
  PendingDelivery = 'PENDING_DELIVERY',
  Delivering = 'DELIVERING',
  Delivered = 'DELIVERED'
}

export type OrderTotalBucket = {
  __typename?: 'OrderTotalBucket';
  startTime?: Maybe<Scalars['String']>;
  totalAmount?: Maybe<Scalars['Float']>;
};

export enum OrderType {
  PaidOrder = 'PAID_ORDER',
  Gift = 'GIFT',
  Sample = 'SAMPLE',
  Redelivery = 'REDELIVERY',
  Employee = 'EMPLOYEE'
}

export type OrderUpdateDeliveredItemInput = {
  orderId: Scalars['ID'];
  itemId: Scalars['ID'];
  deliveredQuantity: Scalars['Float'];
  deliveredNote?: Maybe<Scalars['String']>;
};

export type OrderUpdateDeliveredItemInputs = {
  orderId: Scalars['ID'];
  items: Array<DeliveredItemInput>;
};

export type OrderUpdateItemInput = {
  orderId: Scalars['ID'];
  items: Array<UpdateItemInput>;
};

export type OrderUpdateUnitPriceInput = {
  orderId: Scalars['ID'];
  items: Array<UnitPriceInput>;
};

export type Origin = {
  __typename?: 'Origin';
  name?: Maybe<Scalars['String']>;
  nameVn?: Maybe<Scalars['String']>;
};

export type OriginsResponse = {
  __typename?: 'OriginsResponse';
  data?: Maybe<Array<Maybe<Origin>>>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  nextCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  previousCursor?: Maybe<Scalars['String']>;
};

export type Pagination = {
  page: Scalars['Int'];
  size: Scalars['Int'];
};

export enum PaymentMethod {
  Cod = 'COD',
  Debt_15Day = 'DEBT_15_DAY',
  Debt_1Month = 'DEBT_1_MONTH',
  BankTransfer = 'BANK_TRANSFER',
  Deposit = 'DEPOSIT',
  Debt_7Day = 'DEBT_7_DAY'
}

export type Permission = {
  __typename?: 'Permission';
  action: Scalars['String'];
  scope?: Maybe<PermissionScope>;
};

export type PermissionInput = {
  action: Scalars['String'];
  scope?: Maybe<PermissionScopeInput>;
};

export type PermissionScope = {
  __typename?: 'PermissionScope';
  type: Scalars['String'];
  id: Scalars['String'];
};

export type PermissionScopeInput = {
  type: Scalars['String'];
  id: Scalars['String'];
};

export type Photo = {
  __typename?: 'Photo';
  photo_reference?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  html_attributions?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PlacePrediction = {
  __typename?: 'PlacePrediction';
  description?: Maybe<Scalars['String']>;
  place_id?: Maybe<Scalars['String']>;
  structured_formatting?: Maybe<StructuredFormatting>;
  terms?: Maybe<Array<Maybe<Term>>>;
  types?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PlaceResult = {
  __typename?: 'PlaceResult';
  name?: Maybe<Scalars['String']>;
  place_id?: Maybe<Scalars['String']>;
  vicinity?: Maybe<Scalars['String']>;
  geometry?: Maybe<Geometry>;
  types?: Maybe<Array<Maybe<Scalars['String']>>>;
  plus_code?: Maybe<PlusCode>;
  rating?: Maybe<Scalars['Float']>;
  user_ratings_total?: Maybe<Scalars['Int']>;
  business_status?: Maybe<Scalars['String']>;
  photos?: Maybe<Array<Maybe<Photo>>>;
  opening_hours?: Maybe<OpeningHours>;
};

export enum Platform {
  Android = 'ANDROID',
  Ios = 'IOS',
  Web = 'WEB'
}

export type PlusCode = {
  __typename?: 'PlusCode';
  compound_code?: Maybe<Scalars['String']>;
  global_code?: Maybe<Scalars['String']>;
};

export type PopupButtonActionUnion = PopupGoToScreen | ClosePopupModal;

export type PopupGoToScreen = {
  __typename?: 'PopupGoToScreen';
  type: Scalars['String'];
  name: Scalars['String'];
  title: Scalars['String'];
  filter: WidgetFilter;
};

export type PriceRange = {
  min?: Maybe<Scalars['Int']>;
  max?: Maybe<Scalars['Int']>;
};

export type Product = {
  __typename?: 'Product';
  id: Scalars['ID'];
  supplier?: Maybe<Supplier>;
  name?: Maybe<Scalars['String']>;
  nameLocal?: Maybe<LocalizedContent>;
  supplierInternalCode?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  imageFilePath?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  preservationLocal?: Maybe<Scalars['String']>;
  preservation?: Maybe<Scalars['String']>;
  vat?: Maybe<Scalars['Int']>;
  uomLocal?: Maybe<Scalars['String']>;
  uom?: Maybe<Uom>;
  price: Scalars['Int'];
  originalPrice: Scalars['Int'];
  /** @deprecated Use uitPrice field */
  grossPrice?: Maybe<Scalars['Int']>;
  offMarket?: Maybe<Scalars['Boolean']>;
  inStock?: Maybe<Scalars['Boolean']>;
  private?: Maybe<Scalars['Boolean']>;
  category?: Maybe<Category>;
  disabled?: Maybe<Scalars['Boolean']>;
  isFavorite?: Maybe<Scalars['Boolean']>;
  tags: Array<Scalars['String']>;
  limitedTimeOffer?: Maybe<LimitedTimeOffer>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  allowAddToCart?: Maybe<Scalars['Boolean']>;
  allowPurchase?: Maybe<Scalars['Boolean']>;
  brand?: Maybe<BrandInfoItem>;
  medias?: Maybe<Array<Maybe<MediaType>>>;
};

export type ProductBrand = {
  __typename?: 'ProductBrand';
  id: Scalars['ID'];
  slug?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description_vi?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  productCategories?: Maybe<Array<Maybe<ProductCategory>>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['Boolean']>;
  groupName?: Maybe<Scalars['String']>;
};

export type ProductBrandsFilter = {
  __typename?: 'ProductBrandsFilter';
  brands?: Maybe<Array<Maybe<ProductBrandsFilterItem>>>;
  categories?: Maybe<Array<Maybe<ProductBrandsFilterItem>>>;
};

export type ProductBrandsFilterItem = {
  __typename?: 'ProductBrandsFilterItem';
  key: Scalars['String'];
  productBrands?: Maybe<Array<ProductBrand>>;
};

export type ProductBrandsForHomeResponse = {
  __typename?: 'ProductBrandsForHomeResponse';
  data?: Maybe<ProductBrandsFilter>;
};

export type ProductBrandsResponse = {
  __typename?: 'ProductBrandsResponse';
  totalResults: Scalars['Int'];
  totalPage: Scalars['Int'];
  data?: Maybe<Array<ProductBrand>>;
};

export type ProductCategoriesResponse = {
  __typename?: 'ProductCategoriesResponse';
  data?: Maybe<Array<ProductCategory>>;
};

export type ProductCategory = {
  __typename?: 'ProductCategory';
  id: Scalars['Int'];
  slug: Scalars['String'];
  name: Scalars['String'];
  activated: Scalars['Boolean'];
  subCategories?: Maybe<Array<ProductCategory>>;
};

export type ProductFilter = {
  supplierNames?: Maybe<Array<Scalars['String']>>;
  categoryName?: Maybe<Scalars['String']>;
  categoryIds?: Maybe<Array<Scalars['Int']>>;
  query?: Maybe<Scalars['String']>;
  preservation?: Maybe<Scalars['String']>;
  isFavorite?: Maybe<Scalars['Boolean']>;
  isMightFavorite?: Maybe<Scalars['Boolean']>;
  origins?: Maybe<Array<Scalars['String']>>;
  tags?: Maybe<Array<Scalars['String']>>;
  brandId?: Maybe<Scalars['Int']>;
  limitedTimeOfferPeriod?: Maybe<LimitedTimeOfferInput>;
  withexclusive?: Maybe<Scalars['Boolean']>;
  regionCode?: Maybe<Scalars['String']>;
  isRecommendation?: Maybe<Scalars['Boolean']>;
  isOnboardingBuyer?: Maybe<Scalars['Boolean']>;
  storeUuid?: Maybe<Scalars['String']>;
};

export type ProductFilterForHome = {
  supplierNames?: Maybe<Array<Scalars['String']>>;
  tags?: Maybe<Array<Scalars['String']>>;
  brandIds?: Maybe<Array<Scalars['Int']>>;
  categoryIds?: Maybe<Array<Scalars['Int']>>;
  regionCode?: Maybe<Scalars['String']>;
};

export enum ProductFilterType {
  Tag = 'TAG',
  Product = 'PRODUCT',
  Brand = 'BRAND',
  Category = 'CATEGORY',
  Url = 'URL'
}

export type ProductNoteInput = {
  productId: Scalars['ID'];
  note: Scalars['String'];
};

export type ProductNoteResponse = MutationResponse & {
  __typename?: 'ProductNoteResponse';
  userErrors: Array<UserError>;
  success: Scalars['Boolean'];
  product?: Maybe<Product>;
};

export type ProductSearchForHomeResponse = {
  __typename?: 'ProductSearchForHomeResponse';
  data: ProductsFilter;
};

export type ProductSearchResponse = {
  __typename?: 'ProductSearchResponse';
  totalResults: Scalars['Int'];
  totalPage: Scalars['Int'];
  data?: Maybe<Array<Maybe<Product>>>;
};

export type ProductSort = {
  field?: Maybe<ProductSortField>;
  order?: Maybe<SortDirection>;
};

export enum ProductSortField {
  Name = 'name',
  Price = 'price',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Preservation = 'preservation',
  Uom = 'uom'
}

export type ProductsFilter = {
  __typename?: 'ProductsFilter';
  tags?: Maybe<Array<Maybe<ProductsFilterItem>>>;
  categories?: Maybe<Array<Maybe<ProductsFilterItem>>>;
  brands?: Maybe<Array<Maybe<ProductsFilterItem>>>;
};

export type ProductsFilterItem = {
  __typename?: 'ProductsFilterItem';
  key: Scalars['String'];
  products?: Maybe<Array<Product>>;
};

export type Province = {
  __typename?: 'Province';
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nameEn?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  fullNameEn?: Maybe<Scalars['String']>;
  codeName?: Maybe<Scalars['String']>;
  administrativeUnitId?: Maybe<Scalars['Int']>;
  administrativeRegionId?: Maybe<Scalars['Int']>;
  kmr_region?: Maybe<Scalars['String']>;
};

export type ProvinceSearchResponse = {
  __typename?: 'ProvinceSearchResponse';
  total: Scalars['Int'];
  data?: Maybe<Array<Maybe<Province>>>;
};

export type PushNotificationConfigData = {
  __typename?: 'PushNotificationConfigData';
  navigation: PushNotificationNavigation;
};

export type PushNotificationNavigation = {
  __typename?: 'PushNotificationNavigation';
  type: PushNotificationNavigationType;
  value: Scalars['String'];
};

export enum PushNotificationNavigationType {
  Product = 'PRODUCT',
  Tag = 'TAG',
  Category = 'CATEGORY',
  Url = 'URL'
}

export type PushNotificationOrderData = {
  __typename?: 'PushNotificationOrderData';
  type: PushNotificationOrderStatusType;
  orderId: Scalars['String'];
};

export enum PushNotificationOrderStatusType {
  OrderStatusUpdate = 'ORDER_STATUS_UPDATE',
  OrderMissingItem = 'ORDER_MISSING_ITEM'
}

export type Query = {
  __typename?: 'Query';
  userGroup: UserGroup;
  me?: Maybe<User>;
  user?: Maybe<User>;
  invitation?: Maybe<Invitation>;
  users: Array<User>;
  productSearch?: Maybe<ProductSearchResponse>;
  productSearchForHome?: Maybe<ProductSearchForHomeResponse>;
  products: Array<Product>;
  product?: Maybe<Product>;
  productBySupplierInternalCode?: Maybe<Product>;
  cart: Cart;
  cartGetOrderDeadline?: Maybe<Scalars['String']>;
  cartGetAvailableDeliveryTimeSlots?: Maybe<Array<Maybe<DeliveryTimeSlot>>>;
  cartGetDeliveryDateOffs?: Maybe<Array<Maybe<Scalars['String']>>>;
  cartGetNextDeliveryDate?: Maybe<Scalars['String']>;
  buyer?: Maybe<Buyer>;
  order?: Maybe<Order>;
  orders?: Maybe<OrderListResponse>;
  ordersExport?: Maybe<Csv>;
  storeOrdersSummary: StoreOrdersSummaryConnection;
  storeOrderItemsSummary: StoreOrderItemsSummaryConnection;
  supplier?: Maybe<Supplier>;
  suppliers?: Maybe<SupplierListResponse>;
  categories: Array<Category>;
  categoryDetail?: Maybe<CategoryDetail>;
  orderTotalSummary: Array<OrderTotalBucket>;
  orderCountSummary?: Maybe<OrderCountSummary>;
  orderDraft?: Maybe<OrderDraftList>;
  deliveryNotes: Array<DeliveryNote>;
  widgets: Array<WidgetUnion>;
  homePagePopup?: Maybe<HomePagePopupUnion>;
  orderDisabled: OrderDisabledResponse;
  addStoreDisabled: AddStoreDisableResponse;
  appVersionFromAppStore?: Maybe<AppVersionResponse>;
  notificationMessages?: Maybe<NotificationMessagesResponse>;
  recommendedForYou?: Maybe<RecommendedForYouResponse>;
  buyerWebVersion?: Maybe<BuyerWebVersionResponse>;
  brands?: Maybe<ProductBrandsResponse>;
  brandsForHome?: Maybe<ProductBrandsForHomeResponse>;
  brand?: Maybe<ProductBrand>;
  companies?: Maybe<Array<Scalars['String']>>;
  provinceSearch?: Maybe<ProvinceSearchResponse>;
  districtSearch?: Maybe<DistrictSearchResponse>;
  wardSearch?: Maybe<WardSearchResponse>;
  findFullAddress?: Maybe<FindFullAddressResponse>;
  getAllKamereoRegion?: Maybe<GetAllKamereoRegionsResponse>;
  getGroupCategories?: Maybe<Array<Maybe<CategoryByGroup>>>;
  getGroupRTEByCity?: Maybe<RteGroupByCity>;
  getRTELocationList?: Maybe<RteDistributionLocationResponse>;
  listSalesPic?: Maybe<ListSalesResponse>;
  allUoms?: Maybe<AllUomsResponse>;
  getVaAndQrInfo?: Maybe<GetVaAndQrInfoResponse>;
  getAllOrigins?: Maybe<OriginsResponse>;
  availableVouchersForStore?: Maybe<AvailableVouchersForStoreResponse>;
  calculateVoucherDiscount?: Maybe<CalculateVoucherDiscountResponse>;
  getIpRegion?: Maybe<GetIpRegionResponse>;
  getGeoValueByLatLng?: Maybe<GeoCodingResponse>;
  getGeoValueByPlaceId?: Maybe<GeoCodingResponse>;
  getGooglePlaceAutocomplete?: Maybe<GooglePlaceAutocompleteResponse>;
  getGoogleNearByPlaces?: Maybe<GoogleNearbySearchResponse>;
  getDeliveryFee?: Maybe<GetDeliveryFeeResponse>;
  listHolidays?: Maybe<ListHolidaysResponse>;
  getFrequentlyBuyTogether?: Maybe<GetFrequentlyBuyTogetherResponse>;
};


export type QueryUserGroupArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryUserArgs = {
  id?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
};


export type QueryInvitationArgs = {
  id: Scalars['ID'];
};


export type QueryProductSearchArgs = {
  sort: Array<ProductSort>;
  filter?: Maybe<ProductFilter>;
  pagination: Pagination;
};


export type QueryProductSearchForHomeArgs = {
  sort: Array<ProductSort>;
  filter?: Maybe<ProductFilterForHome>;
  pagination: Pagination;
};


export type QueryProductsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type QueryProductArgs = {
  id: Scalars['ID'];
  regionCode?: Maybe<Scalars['String']>;
};


export type QueryProductBySupplierInternalCodeArgs = {
  supplierInternalCode: Scalars['String'];
};


export type QueryCartArgs = {
  storeId: Scalars['String'];
};


export type QueryCartGetOrderDeadlineArgs = {
  supplierId: Scalars['Int'];
  storeId: Scalars['String'];
};


export type QueryCartGetAvailableDeliveryTimeSlotsArgs = {
  supplierId: Scalars['Int'];
  start: Scalars['String'];
  end: Scalars['String'];
};


export type QueryCartGetDeliveryDateOffsArgs = {
  supplierId: Scalars['Int'];
};


export type QueryCartGetNextDeliveryDateArgs = {
  supplierId: Scalars['Int'];
};


export type QueryOrderArgs = {
  id: Scalars['ID'];
};


export type QueryOrdersArgs = {
  filter?: Maybe<OrderFilterInput>;
  pagination: Pagination;
};


export type QueryOrdersExportArgs = {
  filter?: Maybe<OrderFilterInput>;
  pagination?: Maybe<Pagination>;
};


export type QueryStoreOrdersSummaryArgs = {
  cursor?: Maybe<Scalars['String']>;
  queryInput?: Maybe<StoreOrdersSummaryInput>;
};


export type QueryStoreOrderItemsSummaryArgs = {
  cursor?: Maybe<Scalars['String']>;
  queryInput?: Maybe<StoreOrderItemsSummaryInput>;
};


export type QuerySupplierArgs = {
  name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};


export type QuerySuppliersArgs = {
  filter?: Maybe<SupplierFilterInput>;
  pagination: Pagination;
};


export type QueryCategoriesArgs = {
  supplierName?: Maybe<Scalars['String']>;
  isFavorite?: Maybe<Scalars['Boolean']>;
};


export type QueryCategoryDetailArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryOrderTotalSummaryArgs = {
  orderDateRange?: Maybe<DatetimeRange>;
  supplierId?: Maybe<Scalars['Int']>;
  orderStatuses?: Maybe<Array<OrderStatus>>;
  storeId: Scalars['String'];
};


export type QueryOrderCountSummaryArgs = {
  dateRange: DatetimeRange;
  storeId: Scalars['String'];
};


export type QueryOrderDraftArgs = {
  token: Scalars['String'];
};


export type QueryWidgetsArgs = {
  regions?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryAppVersionFromAppStoreArgs = {
  platform: Platform;
  currentVersion: Scalars['String'];
};


export type QueryNotificationMessagesArgs = {
  pagination: Pagination;
};


export type QueryRecommendedForYouArgs = {
  pagination: Pagination;
};


export type QueryBrandsArgs = {
  name?: Maybe<Scalars['String']>;
  brandIds?: Maybe<Array<Scalars['Int']>>;
  slug?: Maybe<Scalars['String']>;
  categoryName?: Maybe<Scalars['String']>;
  categoryIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  groupName?: Maybe<Scalars['String']>;
  pagination: Pagination;
  status?: Maybe<Scalars['Boolean']>;
};


export type QueryBrandsForHomeArgs = {
  brandIds?: Maybe<Array<Scalars['Int']>>;
  categoryIds?: Maybe<Array<Scalars['Int']>>;
  pagination: Pagination;
  status?: Maybe<Scalars['Boolean']>;
};


export type QueryBrandArgs = {
  brandId?: Maybe<Scalars['Int']>;
};


export type QueryProvinceSearchArgs = {
  regions?: Maybe<Array<Maybe<Scalars['String']>>>;
  provinceCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  pagination: Pagination;
  orderBy?: Maybe<Array<Maybe<OrderBy>>>;
};


export type QueryDistrictSearchArgs = {
  provinceCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  districtCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  pagination: Pagination;
  orderBy?: Maybe<Array<Maybe<OrderBy>>>;
};


export type QueryWardSearchArgs = {
  districtCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  wardCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  pagination: Pagination;
  orderBy?: Maybe<Array<Maybe<OrderBy>>>;
};


export type QueryFindFullAddressArgs = {
  wardCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  districtCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryGetGroupCategoriesArgs = {
  brandIdsList?: Maybe<Array<Maybe<Scalars['Int']>>>;
  tagsList?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryGetRteLocationListArgs = {
  city?: Maybe<Scalars['String']>;
};


export type QueryListSalesPicArgs = {
  salesIds: Array<Maybe<Scalars['String']>>;
};


export type QueryGetVaAndQrInfoArgs = {
  buyerId: Scalars['Int'];
  buyerName: Scalars['String'];
};


export type QueryAvailableVouchersForStoreArgs = {
  input?: Maybe<AvailableVouchersForStoreInput>;
};


export type QueryCalculateVoucherDiscountArgs = {
  input?: Maybe<CalculateVoucherDiscountInput>;
};


export type QueryGetGeoValueByLatLngArgs = {
  latlng: Scalars['String'];
};


export type QueryGetGeoValueByPlaceIdArgs = {
  placeId: Scalars['String'];
};


export type QueryGetGooglePlaceAutocompleteArgs = {
  input: Scalars['String'];
};


export type QueryGetGoogleNearByPlacesArgs = {
  latlng: Scalars['String'];
};


export type QueryGetDeliveryFeeArgs = {
  input?: Maybe<GetDeliveryFeeInput>;
};


export type QueryGetFrequentlyBuyTogetherArgs = {
  supplierInternalCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  size?: Maybe<Scalars['Int']>;
  requiredItemsBaseCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  regionCode: Scalars['String'];
};

export type RteDistributionLocation = {
  __typename?: 'RTEDistributionLocation';
  id: Scalars['String'];
  distributor?: Maybe<Scalars['String']>;
  name_vi?: Maybe<Scalars['String']>;
  name_en?: Maybe<Scalars['String']>;
  addr_vi?: Maybe<Scalars['String']>;
  addr_en?: Maybe<Scalars['String']>;
  district_vi?: Maybe<Scalars['String']>;
  district_en?: Maybe<Scalars['String']>;
  city_vi?: Maybe<Scalars['String']>;
  city_en?: Maybe<Scalars['String']>;
  geo_lat?: Maybe<Scalars['Float']>;
  geo_long?: Maybe<Scalars['Float']>;
  open_time?: Maybe<Scalars['String']>;
  close_time?: Maybe<Scalars['String']>;
  tel?: Maybe<Scalars['String']>;
  plus_code?: Maybe<Scalars['String']>;
};

export type RteDistributionLocationResponse = {
  __typename?: 'RTEDistributionLocationResponse';
  total: Scalars['Int'];
  data?: Maybe<Array<RteDistributionLocation>>;
};

export type RteGroupByCity = {
  __typename?: 'RTEGroupByCity';
  total: Scalars['Int'];
  city?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum ReadStatus {
  NotRead = 'NOT_READ',
  IsRead = 'IS_READ'
}

export type RecommendationItem = {
  __typename?: 'RecommendationItem';
  key: Scalars['String'];
  title: Scalars['String'];
};

export type RecommendationWidget = {
  __typename?: 'RecommendationWidget';
  type: Scalars['String'];
  widgetId: Scalars['String'];
  item: RecommendationItem;
};

export type RecommendedForYouResponse = {
  __typename?: 'RecommendedForYouResponse';
  totalResults: Scalars['Int'];
  totalPage: Scalars['Int'];
  data?: Maybe<Array<Product>>;
  recommendationId: Scalars['String'];
};

export type RedInvoiceInfo = {
  __typename?: 'RedInvoiceInfo';
  registeredName?: Maybe<Scalars['String']>;
  registeredAddress?: Maybe<Scalars['String']>;
  redInvoiceNumber?: Maybe<Scalars['String']>;
};

export type RegionItem = {
  __typename?: 'RegionItem';
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nameVn?: Maybe<Scalars['String']>;
};

export enum RepresentativePersonRoles {
  Owner = 'OWNER',
  Manager = 'MANAGER',
  Purchaser = 'PURCHASER',
  Other = 'OTHER'
}

export enum ResourceGroup {
  Logo = 'LOGO',
  Avatar = 'AVATAR',
  ProductImage = 'PRODUCT_IMAGE'
}

export type SalesPic = {
  __typename?: 'SalesPic';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type ScreenUnion = CategoryScreen;

export type ShowPopup = {
  __typename?: 'ShowPopup';
  key: Scalars['String'];
  type: Scalars['String'];
  imageUrl: Scalars['String'];
  popupImageUrl?: Maybe<Scalars['String']>;
  popupContent: Scalars['String'];
  title: Scalars['String'];
  buttonText: Scalars['String'];
  buttonAction: PopupButtonActionUnion;
};

export type ShowPopupBannerItem = {
  __typename?: 'ShowPopupBannerItem';
  type: Scalars['String'];
  imageUrl: Scalars['String'];
  popupImageUrl?: Maybe<Scalars['String']>;
  popupContent: Scalars['String'];
  title: Scalars['String'];
  buttonText: Scalars['String'];
  buttonAction: PopupButtonActionUnion;
};

export type ShowWebViewBannerItem = {
  __typename?: 'ShowWebViewBannerItem';
  type: Scalars['String'];
  title: Scalars['String'];
  url: Scalars['String'];
  key: Scalars['String'];
  imageUrl: Scalars['String'];
};

export type ShowWebViewPopup = {
  __typename?: 'ShowWebViewPopup';
  key: Scalars['String'];
  type: Scalars['String'];
  title: Scalars['String'];
  url: Scalars['String'];
  imageUrl: Scalars['String'];
};

export type ShowcaseDateRange = {
  __typename?: 'ShowcaseDateRange';
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
};

export type ShowcaseItem = {
  __typename?: 'ShowcaseItem';
  id?: Maybe<Scalars['Int']>;
  key: Scalars['String'];
  title: Scalars['String'];
  backgroundImageId?: Maybe<Scalars['String']>;
  sort: Array<WidgetSort>;
  filter: WidgetFilter;
};

export type ShowcaseWidget = {
  __typename?: 'ShowcaseWidget';
  type: Scalars['String'];
  widgetId: Scalars['String'];
  item: ShowcaseItem;
};

export type SignUploadResponse = MutationResponse & {
  __typename?: 'SignUploadResponse';
  userErrors: Array<UserError>;
  success: Scalars['Boolean'];
  uploadUrl?: Maybe<Scalars['String']>;
  fullUrl?: Maybe<Scalars['String']>;
};

export type SingleSupplierCart = {
  __typename?: 'SingleSupplierCart';
  /** @deprecated Field no longer supported */
  moq: Scalars['Int'];
  paymentMethod?: Maybe<PaymentMethod>;
  deliveryNotes: Array<Scalars['String']>;
  supplier?: Maybe<Supplier>;
  deliveryDatetime?: Maybe<DeliveryDatetime>;
  deliveryTimeSlots?: Maybe<Array<Maybe<DeliveryDatetime>>>;
  orderDeadline?: Maybe<Scalars['String']>;
  earliestDate?: Maybe<Scalars['String']>;
  /** @deprecated Calculate on client */
  total?: Maybe<Scalars['Float']>;
  items: Array<CartItem>;
};

export type SingleSupplierLightCart = {
  __typename?: 'SingleSupplierLightCart';
  items: Array<LightCartItem>;
  supplier?: Maybe<Supplier>;
  total?: Maybe<Scalars['String']>;
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Store = {
  __typename?: 'Store';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  lastOrderedAt?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  location?: Maybe<Location>;
  disabled?: Maybe<Scalars['Boolean']>;
  status?: Maybe<StoreStatus>;
  provinceId?: Maybe<Scalars['String']>;
  districtId?: Maybe<Scalars['String']>;
  wardId?: Maybe<Scalars['String']>;
  geoLat?: Maybe<Scalars['Float']>;
  geoLon?: Maybe<Scalars['Float']>;
  geoPlusCode?: Maybe<Scalars['String']>;
  internalId?: Maybe<Scalars['Int']>;
  isForceUpdate?: Maybe<Scalars['Boolean']>;
  addressNote?: Maybe<Scalars['String']>;
};

export type StoreOrderItemSummary = {
  __typename?: 'StoreOrderItemSummary';
  supplier: Supplier;
  product: Product;
  quantity: Scalars['Float'];
  totalNetPrice: Scalars['Int'];
  totalGrossPrice: Scalars['Int'];
  lastMonthTotalGrossPrice: Scalars['Int'];
};

export type StoreOrderItemsSummary = {
  __typename?: 'StoreOrderItemsSummary';
  date: Scalars['Date'];
  summaries: Array<StoreOrderItemSummary>;
};

export type StoreOrderItemsSummaryConnection = {
  __typename?: 'StoreOrderItemsSummaryConnection';
  node: StoreOrderItemsSummaryEdge;
  pageInfo: PageInfo;
};

export type StoreOrderItemsSummaryEdge = {
  __typename?: 'StoreOrderItemsSummaryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  summary: StoreOrderItemsSummary;
};

export type StoreOrderItemsSummaryInput = {
  storeId: Scalars['ID'];
  yearMonth: Scalars['Date'];
};

export type StoreOrdersSummary = {
  __typename?: 'StoreOrdersSummary';
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  summaries: Array<SupplierOrdersSummary>;
};

export type StoreOrdersSummaryConnection = {
  __typename?: 'StoreOrdersSummaryConnection';
  node: StoreOrdersSummaryEdge;
  pageInfo: PageInfo;
  weekEdge: StoreOrdersSummaryEdge;
  monthEdge: StoreOrdersSummaryEdge;
  yearEdge: StoreOrdersSummaryEdge;
};

export type StoreOrdersSummaryEdge = {
  __typename?: 'StoreOrdersSummaryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  summary: StoreOrdersSummary;
};

export type StoreOrdersSummaryInput = {
  storeId: Scalars['ID'];
  dateRange: DateRangeInput;
  interval?: AggregationInterval;
};

export enum StoreStatus {
  Normal = 'NORMAL',
  Pending = 'PENDING',
  Blocked = 'BLOCKED',
  Rejected = 'REJECTED'
}

export type StructuredFormatting = {
  __typename?: 'StructuredFormatting';
  main_text?: Maybe<Scalars['String']>;
  secondary_text?: Maybe<Scalars['String']>;
};

export type Supplier = {
  __typename?: 'Supplier';
  id: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  location?: Maybe<Location>;
  moq?: Maybe<Scalars['Int']>;
  /** @deprecated this is wrong */
  leadTime?: Maybe<Scalars['Int']>;
  fulfillmentTime?: Maybe<FulfillmentTime>;
  name?: Maybe<Scalars['String']>;
  offMarket?: Maybe<Scalars['Boolean']>;
  paymentMethod?: Maybe<PaymentMethod>;
  private?: Maybe<Scalars['Boolean']>;
  productCount?: Maybe<Scalars['Int']>;
  redInvoiceInfo?: Maybe<RedInvoiceInfo>;
  telephone?: Maybe<Scalars['String']>;
  descriptionMeta?: Maybe<SupplierDescriptionMeta>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SupplierDescriptionMeta = {
  __typename?: 'SupplierDescriptionMeta';
  pic?: Maybe<SupplierPic>;
  purchase?: Maybe<SupplierPurchase>;
};

export type SupplierFilterInput = {
  categoryName?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  hasOrdersOnly?: Maybe<Scalars['Boolean']>;
  hasFavoriteItems?: Maybe<Scalars['Boolean']>;
};

export type SupplierListResponse = {
  __typename?: 'SupplierListResponse';
  totalResults: Scalars['Int'];
  totalPage: Scalars['Int'];
  data?: Maybe<Array<Maybe<Supplier>>>;
};

export type SupplierOrdersSummary = {
  __typename?: 'SupplierOrdersSummary';
  date: Scalars['Date'];
  supplier: Supplier;
  ordersCount: Scalars['Int'];
  totalPrice: Scalars['String'];
};

export type SupplierPic = {
  __typename?: 'SupplierPIC';
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type SupplierPurchase = {
  __typename?: 'SupplierPurchase';
  moq?: Maybe<Scalars['Int']>;
  orderDeadline?: Maybe<Scalars['String']>;
  deliveryTime?: Maybe<Scalars['String']>;
};

export type Term = {
  __typename?: 'Term';
  offset?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};

export type TimeSlot = {
  __typename?: 'TimeSlot';
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
};

export enum TokenType {
  Expo = 'EXPO',
  Fcm = 'FCM'
}

export enum TrackingAddToCartAddFromType {
  FavoriteProduct = 'FavoriteProduct',
  RelatedProduct = 'RelatedProduct',
  ProductDetail = 'ProductDetail',
  HomeTag = 'HomeTag',
  HomeCategory = 'HomeCategory',
  HomeRecommendation = 'HomeRecommendation',
  CollectionTag = 'CollectionTag',
  CollectionCategory = 'CollectionCategory',
  CollectionSubCategory = 'CollectionSubCategory',
  CollectionRecommendation = 'CollectionRecommendation',
  SearchResult = 'SearchResult',
  CartProduct = 'CartProduct',
  AllProduct = 'AllProduct'
}

export type TrackingAddToCartProperty = {
  productId: Scalars['String'];
  type: TrackingAddToCartType;
  category?: Maybe<Scalars['String']>;
  parentCategory?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  addFrom: TrackingAddToCartAddFromType;
  tag?: Maybe<Scalars['String']>;
};

export enum TrackingAddToCartType {
  Add = 'Add',
  Remove = 'Remove',
  Input = 'Input'
}

export type TrackingHomePagePopupProperty = {
  popupId: Scalars['String'];
  buyerId: Scalars['String'];
  storeId: Scalars['String'];
};

export type TrackingNotificationProperty = {
  notificationId: Scalars['String'];
  buyerId: Scalars['String'];
  storeId: Scalars['String'];
};

export type TrackingOrderCompletedProperty = {
  orderId: Scalars['String'];
};

export type TrackingProductImpressionProperty = {
  productId: Scalars['String'];
  type: TrackingProductImpressionType;
  price: Scalars['Float'];
  category?: Maybe<Scalars['String']>;
  parentCategory?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
};

export enum TrackingProductImpressionType {
  FavoriteProduct = 'FavoriteProduct',
  RelatedProduct = 'RelatedProduct',
  ProductDetail = 'ProductDetail',
  HomeTag = 'HomeTag',
  HomeCategory = 'HomeCategory',
  HomeRecommendation = 'HomeRecommendation',
  CollectionTag = 'CollectionTag',
  CollectionCategory = 'CollectionCategory',
  CollectionSubCategory = 'CollectionSubCategory',
  CollectionRecommendation = 'CollectionRecommendation',
  SearchResult = 'SearchResult',
  CartProduct = 'CartProduct',
  AllProduct = 'AllProduct'
}

export type TrackingPurchasedProductsProperty = {
  productIds: Array<Scalars['String']>;
};

export type TrackingSearchKeywordsProperty = {
  keyword: Scalars['String'];
  total: Scalars['Int'];
};

export type TrackingSignupRequestProperty = {
  utmMedium?: Maybe<Scalars['String']>;
  utmSource?: Maybe<Scalars['String']>;
  utmCampaign?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  customer: CustomerContact;
};

export enum TrackingType {
  OrderCompleted = 'OrderCompleted',
  PurchasedProducts = 'PurchasedProducts',
  ProductImpression = 'ProductImpression',
  AddToCart = 'AddToCart',
  ReadNotification = 'ReadNotification',
  HomePagePopupView = 'HomePagePopupView',
  HomePagePopupClick = 'HomePagePopupClick',
  NotificationCenterView = 'NotificationCenterView',
  NotificationDeviceClick = 'NotificationDeviceClick',
  SignupRequest = 'SignupRequest',
  SearchKeywords = 'SearchKeywords'
}

export enum Uom {
  Kilogram = 'KILOGRAM',
  Liter = 'LITER',
  Pack = 'PACK',
  Can = 'CAN',
  Bottle = 'BOTTLE',
  Pieces = 'PIECES',
  Case = 'CASE',
  Box = 'BOX',
  Keg = 'KEG',
  PieceCon = 'PIECE_CON',
  PieceCai = 'PIECE_CAI',
  PieceLat = 'PIECE_LAT',
  PieceTrai = 'PIECE_TRAI',
  Block = 'BLOCK',
  Tray = 'TRAY',
  Jar = 'JAR',
  Crate = 'CRATE',
  Pail = 'PAIL',
  Sheet = 'SHEET',
  Cup = 'CUP',
  PackLoc = 'PACK_LOC',
  Bag = 'BAG',
  Roll = 'ROLL',
  Sack = 'SACK',
  Set = 'SET',
  Carton = 'CARTON'
}

export type UnitPrice = {
  __typename?: 'UnitPrice';
  value: Scalars['Int'];
  vat: Scalars['Int'];
  vatIncluded: Scalars['Boolean'];
};

export type UnitPriceInput = {
  itemId: Scalars['ID'];
  newPrice?: Maybe<Scalars['Float']>;
  vat?: Maybe<Scalars['Int']>;
  vatIncluded?: Maybe<Scalars['Boolean']>;
};

export type UomElement = {
  __typename?: 'UomElement';
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  nameEn?: Maybe<Scalars['String']>;
  nameVn?: Maybe<Scalars['String']>;
};

export type UpdateItemInput = {
  itemId: Scalars['ID'];
  quantity: Scalars['Float'];
  note?: Maybe<Scalars['String']>;
};

export type UpdateStoreInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  telephone: Scalars['String'];
  location: LocationInput;
  disabled: Scalars['Boolean'];
  imageUrl: Scalars['String'];
  wardId: Scalars['String'];
  geoLat?: Maybe<Scalars['Float']>;
  geoLon?: Maybe<Scalars['Float']>;
  geoPlusCode?: Maybe<Scalars['String']>;
  googleAddress?: Maybe<Scalars['String']>;
};


export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  status?: Maybe<UserStatus>;
  telephone?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  lang?: Maybe<Language>;
  permissions: Array<Permission>;
  userGroups: Array<UserGroup>;
};

export type UserError = {
  __typename?: 'UserError';
  field?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

export type UserGroup = {
  __typename?: 'UserGroup';
  name: Scalars['String'];
  members?: Maybe<Array<Maybe<User>>>;
  groupId: Scalars['ID'];
  buyer: Buyer;
  isSystemCreated: Scalars['Boolean'];
  active: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  permissions: Array<Permission>;
};

export type UserGroupCreateInput = {
  name: Scalars['String'];
  memberIds: Array<Scalars['String']>;
  permissions: Array<PermissionInput>;
};

export type UserGroupCreateResponse = MutationResponse & {
  __typename?: 'UserGroupCreateResponse';
  userErrors: Array<UserError>;
  success: Scalars['Boolean'];
  userGroup?: Maybe<UserGroup>;
};

export type UserGroupDeleteInput = {
  groupId: Scalars['String'];
};

export type UserGroupDeleteResponse = MutationResponse & {
  __typename?: 'UserGroupDeleteResponse';
  userErrors: Array<UserError>;
  success: Scalars['Boolean'];
};

export type UserGroupDisableInput = {
  groupId: Scalars['String'];
};

export type UserGroupDisableResponse = MutationResponse & {
  __typename?: 'UserGroupDisableResponse';
  userErrors: Array<UserError>;
  success: Scalars['Boolean'];
  userGroup?: Maybe<UserGroup>;
};

export type UserGroupUpdateInput = {
  groupId: Scalars['String'];
  name: Scalars['String'];
  permissions: Array<PermissionInput>;
};

export type UserGroupUpdateResponse = MutationResponse & {
  __typename?: 'UserGroupUpdateResponse';
  userErrors: Array<UserError>;
  success: Scalars['Boolean'];
  userGroup?: Maybe<UserGroup>;
};

export enum UserStatus {
  Disabled = 'DISABLED',
  Active = 'ACTIVE',
  Deleted = 'DELETED'
}

export type UserUpdateInput = {
  id: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  status?: Maybe<UserStatus>;
  name?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<PermissionInput>>;
};

export type UserUpdateResponse = MutationResponse & {
  __typename?: 'UserUpdateResponse';
  userErrors: Array<UserError>;
  success: Scalars['Boolean'];
  user?: Maybe<User>;
};

export type Viewport = {
  __typename?: 'Viewport';
  northeast?: Maybe<GeoLocation>;
  southwest?: Maybe<GeoLocation>;
};

export type Voucher = {
  __typename?: 'Voucher';
  id?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  nameVn?: Maybe<Scalars['String']>;
  nameEn?: Maybe<Scalars['String']>;
  discountMinPrice?: Maybe<Scalars['Float']>;
  discountPercent?: Maybe<Scalars['Float']>;
  discountMaxValue?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  isDisabled?: Maybe<Scalars['Boolean']>;
};

export type Ward = {
  __typename?: 'Ward';
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nameEn?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  fullNameEn?: Maybe<Scalars['String']>;
  codeName?: Maybe<Scalars['String']>;
  administrativeUnitId?: Maybe<Scalars['Int']>;
  districtCode?: Maybe<Scalars['String']>;
};

export type WardSearchResponse = {
  __typename?: 'WardSearchResponse';
  total: Scalars['Int'];
  data?: Maybe<Array<Maybe<Ward>>>;
};

export type WidgetFilter = {
  __typename?: 'WidgetFilter';
  supplierNames?: Maybe<Array<Scalars['String']>>;
  categoryName?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  preservation?: Maybe<Scalars['String']>;
  /**
   * If this is enabled, `totalResults` and `totalPage` might not be accurate.
   * It's because some of the products can be deleted already.
   */
  isOrderedPreviously?: Maybe<Scalars['Boolean']>;
  /**
   * If this is enabled, `totalResults` and `totalPage` might not be accurate.
   * It's because some of the products can be deleted already.
   */
  isFavorite?: Maybe<Scalars['Boolean']>;
  origins?: Maybe<Array<Scalars['String']>>;
  tags?: Maybe<Array<Scalars['String']>>;
  limitedTimeOfferPeriod?: Maybe<ShowcaseDateRange>;
  productInfo?: Maybe<BannerProductFilterProductInfo>;
  brandInfo?: Maybe<BannerProductFilterBrandInfo>;
  categoryInfo?: Maybe<BannerProductFilterCategoryInfo>;
  urlInfo?: Maybe<BannerProductFilterUrlInfo>;
  filterType?: Maybe<Scalars['String']>;
};

export type WidgetSort = {
  __typename?: 'WidgetSort';
  field: ProductSortField;
  order: SortDirection;
};

export type WidgetUnion = CarouselBannerWidget | HorizontalScrollBannerWidget | ShowcaseWidget | RecommendationWidget;

export type ZeroOrderInfo = {
  __typename?: 'ZeroOrderInfo';
  reason?: Maybe<Scalars['String']>;
  receiver?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
};
