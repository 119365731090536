export const CATEGORIES_FLOATING_LIST_ID = [
  {
    id: 122,
    color: "#D3F1A7",
    url: ""
  },
  {
    id: 120,
    color: "#F8E6A0",
    url: ""
  },
  {
    id: 127,
    color: "#FEDEC8",
    url: ""
  },
  {
    id: 124,
    color: "#FDD0EC",
    url: ""
  },
  {
    id: 126,
    color: "#DFD8FD",
    url: ""
  },
  {
    id: 121,
    color: "#C6EDFB",
    url: ""
  },
  {
    id: 123,
    color: "#CCE0FF",
    url: ""
  },
  {
    id: 128,
    color: "#D4EDD5",
    url: ""
  },
  {
    id: 129,
    color: "#F8E6A0",
    url: ""
  },
  {
    id: 125,
    color: "#CCE0FF",
    url: ""
  },
  {
    id: 131,
    color: "#C6EDFB",
    url: ""
  },
  {
    id: 130,
    color: "#FEDEC8",
    url: ""
  }
]