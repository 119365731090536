export const getUomNameLocal = (uomCode?: string) => {
  if (typeof window === 'undefined') {
    return uomCode;
  }
  const storedUoms = window?.localStorage.getItem('uoms');
  const lang = window?.localStorage.getItem('lang');

  if (!storedUoms || !lang || !uomCode) {
    return uomCode;
  }

  const jsonUoms = JSON.parse(storedUoms);

  if (!jsonUoms || Object?.keys(jsonUoms)?.length <= 0 || !Object?.keys(jsonUoms).includes(uomCode)) {
    return uomCode;
  }

  return lang === 'vi' ? jsonUoms[uomCode]?.nameVn : jsonUoms[uomCode]?.nameEn;
};
