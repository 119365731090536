enum ActionTypes {
  SET_WIDGETS = 'app/HomePage/SET_WIDGETS',
  SET_HOMEPAGE_POPUP = 'app/HomePage/SET_HOMEPAGE_POPUP',

  HOME_PRODUCTS_REQUEST = 'app/HomePage/HOME_PRODUCTS_REQUEST',
  HOME_PRODUCTS_SUCCESS = 'app/HomePage/HOME_PRODUCTS_SUCCESS',
  HOME_PRODUCTS_FAILURE = 'app/HomePage/HOME_PRODUCTS_FAILURE',

  HOME_FAVORITE = 'app/HomePage/HOME_FAVORITE',
  HOME_FAVORITE_REQUEST = 'app/HomePage/HOME_FAVORITE_REQUEST',
  HOME_FAVORITE_SUCCESS = 'app/HomePage/HOME_FAVORITE_SUCCESS',
  HOME_FAVORITE_FAILURE = 'app/HomePage/HOME_FAVORITE_FAILURE',

  HOME_SET_PRODUCT_NOTE = 'app/MainLayout/HOME_SET_PRODUCT_NOTE',
  HOME_SET_PRODUCT_NOTE_REQUEST = 'app/MainLayout/HOME_SET_PRODUCT_NOTE_REQUEST',
  HOME_SET_PRODUCT_NOTE_SUCCESS = 'app/MainLayout/HOME_SET_PRODUCT_NOTE_SUCCESS',
  HOME_SET_PRODUCT_NOTE_FAILURE = 'app/MainLayout/HOME_SET_PRODUCT_NOTE_FAILURE',
  CLEAR_HOMEPAGE_POPUP = 'app/HomePage/CLEAR_HOMEPAGE_POPUP',
  HANDLE_ADD_ALL_TO_FAVORITE = 'app/HomePage/HANDLE_ADD_ALL_TO_FAVORITE',
}

export default ActionTypes;
