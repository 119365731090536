import MainLayout from 'layouts/MainLayout';
import React, { FC, lazy, useEffect, useMemo, useState } from 'react';
import { Redirect, Route, Router, Switch, withRouter } from 'react-router-dom';

import PrivateGateLayout from 'layouts/PrivateGateLayout';
import ProductListingLayout from 'layouts/ProductListingLayout';

import axios from 'axios';
import GateLayout from 'layouts/GateLayout';

import ViewportProvider from 'components/ViewportProvider';
import DocPage from 'containers/DocPage';
import history from 'utils/history';
import GlobalStyles from '../../global-styles';
import SelectRegionModal from './SelectRegionModal';

export const PageManager = withRouter((props: any) => {
  useEffect(() => {
    if (props.location.pathname !== '/thuong-hieu') {
      window.scrollTo(0, 0);
    }
    props.setRedirectPath(props.location.state?.redirectPath || null);
  }, [props.location.pathname, props.location.search, props.location.state]);
  return <>{props.children}</>;
});
const pathNotDisplayRegionModal = [
  '/not-found',
  '/signin',
  '/kamereo-internal-signup',
  '/contact-us',
  '/contact-success',
  '/forgot-password',
  '/reset-password',
  '/invitations/:slug',
  '/he-thong-phan-phoi',
];

const maintainanceUrl = 'https://maintenance.prod.kamereo.vn/';

export const App: FC = () => {
  const [redirectPath, setRedirectPath] = useState<string | null>('/signin');
  const isLogin = window.localStorage.getItem('token');
  const originalUrl = useMemo(
    () => `${window.location.pathname}${window.location.search}`,
    [window.location.pathname, window.location.search],
  );

  const fetchWithManualRedirect = async () => {
    try {
      const response = await axios.get(process.env.APP_URL || 'https://buyer.qa.kamereo.vn', {
        maxRedirects: 0,
        validateStatus: (status) => status >= 200 && status < 400,
        headers: { 'Cache-Control': 'no-cache' },
      });

      const isMaintenance = response?.request?.res?.responseUrl?.includes('maintenance.prod.kamereo.vn');

      if (isMaintenance) {
        window.location.href = `${maintainanceUrl}?redirectUrl=${encodeURIComponent(process.env.APP_URL + originalUrl)}`;
      }
    } catch (error) {
      throw new Error('Web/App not stable');
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      fetchWithManualRedirect();
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Router history={history}>
      <PageManager setRedirectPath={setRedirectPath}>
        <ViewportProvider>
          <Switch>
            <ProductListingLayout path="/" exact component={lazy(() => import('containers/HomePage'))} />
            <MainLayout
              path="/thuong-hieu-kamereo"
              component={lazy(() => import('containers/LandingPage/KamereoBrandPage'))}
              isAuth={false}
            />
            <MainLayout
              path="/nguyen-lieu-nhat-ban-gyomu-japan"
              component={lazy(() => import('containers/LandingPage/GyomuSuper'))}
              isAuth={false}
            />
            <MainLayout
              path="/thuong-hieu/:slug"
              component={lazy(() => import('containers/BrandDetailPage'))}
              isAuth={false}
            />
            <ProductListingLayout path="/market" component={lazy(() => import('containers/MarketPage'))} />
            <MainLayout
              path="/he-thong-phan-phoi"
              component={lazy(() => import('containers/LandingPage/DistributionLocationPage'))}
              isAuth={false}
            />
            <MainLayout
              path="/overview"
              component={lazy(() => import('containers/DashboardPage'))}
              redirect="/market"
            />
            <MainLayout path="/thuong-hieu" component={lazy(() => import('containers/AllBrandPage'))} isAuth={false} />
            <MainLayout
              path="/products/:slug"
              component={lazy(() => import('containers/ProductDetailsPage'))}
              isAuth={false}
            />
            <MainLayout
              path="/orders/:slug"
              component={lazy(() => import('containers/OrderDetailsPage'))}
              redirect="/not-authorized"
            />
            <MainLayout path="/checkout" component={lazy(() => import('containers/CheckoutPage'))} />
            <MainLayout path="/orders" exact component={lazy(() => import('containers/OrdersPage'))} />
            <MainLayout
              path="/orders-pending-approval"
              exact
              component={lazy(() => import('containers/PendingApprovalPage'))}
            />
            <MainLayout
              path="/pending-orders-history"
              exact
              component={lazy(() => import('containers/PendingOrdersHistoryPage'))}
            />
            <ProductListingLayout path="/favorite" component={lazy(() => import('containers/FavoritePage'))} />
            <MainLayout path="/account-info" component={lazy(() => import('containers/AccountInfoPage'))} />
            <MainLayout path="/company-info" component={lazy(() => import('containers/CompanyInfoPage'))} />
            <MainLayout path="/store-info" component={lazy(() => import('containers/StoreInfoPage'))} />
            <MainLayout path="/user-management" component={lazy(() => import('containers/UserManagementPage'))} />
            <MainLayout path="/user-details/:slug" component={lazy(() => import('containers/UserDetailsPage'))} />
            <MainLayout path="/group-management" component={lazy(() => import('containers/GroupsPage'))} />
            <MainLayout path="/groups/:slug" component={lazy(() => import('containers/GroupDetailsPage'))} />
            <MainLayout path="/item-analysis" component={lazy(() => import('containers/ItemAnalysisPage'))} />
            <MainLayout path="/notifications" component={lazy(() => import('containers/NotificationPage'))} />
            <MainLayout path="/not-found" isAuth={false} component={lazy(() => import('containers/NotFoundPage'))} />
            <MainLayout
              path="/not-authorized"
              isAuth={false}
              component={lazy(() => import('containers/NotAuthorizedPage'))}
            />
            <GateLayout path="/signin" component={lazy(() => import('containers/SigninPage'))} />
            <GateLayout
              path="/kamereo-internal-signup"
              component={lazy(() => import('containers/SignupPage'))}
              acceptGuest
            />
            <GateLayout path="/contact-us" component={lazy(() => import('containers/ContactUsPage'))} />
            <GateLayout path="/contact-success" component={lazy(() => import('containers/ContactUsSuccessPage'))} />
            <GateLayout path="/forgot-password" component={lazy(() => import('containers/ForgotPage'))} />
            <GateLayout path="/reset-password" component={lazy(() => import('containers/ResetPage'))} />
            <GateLayout
              path="/invitations/:slug"
              component={lazy(() => import('containers/InvitationPage'))}
              acceptGuest
            />
            <PrivateGateLayout path="/change-store" component={lazy(() => import('containers/ChangeStorePage'))} />
            <MainLayout path="/account-mobile" component={lazy(() => import('containers/AccountMobilePage'))} />
            <Route path="/docs/:slug" render={(matchProps) => <DocPage {...matchProps} />} />
            {originalUrl.includes('/v2') && <Redirect to={originalUrl.replace('/v2', '')} />}
            <ProductListingLayout
              path="/:slug([\w-/]+)(-c[\d]{1,5})(\?lang=en)?"
              component={lazy(() => import('containers/MarketPage'))}
            />
            <ProductListingLayout
              path="/:slug([A-Z0-9_]+)"
              component={lazy(() => import('containers/MarketPage'))}
              sensitive
            />
            <Redirect to="/not-found" />
          </Switch>
          <GlobalStyles />
          {!pathNotDisplayRegionModal.includes(redirectPath || window.location.pathname) && !isLogin && (
            <SelectRegionModal />
          )}
        </ViewportProvider>
      </PageManager>
    </Router>
  );
};

export default App;
