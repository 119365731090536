/* tslint:disable */

import * as Sentry from '@sentry/browser';
import { print } from 'graphql';
import { get } from 'lodash';
import { call, put } from 'redux-saga/effects';
import 'whatwg-fetch';
import packageJson from '../../../package.json';
const { version } = packageJson;
import {
  addStroreDisabled,
  brand,
  brands,
  buyer,
  buyerCreateAccount,
  buyerWebVersion,
  cart,
  cartClearSupplier,
  cartClearSupplierLight,
  cartCreateOrderDraft,
  cartDeleteDeliveryNote,
  cartDeleteItem,
  cartDeleteItemLight,
  cartGetAvailableDeliveryTimeSlots,
  cartGetDeliveryDateOffs,
  cartGetNextDeliveryDate,
  cartGetOrderDeadline,
  cartPutDeliveryDate,
  cartPutDeliveryNote,
  cartPutDeliveryTime,
  cartPutItem,
  cartPutItemLight,
  categories,
  categoryDescription,
  checkEmail,
  companyInviteUsers,
  companyUpdate,
  deletePendingOrder,
  deliveryNoteCreate,
  deliveryNoteDelete,
  deliveryNoteUpdate,
  deliveryNotes,
  favoriteAddItem,
  favoriteRemoveItem,
  featureFlagToggle,
  group,
  groupMemberAdd,
  groupMemberRemove,
  groupRemove,
  homePagePopup,
  invitation,
  invitationAccept,
  invitationAcceptAsNewBuyer,
  invitationSetStores,
  me,
  meForgetPassword,
  meLogin,
  meRelogin,
  meResetPassword,
  meUpdate,
  meUpdateLang,
  meUpdatePassword,
  notificationMarkAsReadMutation,
  notificationMessages,
  notificationRegisterMutation,
  notificationUnregisterMutation,
  order,
  orderApprove,
  orderCancel,
  orderCreate,
  orderDecline,
  orderDraft,
  orderReceive,
  orderReorder,
  orderStatistics,
  orderTotalSummary,
  orderUpdateDeliveredItems,
  orderUpdateItem,
  orderUpdateUnitPrice,
  orders,
  ordersExport,
  product,
  productSearch,
  productSetNote,
  shortSuppliers,
  storeCreate,
  storeOrderItemsSummary,
  storeUpdate,
  updatePendingOrder,
  user,
  userGroupCreate,
  userGroupUpdate,
  userRemove,
  userUpdate,
  users,
  widgets,
  provinceSearch,
  districtSearch,
  wardSearch,
  listSalesPic,
  getGroupCategories,
  groupRTEByCity,
  rteLocationList,
  createNewProductRequest,
  getVaAndQrInfo,
  allUoms,
  getAllOrigins,
  brandWithCategories,
  checkStoreIsDuplicate,
  putItemsToFavorite,
  listHolidays,
  getFrequentlyBuyerTogether,
} from '../query';

import { ApolloClient, HttpLink } from '@apollo/client';
import { ApolloCache, InMemoryCache, NormalizedCacheObject } from '@apollo/client/cache';
import { setContext } from '@apollo/client/link/context';
import { signout } from 'containers/MainLayout/actions';
import jwtDecode from 'jwt-decode';
import {
  QueryBrandsArgs,
  QueryNotificationMessagesArgs,
  QueryProductSearchArgs,
  QueryProvinceSearchArgs,
  QueryDistrictSearchArgs,
  QueryWardSearchArgs,
  QueryListSalesPicArgs,
  QueryCategoryDetailArgs,
  QueryGetGroupCategoriesArgs,
  QueryGetRteLocationListArgs,
  QueryBrandsForHomeArgs,
} from 'types/schema';
import orderDisabled from 'utils/query/orderDisabled';
import recommendationItems from 'utils/query/recommendationItems';
import productBySupplierInternalCode from 'utils/query/productBySupplierInternalCode';
import sendContactUs from 'utils/query/sendContactUs';
import { QueryFindFullAddressArgs } from '../../types/schema';
import findFullAddress from '../query/findFullAddress';

const apiUrl = process.env.API_URL || 'https://buyer-graphql.qa.kamereo.vn';

const NODE_API_URL = '/api/v1';

type Option = {
  headers: HeadersInit;
  method?: string;
  body?: any;
};

let headers: HeadersInit;

const defaultOptions: any = {
  watchQuery: {
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
  },
};

async function refreshToken() {
  const rememberToken = window.localStorage.getItem('rememberToken');
  const response = await fetch(apiUrl, {
    method: 'POST',
    body: JSON.stringify({
      operationName: 'meRelogin',
      variables: { token: rememberToken },
      query: print(meRelogin),
    }),
    headers: {
      accept: '*/*',
      'content-type': 'application/json',
      'X-Client-AppName': 'BuyerWeb',
      'X-Client-Version': version,
    },
  });
  const data = await response.json();
  return data;
}

async function handleTokenExpiry(requestToken: string) {
  const decodedToken = jwtDecode(requestToken);

  if (Date.now() > decodedToken.exp * 1000) {
    const data = await refreshToken();
    const error = (data.errors || []).find((error) =>
      (error.graphQLErrors || []).find(
        (error) => error.extensions.subcode === '6101' || error.extensions.subcode === '6102',
      ),
    );

    if (error) {
      window.localStorage.removeItem('token');
      window.localStorage.removeItem('rememberToken');
    }

    const reoginToken = get(data, ['data', 'meRelogin', 'token']);

    if (reoginToken) {
      window.localStorage.setItem('token', reoginToken);
      if (data.data.meRelogin.rememberMeToken) {
        window.localStorage.setItem('rememberToken', data.data.meRelogin.rememberMeToken);
      }

      requestToken = reoginToken;
    }
  }

  return requestToken;
}

async function createApolloClient(currentHeader: HeadersInit) {
  const httpLink = new HttpLink({
    uri: apiUrl,
  });

  const headers = await getHeaders(currentHeader);

  const authLink = setContext(async (_: any, { headers: existingHeaders }: any) => {
    return {
      headers: {
        ...existingHeaders,
        ...headers,
      },
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache() as ApolloCache<NormalizedCacheObject>,
    defaultOptions: defaultOptions,
  });

  return client;
}

function scheduleToRevalidate(client: ApolloClient<NormalizedCacheObject>, options: any) {
  const duration = options.duration || 600000; // 10 minutes in milliseconds
  const intervalId = setInterval(() => {
    client
      .query({ query: options.query, variables: options.variables })
      .then((response: any) => {
        const newData = options.transform ? options.transform(response.data) : response.data;
        client.writeQuery({
          query: options.query,
          data: {
            [options.query.definitions[0].name.value]: newData,
          },
        });
      })
      .catch((error) => {
        if (process.env.APP_ENV !== 'development') {
          Sentry.withScope((scope) => {
            Object.keys(options).forEach((key) => {
              scope.setExtra(key, options[key]);
            });

            captureException(error, options);
          });
        }
      });
  }, duration);

  // Cleanup function to clear the interval
  window.addEventListener('beforeunload', () => clearInterval(intervalId));
}

function writeToCache(client: ApolloClient<NormalizedCacheObject>, options: any) {
  const data = options.transform ? options.transform(options.data) : options.data;
  try {
    client.writeQuery({
      query: options.query,
      data: {
        [options.query.definitions[0].name.value]: data,
      },
    });
  } catch (error) {
    if (process.env.APP_ENV !== 'development') {
      Sentry.withScope((scope) => {
        Object.keys(options).forEach((key) => {
          scope.setExtra(key, options[key]);
        });

        captureException(error, options);
      });
    }

    return {
      errors: [error],
    };
  }
  if (options.revalidation) {
    scheduleToRevalidate(client, options);
  }
  return { success: true };
}

export async function getHeaders(existingHeaders: HeadersInit = {}) {
  const isHeadersExisted = Object.keys(existingHeaders).length > 0 && existingHeaders['aithorization'];
  let requestToken = localStorage.getItem('token');
  let region = localStorage.getItem('region') || 'HCM';

  if (requestToken) {
    requestToken = await handleTokenExpiry(requestToken);
  }

  return isHeadersExisted
    ? existingHeaders
    : {
      authorization: requestToken ? `Bearer ${requestToken}` : '',
      'X-Store': window.localStorage.getItem('store') || '',
      'Accept-Language': window.localStorage.getItem('lang') ?? 'vi',
      'X-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone || 'Asia/Ho_Chi_Minh',
      'X-Client-AppName': 'BuyerWeb',
      'X-Client-Version': version,
      'X-CustomHeader': region,
    };
}

(async () => {
  headers = await getHeaders();
})();

export { headers };

const request = function* (options: any, authentication: boolean = true) {
  const loggedIn = !!window.localStorage.getItem('token');

  const currentHeader = yield call(getHeaders, headers);
  const client = yield call(createApolloClient, currentHeader);

  if (authentication && !loggedIn) {
    yield put(signout());
    return yield Promise.reject({ errors: [{ message: 'Authentication is required' }] });
  }

  function checkTypename(variables) {
    if (Array.isArray(variables)) {
      return variables.map((v) => checkTypename(v));
    }

    if (!variables || typeof variables !== 'object') {
      return variables;
    }

    if ('__typename' in variables) {
      const { __typename, ...withoutTypename } = variables;
      return checkTypename(withoutTypename);
    }

    for (const key in variables) {
      if (typeof variables[key] === 'object') {
        variables[key] = checkTypename(variables[key]);
      }
    }

    return variables;
  }

  if (options.variables && typeof options.variables === 'object') {
    checkTypename(options.variables);
  }
  if (options.mutation) {
    return yield client
      .mutate({ ...options, errorPolicy: 'all' })
      .then((response: any) => {
        if (options.mutation.definitions.length === 1) {
          if (response.data) {
            if (options.transform) {
              return options.transform(response.data);
            }
            const data = response.data[options.mutation.definitions[0].name.value];
            if (data) {
              if (data.success !== false) {
                return data;
              }
              return { errors: data.userErrors };
            }
          }
          return { errors: response.errors || [{ message: 'Something wrong' }] };
        }
        return options.transform ? options.transform(response.data) : response.data;
      })
      .catch((error) => {
        if (process.env.APP_ENV !== 'development') {
          Sentry.withScope((scope) => {
            Object.keys(options).forEach((key) => {
              scope.setExtra(key, options[key]);
            });

            captureException(error, options);
          });
        }

        return {
          errors: [error],
        };
      });
  }
  if (options?.preload && options?.data) {
    writeToCache(client, options);
  }

  return yield client
    .query(options)
    .then((response: any) => {
      if (options.query.definitions.length === 1) {
        if (response.data) {
          if (options.transform) {
            return options.transform(response.data);
          }
          const data = response.data[options.query.definitions[0].name.value];
          if (data) {
            return data;
          }
        }
        return { errors: [{ message: 'Something wrong' }] };
      }
      return options.transform ? options.transform(response.data) : response.data;
    })
    .catch((error) => {
      if (process.env.APP_ENV !== 'development') {
        Sentry.withScope((scope) => {
          Object.keys(options).forEach((key) => {
            scope.setExtra(key, options[key]);
          });

          captureException(error, options);
        });
      }

      return {
        errors: [error],
      };
    });
};

function captureException(error, options) {
  const { mutation, query, variables } = options;
  const { name } = (mutation && mutation.definitions[0]) || (query && query.definitions[0]);
  const additionalInfo = JSON.stringify({
    name,
    variables,
  });

  // sending additional information to Sentry via breadcrumb
  // as sending via captureException truncates the data
  Sentry.addBreadcrumb({
    category: 'exception',
    message: additionalInfo,
    level: Sentry.Severity.Debug,
  });

  Sentry.captureException(new Error(`Error: ${JSON.stringify(error)}`));
}

export function* fetchApi(endpoint: string, options: Option) {
  return yield call(fetch, `${NODE_API_URL}/${endpoint}/${window.localStorage.getItem('lang') || 'vi'}`, {
    method: options?.method || 'GET',
    headers: {
      ...options.headers,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(options?.body),
  });
}

export function* contactUs(variables: any) {
  return yield call(request, { variables: variables, mutation: sendContactUs }, false);
}

export function* signin(variables: any) {
  return yield call(request, { variables: variables, mutation: meLogin }, false);
}

export function* signup(variables: any) {
  return yield call(request, { variables: variables, mutation: buyerCreateAccount }, false);
}

export function* forgetPassword(variables: any) {
  return yield call(request, { variables: variables, mutation: meForgetPassword }, false);
}

export function* resetPassword(variables: any) {
  return yield call(request, { variables: variables, mutation: meResetPassword }, false);
}

export function* checkValidEmail(variables: any) {
  return yield call(request, { variables: variables, mutation: checkEmail }, false);
}

export function* sendRequest(options) {
  return yield call(request, options);
}

export function* updatePassword(variables: any) {
  return yield call(request, { variables: variables, mutation: meUpdatePassword });
}

export function* updateProfile(variables: any) {
  return yield call(request, { variables: variables, mutation: meUpdate });
}

export function* updateLang(variables: any) {
  return yield call(request, { variables: variables, mutation: meUpdateLang });
}

export function* updateUser(variables: any) {
  return yield call(request, { variables: variables, mutation: userUpdate });
}

export function* deleteUser(variables: any) {
  return yield call(request, { variables: variables, mutation: userRemove });
}

export function* getCart(variables: any) {
  return yield call(request, { variables: variables, query: cart });
}

export function* getUser() {
  return yield call(request, { query: me });
}

export function* inviteBuyerUsers(variables: any) {
  return yield call(request, { variables: variables, mutation: companyInviteUsers });
}

export function* getUsers() {
  return yield call(request, { query: users });
}

export function* removeUser(variables: any) {
  return yield call(request, { variables, mutation: removeUser });
}

export function* getCompanyUser(variables: any) {
  return yield call(request, { variables: variables, query: user });
}

export function* getBuyer() {
  return yield call(request, { query: buyer });
}

export function* getCategories(variables?: any) {
  return yield call(request, { variables: variables, query: categories, preload: true }, false);
}

export function* preloadCategoriesAndRevalidate(variables?: any) {
  const preloadCategories = (window as any).__PRELOADED_STATE__?.categories;
  if (preloadCategories) {
    const response = yield call(
      request,
      { variables: variables, query: categories, preload: true, data: preloadCategories, revalidation: false },
      false,
    );
    if (response.success) {
      return preloadCategories;
    }
    return yield call(request, { variables: variables, query: categories }, false);
  }
  return yield call(request, { variables: variables, query: categories }, false);
}

export function* addFavorite(variables: any) {
  return yield call(request, { variables: variables, mutation: favoriteAddItem });
}

export function* removeFavorite(variables: any) {
  return yield call(request, { variables: variables, mutation: favoriteRemoveItem });
}

export function* addCartItem(variables: any) {
  return yield call(request, { variables: variables, mutation: cartPutItem });
}

export function* addCartItemLight(variables: any) {
  return yield call(request, { variables: variables, mutation: cartPutItemLight });
}

export function* removeCartItem(variables: any) {
  return yield call(request, { variables: variables, mutation: cartDeleteItem });
}

export function* removeCartItemLight(variables: any) {
  return yield call(request, { variables: variables, mutation: cartDeleteItemLight });
}

export function* updateCartDeliveryDate(variables: any) {
  return yield call(request, { variables: variables, mutation: cartPutDeliveryDate });
}

export function* updateCartDeliveryTime(variables: any) {
  return yield call(request, { variables: variables, mutation: cartPutDeliveryTime });
}

export function* addCartDeliveryNote(variables: any) {
  return yield call(request, { variables: variables, mutation: cartPutDeliveryNote });
}

export function* removeCartSupplier(variables: any) {
  return yield call(request, { variables: variables, mutation: cartClearSupplier });
}

export function* removeCartSupplierLight(variables: any) {
  return yield call(request, { variables: variables, mutation: cartClearSupplierLight });
}

export function* removeCartDeliveryNote(variables: any) {
  return yield call(request, { variables: variables, mutation: cartDeleteDeliveryNote });
}

export function* createOrderDraft(variables: any) {
  return yield call(request, { variables: variables, mutation: cartCreateOrderDraft });
}

export function* getOrderDraft(variables: any) {
  return yield call(request, { variables: variables, query: orderDraft });
}

export function* createOrder(variables: any) {
  return yield call(request, { variables: variables, mutation: orderCreate });
}

export function* getDeliveryNotes() {
  return yield call(request, { query: deliveryNotes });
}

export function* createDeliveryNote(variables: any) {
  return yield call(request, { variables: variables, mutation: deliveryNoteCreate });
}

export function* updateDeliveryNote(variables: any) {
  return yield call(request, { variables: variables, mutation: deliveryNoteUpdate });
}

export function* removeDeliveryNote(variables: any) {
  return yield call(request, { variables: variables, mutation: deliveryNoteDelete });
}

export function* addStore(variables: any) {
  return yield call(request, { variables: variables, mutation: storeCreate });
}

export function* updateStore(variables: any) {
  return yield call(request, { variables: variables, mutation: storeUpdate });
}

export function* getProducts(variables: QueryProductSearchArgs) {
  const regionCode = window.localStorage.getItem('region') || 'HCM';

  return yield call(
    request,
    {
      variables: {
        ...variables,
        filter: {
          ...variables.filter,
          regionCode,
        },
      },
      query: productSearch,
    },
    false,
  );
}

export function* getDescriptionById(variables: QueryCategoryDetailArgs) {
  return yield call(request, { variables: variables, query: categoryDescription }, false);
}

export function* getProduct(variables: any) {
  const regionCode = window.localStorage.getItem('region') || 'HCM';
  return yield call(
    request,
    {
      variables: {
        ...variables,
        regionCode,
      },
      query: product,
    },
    false,
  );
}
export function* getProductBySupplierInternalCode(variables: { supplierInternalCode: string }) {
  return yield call(request, { variables: variables, query: productBySupplierInternalCode }, false);
}

export function* getRecommendationItems(variables: any) {
  return yield call(request, { variables, query: recommendationItems });
}

export function* getStoreOrderItemsSummary(variables: any) {
  return yield call(request, { variables: variables, query: storeOrderItemsSummary });
}

export function* getOrder(variables: any) {
  return yield call(request, { variables: variables, query: order });
}

export function* getOrders(variables: any) {
  return yield call(request, { variables: variables, query: orders });
}

export function* downloadOrdersReport(variables: any) {
  return yield call(request, { variables: variables, query: ordersExport });
}

export function* getShortSuppliers(variables: any) {
  return yield call(request, { variables: { ...variables, query: `${variables.query}` }, query: shortSuppliers });
}

export function* createReOrder(variables: any) {
  return yield call(request, { variables: variables, mutation: orderReorder });
}

export function* updateDeliveryItem(variables: any) {
  return yield call(request, { variables: variables, mutation: orderUpdateDeliveredItems });
}

export function* updateOrderItem(variables: any) {
  return yield call(request, { variables: variables, mutation: orderUpdateItem });
}

export function* updateUnitPrice(variables: any) {
  return yield call(request, { variables: variables, mutation: orderUpdateUnitPrice });
}

export function* confirmOrder(variables: any) {
  return yield call(request, { variables: variables, mutation: orderReceive });
}

export function* updateOrderStatusToCancel(variables: any) {
  return yield call(request, { variables: variables, mutation: orderCancel });
}

export function* updateCompany(variables: any) {
  return yield call(request, {
    variables: variables,
    mutation: companyUpdate,
    transform: (data: any) => {
      const buyer = data['buyerUpdate'];
      const redInvoice = data['companyUpdateRedInvoice'];
      let result: any = {};
      if (!buyer.success && !redInvoice.success) {
        result.errors = [].concat(buyer.userErrors || [], redInvoice.userErrors || []);
      }
      if (buyer.success) {
        result = { ...buyer };
      }
      if (redInvoice.success) {
        result.redInvoiceInfo = { ...redInvoice };
      }

      return result;
    },
  });
}

export function* getOrderStatistics(variables: any) {
  return yield call(request, {
    variables: variables,
    query: orderStatistics,
    transform: (data: any) => {
      return data;
    },
  });
}

export function* getOrderTotalSummary(variables: any) {
  return yield call(request, { variables: variables, query: orderTotalSummary });
}

export function* getAvailableDeliveryTimeSlots(variables: any) {
  return yield call(request, { variables: variables, query: cartGetAvailableDeliveryTimeSlots });
}

export function* getDeliveryDateOffs(variables: any) {
  return yield call(request, { variables: variables, query: cartGetDeliveryDateOffs });
}

export function* getNextDeliveryDate(variables: any) {
  return yield call(request, { variables: variables, query: cartGetNextDeliveryDate });
}

export function* getOrderDeadline(variables: any) {
  return yield call(request, { variables: variables, query: cartGetOrderDeadline });
}

export function* getGroup(variables: any) {
  return yield call(request, { variables, query: group });
}

export function* createGroup(variables: any) {
  return yield call(request, { variables, mutation: userGroupCreate });
}

export function* updateGroup(variables: any) {
  return yield call(request, { variables, mutation: userGroupUpdate });
}

export function* addMembersGroup(variables: any) {
  return yield call(request, { variables, mutation: groupMemberAdd });
}

export function* removeMembersGroup(variables: any) {
  return yield call(request, { variables, mutation: groupMemberRemove });
}

export function* deleteGroup(variables: any) {
  return yield call(request, { variables, mutation: groupRemove });
}

export function* setFeatureFlag(variables: any) {
  return yield call(request, { variables, mutation: featureFlagToggle });
}

export function* approveOrder(variables: any) {
  return yield call(request, { variables, mutation: orderApprove });
}

export function* declineOrder(variables: any) {
  return yield call(request, { variables, mutation: orderDecline });
}

export function* updateOrderPending(variables: any) {
  return yield call(request, { variables, mutation: updatePendingOrder });
}

export function* deleteOrderPending(variables: any) {
  return yield call(request, { variables, mutation: deletePendingOrder });
}

export function* setProductNote(variables: any) {
  return yield call(request, { variables, mutation: productSetNote });
}

export function* getInvitation(variables: any) {
  return yield call(request, { variables, query: invitation }, false);
}

export function* acceptInvitation(variables: any) {
  return yield call(request, { variables, mutation: invitationAccept }, false);
}

export function* acceptAsNewBuyerInvitation(variables: any) {
  return yield call(request, { variables, mutation: invitationAcceptAsNewBuyer }, false);
}

export function* setInvitationStores(variables: any) {
  return yield call(request, { variables, mutation: invitationSetStores }, false);
}

export function* getOrderDisabled() {
  return yield call(request, { query: orderDisabled });
}

export function* notificationTokenRegister(variables: any) {
  return yield call(request, { variables, mutation: notificationRegisterMutation });
}

export function* notificationTokenUnregister(variables: any) {
  return yield call(request, { variables, mutation: notificationUnregisterMutation });
}

export function* notificationMarkAsRead(variables: any) {
  return yield call(request, { variables, mutation: notificationMarkAsReadMutation });
}

export function* getWidgetConfig(variables: any) {
  return yield call(request, { variables: variables, query: widgets }, false);
}

export function* getHomepagePopup() {
  return yield call(request, { query: homePagePopup }, false);
}

export function* getNotificationMessages(variables: QueryNotificationMessagesArgs) {
  return yield call(request, { variables, query: notificationMessages });
}

export function* getAddStoreDisabled() {
  return yield call(request, { query: addStroreDisabled });
}

export function* getBuyerWebVersion() {
  return yield call(request, { query: buyerWebVersion }, false);
}

export function* getBrand(variables: any) {
  return yield call(request, { variables: variables, query: brand }, false);
}

export function* getBrands(variables: QueryBrandsArgs) {
  return yield call(request, { variables: variables, query: brands }, false);
}

export function* getBrandsWithCategories(variables: QueryBrandsForHomeArgs) {
  return yield call(request, { variables: variables, query: brandWithCategories }, false);
}
export function* getProvince(variables: QueryProvinceSearchArgs) {
  return yield call(request, { variables: variables, query: provinceSearch }, false);
}

export function* getDistricts(variables: QueryDistrictSearchArgs) {
  return yield call(request, { variables: variables, query: districtSearch }, false);
}

export function* getWards(variables: QueryWardSearchArgs) {
  return yield call(request, { variables: variables, query: wardSearch }, false);
}

export function* getFullAddress(variables: QueryFindFullAddressArgs) {
  return yield call(request, { variables: variables, query: findFullAddress }, false);
}


export function* getListSalesPic(variables: QueryListSalesPicArgs) {
  return yield call(request, { variables: variables, query: listSalesPic }, false);
}

export function* getGroupCategoriesData(variables: QueryGetGroupCategoriesArgs) {
  return yield call(request, { variables: variables, query: getGroupCategories }, false);
}

export function* getGroupRTEByCity() {
  return yield call(request, { query: groupRTEByCity }, false);
}

export function* getRTELocationList(variables: QueryGetRteLocationListArgs) {
  return yield call(request, { variables: variables, query: rteLocationList }, false);
}

export function* requestNewProductItem(variables: any) {
  return yield call(request, { variables: variables, mutation: createNewProductRequest }, false);
}

export function* getVaAndQrInfoData(variables: any) {
  return yield call(request, { variables: variables, query: getVaAndQrInfo });
}

export function* getAllUoms() {
  return yield call(request, { query: allUoms }, false);
}

export function* getOrigins() {
  return yield call(request, { query: getAllOrigins }, false);
}

export function* handleCheckStoreIsDuplicate(variables) {
  return yield call(request, { variables: variables, mutation: checkStoreIsDuplicate }, false);
}

export function* handlePutItemsToFavorite(variables) {
  return yield call(request, { variables, mutation: putItemsToFavorite })
}

export function* getListHolidays() {
  return yield call(request, { query: listHolidays });
}

export function* handleGetFrequentlyBuyTogether(variables) {
  return yield call(request, { variables, query: getFrequentlyBuyerTogether })
}