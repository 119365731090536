import gql from 'graphql-tag';

export default gql`
  query allUoms {
    allUoms {
      data {
        code
        id
        nameEn
        nameVn
      }
    }
  }
`;
