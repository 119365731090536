import { Input as AInput, Col, Form, Row, FormInstance, AutoComplete } from 'antd';
import Select from 'components/Select/Select';
import React, { FC, useEffect, useMemo, memo, useState, ChangeEvent, useCallback, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose, Dispatch } from 'redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import utilsMessages from 'utils/messages';
import { getDistrictByProvince, getWardsByDistrict, fetchProvince } from './actions';
import reducer from './reducer';
import saga from './saga';
import selectAddressComponent from './selectors';
import { RootState } from './type';
import { District, Province, Ward } from '../../types/schema';
import { FIELD_NAME_FULL_TEXT } from 'components/CreateStorePopup/constants';
import { debounce } from 'lodash';
import { handleDuplicateAddressField, isMatchAddress } from 'components/CreateStorePopup/utils';
import useAutoSuggestion from 'components/CreateStorePopup/hooks/useAutoSuggestion';
import useAddressToCoords from 'components/CreateStorePopup/hooks/useAddressToCoords';
import { useAddressLocation } from 'containers/LandingPage/DistributionLocationPage/components/Map/hooks/GoogleMapScriptContext';
import { DEFAULT_REGION_COORDS, DEFAULT_COUNTRY_NAME } from '../CreateStorePopup/constants';
import translations from 'translations';

interface IDispatchProps {
  getDistrictData: (payload: { provinceId: string; cb: (data: District[]) => void }) => void;
  getWardData: (payload: { districtId: string; cb: (data: Ward[]) => void }) => void;
  getProvinceData: (payload: { regions?: string[]; cb: (data: Province[]) => void }) => void;
}

interface IStateProps {
  address: any;
}

interface IOwnProps {
  form: FormInstance<any>;
  fieldName: {
    city: string | string[];
    district: string | string[];
    ward: string | string[];
    addressDetail: string | string[];
    wardId?: string | string[];
    geoLat?: string | string[];
    geoLon?: string | string[];
    geoPlusCode?: string | string[];
  };
  fieldNameFullText: typeof FIELD_NAME_FULL_TEXT;
  setOpenExtendedMap: React.Dispatch<React.SetStateAction<boolean>>;
  isOpenExtendedMap: boolean;
  setCoords: React.Dispatch<React.SetStateAction<google.maps.LatLngLiteral | null>>;
  handleShowWarning: () => void;
  placeId: string;
  setPlaceId: React.Dispatch<React.SetStateAction<string | null>>;
  setIsOutOfRegion: React.Dispatch<React.SetStateAction<boolean>>;
  currentAddressDetail?: string;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isAlreadySubmit?: boolean;
  regions?: string[];
  isUpdate?: boolean;
}

type Props = IDispatchProps & IOwnProps & IStateProps;

interface RepresentativeLocation {
  provinceId?: string;
  districtId?: string;
  wardId?: string;
  pluscode?: string;
}

const MapAddressAutoComplete = styled(AutoComplete)`
  width: '100%';
  padding-right: 0;

  & .ant-select-selection-search {
    padding-right: 0 !important;
  }
`;

const AntdInput = styled(AInput)<any>`
  border-radius: 8px !important;
`;

export const FormItem = styled(Form.Item)`
  /* margin-bottom: 24px !important; */
  ${({ notASelect }) =>
    notASelect &&
    `& .ant-select .ant-select-arrow {
      display: none;
  }`}
  ${({ opacity }) =>
    opacity &&
    `& .ant-form-item-label > label {
      opacity: 0.5;
  }`}
`;

const AddressForMap: FC<Props> = (props) => {
  const {
    fieldNameFullText,
    fieldName,
    form,
    setOpenExtendedMap,
    isOpenExtendedMap,
    setCoords,
    handleShowWarning,
    placeId,
    setPlaceId,
    setIsOutOfRegion,
    getWardData,
    getDistrictData,
    getProvinceData,
    currentAddressDetail,
    setLoading,
    regions,
    isAlreadySubmit,
    isUpdate = false,
  } = props;
  const currentLang = window.localStorage.getItem('lang') || 'vi';
  const [provinces, setProvinceData] = useState([] as Province[]);
  const [districts, setDistrict] = useState([] as District[]);
  const [wards, setWards] = useState([] as Ward[]);
  const [addressDetail, setAddressDetail] = useState('');
  const addressValue = Form.useWatch(fieldNameFullText.addressDetail, form);
  const {
    autoSuggestions,
    setAutoSuggestions,
    handleMatchedSuggestion,
    setIsFetchingSuggestions,
    setIsReWordingTitle,
    isFetchingSuggestions,
    isRewordingTitle,
  } = useAutoSuggestion();
  const { addressComponents, setAddressComponents } = useAddressLocation();
  const [representativeLocation, setPresentativeLocation] = useState<RepresentativeLocation>({});
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [open, setOpen] = useState(false);
  const autoCompleteRef: any = useRef(null);
  const isRequireManualSelect = useMemo(() => {
    const hasSelectedAnAddress = placeId && !isOpenExtendedMap;
    if (addressComponents) {
      if (
        'provinceId' in representativeLocation &&
        'districtId' in representativeLocation &&
        'wardId' in representativeLocation &&
        hasSelectedAnAddress
      ) {
        return (
          !representativeLocation?.provinceId || !representativeLocation.districtId || !representativeLocation.wardId
        );
      }
      if (hasSelectedAnAddress) {
        return true;
      }
      return false;
    }
    return false;
  }, [addressComponents, representativeLocation, placeId, isOpenExtendedMap]);
  const [debouncedIsRequireManualSelect, setDebouncedIsRequireManualSelect] = useState(isRequireManualSelect);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedIsRequireManualSelect(isRequireManualSelect);
      setLoading(false);
      // const formattedAddress = form.getFieldValue(fieldNameFullText?.formattedAddress);
      // if (!isRequireManualSelect) {
      //   form.setFieldValue(fieldNameFullText.addressDetail, formattedAddress);
      // }
    }, 1200);

    return () => {
      clearTimeout(handler);
    };
  }, [isRequireManualSelect, form]);

  useAddressToCoords({
    addressValue: addressValue,
    setAutoSuggestions: setAutoSuggestions,
    setAddressComponents: setAddressComponents,
    setCoords: setCoords,
    placeId: placeId,
    setIsFetchingSuggestions: setIsFetchingSuggestions,
  });

  useEffect(() => {
    if (addressComponents?.province) {
      const matchedProvince = provinces.find((province) => {
        return isMatchAddress(addressComponents?.province, province.nameEn || '', 'province');
      });
      if (matchedProvince) {
        setPresentativeLocation((prevState) => {
          return {
            ...prevState,
            provinceId: matchedProvince?.code || '',
          };
        });
        setIsOutOfRegion(false);
      } else {
        onOutOfRegionSelected();
      }
    }
    if (addressComponents?.district) {
      const matchedDistrict = districts.find((district) => {
        return isMatchAddress(addressComponents?.district, district.nameEn || '', 'district');
      });
      setPresentativeLocation((prevState) => {
        return {
          ...prevState,
          districtId: matchedDistrict?.code || '',
        };
      });
    }
    if (addressComponents?.ward) {
      const matchedWard = wards.find((ward) => {
        return isMatchAddress(addressComponents?.ward, ward.nameEn || '', 'ward');
      });
      setPresentativeLocation((prevState) => {
        return {
          ...prevState,
          wardId: matchedWard?.code || '',
        };
      });
    }
    if (addressComponents?.pluscode) {
      form.setFieldValue(fieldName?.geoPlusCode, addressComponents.pluscode);
    }
    if (addressComponents?.province && addressComponents?.district && addressComponents?.ward) {
      // const addressDetail = form.getFieldValue(fieldName.addressDetail);
      const duplicatedAddress = {
        province: addressComponents?.province || '',
        district: addressComponents?.district || '',
        ward: addressComponents?.ward || '',
        // surroundDistrict: addressComponents.surroundDistrict,
        // surroundWard: addressComponents.surroundWard,
        country: DEFAULT_COUNTRY_NAME,
        label: addressComponents?.fullAddress || '',
      };
      const formattedAddress = handleDuplicateAddressField(duplicatedAddress);
      let escapedAddress = formattedAddress
        ?.split(',')
        .filter(Boolean)
        .map((field) => field.trim())
        .join(', ');
      escapedAddress = addressComponents?.description
        ? `(${addressComponents?.description}) ${escapedAddress}`
        : escapedAddress;
      if (fieldNameFullText?.formattedAddress) {
        form.setFieldValue(fieldNameFullText?.formattedAddress, escapedAddress);
      }
    } else {
      const formattedAddress = addressComponents?.description
        ? `(${addressComponents?.description}) ${addressComponents?.fullAddress}`
        : addressComponents?.fullAddress;
      form.setFieldValue(fieldNameFullText?.formattedAddress, formattedAddress);
    }
  }, [addressComponents, provinces, districts, wards, form]);

  const onClearAddress = (isClearAll = true) => {
    form.resetFields([fieldName.city, fieldName.district, fieldName.ward, fieldName.geoPlusCode]);
    form.resetFields([fieldNameFullText?.city, fieldNameFullText?.district, fieldNameFullText?.ward]);
    if (isClearAll) {
      form.resetFields([fieldName.addressDetail]);
      form.resetFields([fieldNameFullText?.addressDetail]);
      setAddressDetail('');
    }
    setAddressComponents(null);
    setDistrict([]);
    setWards([]);
    setLoading(false);
  };

  const onOutOfRegionSelected = () => {
    handleShowWarning();
    setIsOutOfRegion(true);
    setPlaceId('');
    const defaultCoords = regions?.length ? DEFAULT_REGION_COORDS[regions[0]] : null;
    setCoords(defaultCoords);
    onClearAddress(false);
  };

  const provinceData = useMemo(() => {
    if (provinces?.length) {
      return provinces?.map((pv) => ({
        label: currentLang === 'vi' ? pv.fullName : pv.fullNameEn,
        value: pv.code,
      }));
    }
    return [];
  }, [provinces]);

  const districtsData = useMemo(() => {
    if (districts?.length) {
      return districts?.map((dis) => ({
        label: currentLang === 'vi' ? dis.fullName : dis.fullNameEn,
        value: dis.code,
      }));
    }
    return [];
  }, [districts]);

  const wardsData = useMemo(() => {
    if (wards?.length) {
      return wards?.map((ward) => ({
        label: currentLang === 'vi' ? ward.fullName : ward.fullNameEn,
        value: ward.code,
      }));
    }
    return [];
  }, [wards]);

  const onChangeProvince = (provinceId) => {
    if (provinceId) {
      getDistrictData({ provinceId: provinceId, cb: (data) => setDistrict(data) });
      form.setFieldValue(fieldName.district, null);
      form.setFieldValue(fieldName.ward, null);
      if (fieldNameFullText?.city) {
        const provinceInfo = provinces.find((province) => province.code === provinceId);
        form.setFieldValue(fieldNameFullText?.city, provinceInfo?.fullNameEn);
        setAddressComponents((prevState: any) => {
          return {
            ...prevState,
            province: provinceInfo?.fullNameEn || '',
          };
        });
      }
    }
  };

  const onChangeDistrict = (districtId) => {
    if (districtId) {
      getWardData({ districtId: districtId, cb: (data) => setWards(data) });
      form.setFieldValue(fieldName.ward, null);
      if (fieldNameFullText?.district) {
        const districtInfo = districts.find((district) => district.code === districtId);
        form.setFieldValue(fieldNameFullText?.district, districtInfo?.fullNameEn);
        setAddressComponents((prevState: any) => {
          return {
            ...prevState,
            district: districtInfo?.fullNameEn || '',
          };
        });
      }
    }
  };

  const debounceUpdateAddressDetail = useMemo(() => {
    return debounce((value) => {
      if (fieldNameFullText?.addressDetail) {
        form.setFieldValue(fieldNameFullText?.addressDetail, value);
      }
      setAddressDetail(value);
    }, 300);
  }, []);

  const onChangeAddressDetail = (value: string) => {
    if (placeId) {
      onClearAddress(false);
      setPlaceId('');
    }
    debounceUpdateAddressDetail(value);
  };

  const isAlreadySelectedOnMap = useCallback(
    (value: string) => {
      return autoSuggestions.length === 1 && autoSuggestions?.[0]?.placeId === placeId && value !== 'open-extended-map';
    },
    [autoSuggestions, placeId],
  );

  const handleSelectAddressDetail = (value: string) => {
    if (!value) return;
    if (isAlreadySelectedOnMap(value)) {
      return;
    }
    setPresentativeLocation({});
    onClearAddress(false);
    if (value !== 'open-extended-map') {
      if (fieldNameFullText?.addressDetail) {
        form.setFieldValue(fieldNameFullText?.addressDetail, value);
      }
      const placeId =
        autoSuggestions?.find((item) => {
          return item?.description === value;
        })?.placeId || '';
      setPlaceId(placeId);
      setAddressDetail(value);
    } else {
      const value = addressValue || addressDetail;
      form.setFieldValue(fieldName.addressDetail, value);
      if (fieldNameFullText?.addressDetail) {
        form.setFieldValue(fieldNameFullText?.addressDetail, value);
      }
      setOpenExtendedMap(true);
      const placeId = autoSuggestions?.[0]?.placeId || '';
      setPlaceId(placeId);
      setAddressDetail(value);
    }
    form.validateFields([fieldName.addressDetail]);
  };

  const handleOnClicked = useCallback(() => {
    if (placeId && autoSuggestions.length) {
      const targetAddress = autoSuggestions?.find((item) => item.placeId === placeId);
      if (targetAddress) {
        setAutoSuggestions([targetAddress]);
      } else {
        setAutoSuggestions([
          {
            description: addressValue,
            placeId: placeId,
          },
        ]);
      }
    }
  }, [placeId, autoSuggestions, addressValue]);

  const handleKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        onChangeAddressDetail(event.currentTarget.value);
        const placeId = autoSuggestions?.[0]?.placeId || '';
        setPlaceId(placeId);
      }
    },
    [onChangeAddressDetail, autoSuggestions, setPlaceId],
  );
  const matchedOptions = handleMatchedSuggestion(addressDetail, placeId);

  const handleBlur = () => {
    if (!placeId && !isOpenExtendedMap) {
      onClearAddress();
    }
    if (isRewordingTitle) {
      setIsReWordingTitle(false);
    }
  };
  useEffect(() => {
    if (currentAddressDetail && addressDetail !== currentAddressDetail && !placeId) {
      if (fieldNameFullText?.addressDetail) {
        form.setFieldValue(fieldNameFullText?.addressDetail, currentAddressDetail);
      }
      form.setFieldValue(fieldName.addressDetail, currentAddressDetail);
      form.validateFields([fieldName.addressDetail]);
      setAddressDetail(currentAddressDetail);
    }
    getProvinceData({
      regions: regions,
      cb: (data) => {
        setProvinceData(data);
      },
    });
  }, []);

  useEffect(() => {
    const selectedCity = form.getFieldValue(fieldName.city) || '';
    const selectedDistrict = form.getFieldValue(fieldName.district) || '';
    const selectedWard = form.getFieldValue(fieldName.ward) || '';
    if (representativeLocation?.provinceId && selectedCity !== representativeLocation?.provinceId) {
      const provinceValue = representativeLocation?.provinceId;
      getDistrictData({ provinceId: provinceValue, cb: (data) => setDistrict(data) });
      form.setFieldValue(fieldName.city, provinceValue);
      if (fieldNameFullText?.city) {
        form.setFieldValue(fieldNameFullText?.city, provinceValue);
      }
    } else if (!representativeLocation?.provinceId) {
      form.resetFields([
        fieldName.city,
        fieldName.district,
        fieldName.ward,
        fieldNameFullText?.city,
        fieldNameFullText?.district,
        fieldNameFullText?.ward,
      ]);
      form.validateFields([fieldName.city]);
      return;
    }
    if (representativeLocation?.districtId && selectedDistrict !== representativeLocation.districtId) {
      const districtValue = representativeLocation.districtId;
      getWardData({ districtId: districtValue, cb: (data) => setWards(data) });
      form.setFieldValue(fieldName.district, districtValue);
      if (fieldNameFullText?.district) {
        form.setFieldValue(fieldNameFullText?.district, districtValue);
      }
    } else if (!representativeLocation?.districtId) {
      form.resetFields([fieldName.district, fieldName.ward, fieldNameFullText?.district, fieldNameFullText?.ward]);
      form.validateFields([fieldName.district]);
      return;
    }
    if (representativeLocation?.wardId && selectedWard !== representativeLocation.wardId) {
      const wardValue = representativeLocation.wardId;
      form.setFieldValue(fieldName.ward, wardValue);
      if (fieldNameFullText?.ward && fieldName?.wardId) {
        form.setFieldValue(fieldNameFullText?.ward, wardValue);
        form.setFieldValue(fieldName?.wardId, wardValue);
      }
    } else if (!representativeLocation?.wardId) {
      form.resetFields([fieldName.ward, fieldNameFullText?.ward, fieldName?.wardId]);
      form.validateFields([fieldName.ward]);
      return;
    }
  }, [representativeLocation?.provinceId, representativeLocation?.districtId, representativeLocation?.wardId, form]);
  useEffect(() => {
    if (isUpdate && !isFetchingSuggestions && isFirstRender) {
      if (matchedOptions?.[0]?.options?.length > 1) {
        setIsReWordingTitle(true);
        autoCompleteRef?.current?.focus();
        setOpen(true);
      } else {
        onClearAddress();
      }
      setIsFirstRender(false);
    }
  }, [isUpdate, matchedOptions, isFirstRender, isFetchingSuggestions]);
  return (
    <div className="address-modal">
      <FormattedMessage {...utilsMessages.deliveryAddress}>
        {(companyName: string) => (
          <FormattedMessage {...utilsMessages.fieldRequired} values={{ field: companyName }}>
            {(fieldRequired: string) => (
              <FormattedMessage {...utilsMessages.addressPlaceHolder}>
                {(addressPlaceHolder: string) => (
                  <FormItem
                    name={fieldName.addressDetail}
                    colon={false}
                    required={true}
                    label={companyName}
                    hasFeedback={!isOpenExtendedMap}
                    notASelect
                    rules={[
                      {
                        required: true,
                        message: fieldRequired,
                      },
                      () => ({
                        validator: (_, value) => {
                          if (!value) {
                            return Promise.resolve();
                          }
                          if (!placeId) {
                            return Promise.reject(translations(utilsMessages.incompleteAddressInput));
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <MapAddressAutoComplete
                      options={matchedOptions}
                      onSelect={handleSelectAddressDetail}
                      onSearch={onChangeAddressDetail}
                      open={open}
                      onDropdownVisibleChange={setOpen}
                      onBlur={handleBlur}
                      onClick={handleOnClicked}
                    >
                      <AntdInput
                        ref={autoCompleteRef}
                        onBlur={handleBlur}
                        onPressEnter={handleKeyPress}
                        placeholder={addressPlaceHolder}
                        onClear={onClearAddress}
                        allowClear={true}
                        value={addressDetail}
                      />
                    </MapAddressAutoComplete>
                  </FormItem>
                )}
              </FormattedMessage>
            )}
          </FormattedMessage>
        )}
      </FormattedMessage>
      {debouncedIsRequireManualSelect && !isAlreadySubmit && !isOpenExtendedMap ? (
        <Row gutter={24}>
          <Col xs={{ span: 24 }} lg={{ span: 8 }}>
            <FormattedMessage {...utilsMessages.province}>
              {(province: string) => (
                <FormattedMessage {...utilsMessages.fieldRequired} values={{ field: province }}>
                  {(fieldRequired: string) => (
                    <FormattedMessage {...utilsMessages.provincePlaceHolder}>
                      {(provincePlaceHolder) => (
                        <FormItem
                          name={fieldName.city}
                          colon={false}
                          required={true}
                          label={province}
                          hasFeedback
                          rules={[
                            {
                              required: true,
                              message: fieldRequired,
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            items={provinceData}
                            placeholder={provincePlaceHolder}
                            onChange={onChangeProvince}
                          />
                        </FormItem>
                      )}
                    </FormattedMessage>
                  )}
                </FormattedMessage>
              )}
            </FormattedMessage>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 8 }}>
            <FormattedMessage {...utilsMessages.newDistrict}>
              {(newDistrict: string) => (
                <FormattedMessage {...utilsMessages.fieldRequired} values={{ field: newDistrict }}>
                  {(fieldRequired: string) => (
                    <FormattedMessage {...utilsMessages.districtPlaceHolder}>
                      {(districtPlaceHolder) => (
                        <FormItem
                          name={fieldName.district}
                          colon={false}
                          required={true}
                          label={newDistrict}
                          hasFeedback
                          rules={[
                            {
                              required: true,
                              message: fieldRequired,
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            items={districtsData}
                            placeholder={districtPlaceHolder}
                            onChange={onChangeDistrict}
                          />
                        </FormItem>
                      )}
                    </FormattedMessage>
                  )}
                </FormattedMessage>
              )}
            </FormattedMessage>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 8 }}>
            <FormattedMessage {...utilsMessages.newWard}>
              {(newWard: string) => (
                <FormattedMessage {...utilsMessages.fieldWardRequired} values={{ field: newWard }}>
                  {(fieldWardRequired: string) => (
                    <FormattedMessage {...utilsMessages.wardPlaceHolder}>
                      {(wardPlaceHolder) => (
                        <>
                          <FormItem
                            name={fieldName.ward}
                            colon={false}
                            required={true}
                            label={newWard}
                            hasFeedback
                            rules={[
                              {
                                required: true,
                                message: fieldWardRequired,
                              },
                            ]}
                          >
                            <Select
                              items={wardsData}
                              placeholder={wardPlaceHolder}
                              onChange={(wardId) => {
                                if (fieldNameFullText?.ward) {
                                  const wardInfo = wards.find((ward) => ward.code === wardId);
                                  form.setFieldValue(fieldNameFullText?.ward, wardInfo?.code);
                                  form.setFieldValue(fieldName.wardId, wardInfo?.code);
                                }
                              }}
                              showSearch
                              optionFilterProp="children"
                            />
                          </FormItem>
                        </>
                      )}
                    </FormattedMessage>
                  )}
                </FormattedMessage>
              )}
            </FormattedMessage>
          </Col>
        </Row>
      ) : null}
      {fieldNameFullText?.noteForAddress && (
        <FormattedMessage {...utilsMessages.deliveryNote}>
          {(deliveryNote: string) => (
            <FormattedMessage {...utilsMessages.deliveryNotePlaceholder}>
              {(deliveryNotePlaceholder: string) => (
                <FormItem name={fieldNameFullText?.noteForAddress} colon={false} label={deliveryNote} hasFeedback>
                  <AntdInput
                    onChange={(e: ChangeEvent) => {
                      const value = (e.target as HTMLInputElement).value || '';
                      form.setFieldValue(fieldNameFullText?.noteForAddress, value);
                    }}
                    placeholder={deliveryNotePlaceholder}
                  />
                </FormItem>
              )}
            </FormattedMessage>
          )}
        </FormattedMessage>
      )}
      {fieldNameFullText && (
        <div style={{ display: 'none' }}>
          {fieldNameFullText?.city && <FormItem name={fieldNameFullText.city} />}
          {fieldNameFullText?.district && <FormItem name={fieldNameFullText.district} />}
          {fieldNameFullText?.ward && <FormItem name={fieldNameFullText.ward} />}
          {fieldNameFullText?.addressDetail && <FormItem name={fieldNameFullText.addressDetail} />}
          {fieldNameFullText?.formattedAddress && <FormItem name={fieldNameFullText.formattedAddress} />}
          {fieldName?.geoLat && <FormItem name={fieldName.geoLat} />}
          {fieldName?.geoLon && <FormItem name={fieldName.geoLon} />}
          {fieldName?.wardId && <FormItem name={fieldName.wardId} />}
          {fieldName?.geoPlusCode && <FormItem name={fieldName.geoPlusCode} />}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = createStructuredSelector<RootState, IStateProps>({
  address: selectAddressComponent(),
});

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => ({
  getProvinceData: (payload: { regions?: string[]; cb: (data: Province[]) => void }) =>
    dispatch(fetchProvince.request(payload as any)),
  getDistrictData: (payload: { provinceId: string; cb: (data: District[]) => void }) =>
    dispatch(getDistrictByProvince(payload)),
  getWardData: (payload: { districtId: string; cb: (data: Ward[]) => void }) => dispatch(getWardsByDistrict(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer<IOwnProps>({ key: 'addressComponent', reducer: reducer });

const withSaga = injectSaga<IOwnProps>({ key: 'addressComponent', saga: saga });

export default memo(compose(withConnect, withReducer, withSaga)(AddressForMap));
