import { selectLoading, selectNoteItemLoading, selectSections, selectWidgets } from 'containers/HomePage/selectors';
import {
  IHomeFavoritePayload,
  IHomeProductNotePayload,
  ILoadingSection,
  ISection,
  ISectionData,
} from 'containers/HomePage/types';
import React, { FC, useMemo, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { createStructuredSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import {
  Buyer,
  Category,
  Product,
  RecommendationItem,
  ShowcaseItem,
  TrackingAddToCartAddFromType,
  TrackingProductImpressionType,
} from 'types/schema';
import { Typography } from 'antd';
import styled from 'styles/styled-components';
import MarketItem from 'components/MarketItem/MarketItem';
import {
  selectAddedProducts,
  selectAddingProducts,
  selectBuyer,
  selectCategories,
  selectUserPermissions,
} from 'containers/MainLayout/selectors';
import { AddedProduct, AddingProduct } from 'containers/MainLayout/types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import utilsMessages from 'utils/messages';
import ModalNote from 'components/Checkout/ModalNote';
import { setHomeFavirote, setHomeProductNote } from 'containers/HomePage/actions';
import media from 'utils/mediaStyle';
import { isRecommendationTag } from 'utils/utilities';
import { push } from 'connected-react-router';
import generateProductSlug from '../../utils/generateProductSlug';
import MightFavoriteProductsWidget from '../MightFavoriteProductsWidget';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/grid';
import 'swiper/css/mousewheel';
import { Navigation, Mousewheel } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

interface ITrackingFrom {
  productImpression: TrackingProductImpressionType;
  addToCart: TrackingAddToCartAddFromType;
}

const { Title } = Typography;

const Container = styled.div`
  display: flex;
  align-items: stretch;
  gap: 12px;
`;

const SectionTitle = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const SectionHeading = styled(Title)`
  &.ant-typography {
    font-size: 20px;
    line-height: 1.4;
  }
`;

const WidgetWrapper = styled('div')`
  display: grid;
  padding: 16px 16px 0 16px;
  background-color: #fff;
  margin-bottom: 16px;
  border-radius: 4px;
  flex-grow: 1;
  @media (max-width: 576px) {
    &:last-child {
      margin-bottom: 32px;
    }
  }
  ${({ isRecommendation }) =>
    isRecommendation &&
    `
      border: 1px solid #FFDC2C;
      background: #FFFADF;
    `}
`;

const List = styled('div')`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding: 1px;
`;

const Item = styled('div')`
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  flex: 0 0 180px;
  width: 100%;
  height: 100%;
  ${media.md`
    box-shadow: none;
  `};
`;
const RecommendationWrapper = styled.div`
  margin-bottom: 12px;
`;
const RecommendationTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: #242527;
`;
const RecommendationSubTitle = styled.div`
  font-size: 14px;
  color: #585a60;
  margin-top: 2pxl;
`;
const DiscoverMore = styled.div`
  border-radius: 30px;
  background: #ffe664;
  padding: 8px 12px;
  color: #242527;
  font-size: 14px;
  font-weight: 500;
`;

const SwiperSlideElement = styled(SwiperSlide)`
  width: 180px !important;
`;

const SwiperElement = styled(Swiper)`
  max-width: 1136px;
  margin: 0px 0px 24px !important;
  padding: 1px 5px !important;
  .swiper-button-next,
  .swiper-button-prev {
    top: 120px !important;
    width: 30px !important;
    height: 30px !important;
    border-radius: 50% !important;
    background-color: #ffffff !important;
    box-shadow: 0px 0px 6.6px 0px rgba(0, 0, 0, 0.12);
    &:after {
      font-size: 14px !important;
      color: #43a047 !important;
    }
    &.swiper-button-disabled {
      display: none !important;
    }
  }
  .swiper-button-next {
    right: 4px;
    &:after {
      margin-left: 1px;
    }
  }
  .swiper-button-prev {
    left: 4px;
    &:after {
      margin-right: 1px;
    }
  }
`;

interface IOwnProps {
  showcaseId: string;
  showcase: ShowcaseItem | RecommendationItem;
}

interface IStateProps {
  sections: ISection;
  categories: Category[];
  loading: ILoadingSection | boolean;
  addedProducts: AddedProduct;
  addingProducts: AddingProduct;
  permissions: string[];
  noteItemLoading: boolean;
  buyer: Buyer;
  widgets: any;
}

interface IDispatchProps {
  onToggleFavorite: (data: IHomeFavoritePayload) => void;
  onSetProductNote: (data: IHomeProductNotePayload) => void;
  gotoSignIn: () => void;
}

type Props = IStateProps & IDispatchProps & IOwnProps;

const HomeScreenWidget: FC<Props> = (props) => {
  const {
    buyer,
    noteItemLoading,
    showcase,
    showcaseId,
    sections,
    categories,
    loading,
    addedProducts,
    addingProducts,
    permissions,
    onToggleFavorite,
    onSetProductNote,
    gotoSignIn,
    widgets,
  } = props;
  const section = sections[showcaseId] as ISectionData;
  const loggedIn = !!localStorage.getItem('token');
  const lang = localStorage.getItem('lang') || 'vi';
  const [selectedProduct, setSelectedProduct] = useState<Product>();
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [isOpenNoteModal, setIsOpenNoteModal] = useState<boolean>(false);
  const isRecommendation = isRecommendationTag(showcaseId);
  const seeAllUrl = useMemo(() => {
    let url = '/';
    if ('filter' in showcase) {
      if (showcase.filter.categoryName) {
        url += generateProductSlug(showcase.filter.categoryName, `c${showcase.id}`, lang);
      }
      if (showcase.filter.tags) {
        url += `${showcase.filter.tags}`;
      }
    } else if (isRecommendationTag(showcaseId)) {
      url += `RECOMMENDED_FOR_YOU`;
    }
    return url;
  }, [showcase]);

  const trackingFrom = useMemo<ITrackingFrom>(() => {
    if ('filter' in showcase) {
      if (!!showcase.filter.tags) {
        return {
          productImpression: TrackingProductImpressionType.HomeTag,
          addToCart: TrackingAddToCartAddFromType.HomeTag,
        };
      }
      if (!!showcase.filter.categoryName) {
        return {
          productImpression: TrackingProductImpressionType.HomeCategory,
          addToCart: TrackingAddToCartAddFromType.HomeCategory,
        };
      }
    }
    // isRecommendation
    return {
      productImpression: TrackingProductImpressionType.HomeRecommendation,
      addToCart: TrackingAddToCartAddFromType.HomeRecommendation,
    };
  }, [showcase]);

  const firstWidgetIndex = widgets.findIndex((widget) => widget.widgetId === 'MIGHT_FAVORITE_PRODUCTS') + 1;
  const onClickOpenNoteModal = useCallback(
    (product: Product, index: number) => {
      if (loggedIn) {
        setSelectedProduct(product);
        setSelectedIndex(index);
        setIsOpenNoteModal(true);
      } else {
        gotoSignIn();
      }
    },
    [setSelectedProduct, setSelectedIndex, setIsOpenNoteModal, loggedIn],
  );

  const onClickCloseNoteModal = useCallback(() => {
    setSelectedProduct(undefined);
    setSelectedIndex(-1);
    setIsOpenNoteModal(false);
  }, [setSelectedProduct, setSelectedIndex, setIsOpenNoteModal]);

  const onSubmitAddingNote = useCallback(
    (note: string) => {
      if (selectedProduct) {
        onSetProductNote({
          index: selectedIndex,
          section: showcaseId,
          note: note,
          id: selectedProduct.id,
        });
      }
      onClickCloseNoteModal();
    },
    [selectedProduct, showcaseId, selectedIndex, onClickCloseNoteModal],
  );
  if ((!loading[showcaseId] && !section?.data?.length) || sections[showcaseId].error) {
    return null;
  }
  return section?.data?.length ? (
    <Container>
      <WidgetWrapper isRecommendation={isRecommendation}>
        <SectionTitle>
          {isRecommendation ? (
            <RecommendationWrapper>
              <RecommendationTitle>
                <FormattedMessage {...utilsMessages.recommendedProducts} />
              </RecommendationTitle>
              <RecommendationSubTitle>
                {lang === 'vi' ? (
                  <span>
                    Được mua nhiều nhất trong danh mục kinh doanh{' '}
                    {buyer?.businessCategory ? (
                      <strong>
                        <FormattedMessage {...utilsMessages[buyer?.businessCategory || '']} />
                      </strong>
                    ) : (
                      ''
                    )}
                  </span>
                ) : (
                  <span>
                    Best-selling in the&nbsp;
                    {buyer?.businessCategory ? (
                      <strong>
                        <FormattedMessage {...utilsMessages[buyer?.businessCategory || '']} />
                      </strong>
                    ) : (
                      ''
                    )}
                    &nbsp;business category
                  </span>
                )}
              </RecommendationSubTitle>
            </RecommendationWrapper>
          ) : (
            <SectionHeading id={showcase.title} level={2}>
              {'filter' in showcase && showcase.filter.categoryName
                ? categories.find((category) => category.name === showcase.filter.categoryName)?.nameLocal ||
                  showcase.title
                : showcase.title}
            </SectionHeading>
          )}
          <Link
            to={{
              pathname: seeAllUrl,
              state: {
                route: 'HomeScreenWidget',
                state: {
                  data: section.data,
                },
              },
            }}
          >
            {isRecommendation ? (
              <DiscoverMore>
                <FormattedMessage {...utilsMessages.discoverMore} />
              </DiscoverMore>
            ) : (
              <FormattedMessage {...utilsMessages.seeAll} />
            )}
          </Link>
        </SectionTitle>
        <List>
          <SwiperElement
            slidesPerView={'auto'}
            spaceBetween={16}
            navigation={true}
            modules={[Navigation, Mousewheel]}
            mousewheel={{ forceToAxis: true }}
            simulateTouch={true}
            className="mySwiper"
            slidesPerGroup={2}
          >
            {/* <List numberOfColumn={isShowBeside && numberOfColumn}> */}
            {(section?.data as Product[]).map((product: Product, index: number) => (
              <SwiperSlideElement key={product?.id}>
                <Item>
                  <MarketItem
                    vertialOnly
                    listName="Section Product List"
                    loggedIn={loggedIn}
                    gotoSignIn={gotoSignIn}
                    product={product}
                    loading={loading[showcaseId]}
                    favorite={product.isFavorite}
                    note={product.note}
                    added={addedProducts[product.id]}
                    adding={addingProducts[product.id]}
                    position={index}
                    permissions={permissions}
                    onToggleFavorite={() => onToggleFavorite({ product: product, index: index, section: showcaseId })}
                    onOpenNote={() => onClickOpenNoteModal(product, index)}
                    onDeleteNote={() => {
                      onSetProductNote({ index: index, section: showcaseId, note: '', id: product.id });
                      onClickCloseNoteModal();
                    }}
                    impressionProperties={{
                      type: trackingFrom?.productImpression,
                      tag:
                        trackingFrom?.productImpression === TrackingProductImpressionType.HomeTag
                          ? showcaseId
                          : undefined,
                    }}
                    addToCartProperties={{
                      addFrom: trackingFrom?.addToCart,
                      tag: trackingFrom?.addToCart === TrackingAddToCartAddFromType.HomeTag ? showcaseId : undefined,
                    }}
                  />
                </Item>
              </SwiperSlideElement>
            ))}
            {/* </List> */}
          </SwiperElement>
        </List>
        <ModalNote
          headerLabel={(selectedProduct && selectedProduct.name) || ''}
          isOpen={isOpenNoteModal}
          confirmLoading={noteItemLoading}
          onSubmit={onSubmitAddingNote}
          onClose={onClickCloseNoteModal}
          note={(selectedProduct && selectedProduct.note) || ''}
        />
      </WidgetWrapper>
      {showcaseId === widgets[firstWidgetIndex].widgetId && (
        <MightFavoriteProductsWidget isShowBeside showcaseId={'MIGHT_FAVORITE_PRODUCTS'} />
      )}
    </Container>
  ) : null;
};

const mapStateToProps = createStructuredSelector<ApplicationRootState, IStateProps>({
  categories: selectCategories(),
  sections: selectSections(),
  loading: selectLoading(),
  addedProducts: selectAddedProducts(),
  addingProducts: selectAddingProducts(),
  permissions: selectUserPermissions(),
  noteItemLoading: selectNoteItemLoading(),
  buyer: selectBuyer(),
  widgets: selectWidgets(),
});

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => ({
  onToggleFavorite: (data: IHomeFavoritePayload) => dispatch(setHomeFavirote(data)),
  onSetProductNote: (data: IHomeProductNotePayload) => dispatch(setHomeProductNote(data)),
  gotoSignIn: () => dispatch(push('/signin')),
});

const withConnect = connect<IStateProps, IDispatchProps, IOwnProps>(mapStateToProps, mapDispatchToProps);

export default withConnect(HomeScreenWidget);
