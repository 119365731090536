import {
  Map,
  AdvancedMarker,
  MapCameraProps,
  useAdvancedMarkerRef,
  MapProps,
  MapMouseEvent,
  ControlPosition,
  MapControl,
} from '@vis.gl/react-google-maps';
import { useGoogleMapsContext } from 'containers/LandingPage/DistributionLocationPage/components/Map/hooks/GoogleMapScriptContext';
import React, { CSSProperties, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Alert } from 'antd';
import AlertOn from '../images/AlertOn.svg';
import AddressPin from '../images/AddressPin.svg';
import QuestionPin from '../images/QuestionPin.svg';
import ErrorExclamation from '../images/ErrorExclamation.svg';
import styled from 'styles/styled-components';
import SVGIcon from 'components/SVGIcon/SVGIcon';
import messages from '../messages';
import translation from 'translations';
import Button from 'components/Button/Button';
import MapIcon from '../images/MapIcon.svg';
import { debounce } from 'lodash';
import { FormattedMessage } from 'react-intl';
import utilsMessages from 'utils/messages';

const OpenExtendedMapButton = styled(Button)`
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-left: 16px;

  &.ant-btn.ant-btn-lg {
    padding: 8px 12px 8px 10px;
    border-radius: 6px;
    color: #475467;
    text-align: center;
    font-family: 'Maven Pro';
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
`;

const MapOptionIcon = styled(SVGIcon)`
  ${OpenExtendedMapButton}:hover & path {
    fill: #43a047;
  }
`;

const WarningMessage = styled(Alert)`
  padding: 12px 24px 12px 36px;
  top: 0;
  width: 100%;
  background: #ffff;
  border-radius: 8px 8px 0 0;
  gap: 8px;
  border: none;
  color: #e56910;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  &.ant-alert-with-description .ant-alert-icon {
    margin: 0;
  }
`;

const ErrorMessage = styled(WarningMessage)`
  background: #ffecea;
  &.ant-alert.ant-alert-with-description .ant-alert-content {
    .ant-alert-message {
      color: #e2483c;
    }
  }
`;

const AddressPinInfo = styled.div`
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 20px;
  background: #43a047;
  color: #fff;
  font-family: 'Maven Pro';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

interface Props {
  center: google.maps.LatLngLiteral | null;
  setCenter: React.Dispatch<React.SetStateAction<google.maps.LatLngLiteral | null>>;
  setPlaceId: React.Dispatch<React.SetStateAction<string>>;
  mapOptions: MapProps;
  setOpenExtendedMap: React.Dispatch<React.SetStateAction<boolean>>;
  currentRegion: string;
  setIsOnDrag: React.Dispatch<React.SetStateAction<boolean>>;
  isOutOfRegion?: boolean;
  setIsOutOfRegion?: React.Dispatch<React.SetStateAction<boolean>>;
  // handleOnClickOnMap: (event: MapMouseEvent) => void;
  extraCameraProps?: Partial<MapCameraProps>;
  styles?: CSSProperties;
  hideMarker?: boolean;
  openExtendedMap?: boolean;
}

const StoreMap: React.ForwardRefRenderFunction<any, Props> = (
  {
    center,
    setCenter,
    setPlaceId,
    mapOptions,
    setOpenExtendedMap,
    currentRegion,
    setIsOnDrag,
    isOutOfRegion,
    setIsOutOfRegion,
    hideMarker,
    openExtendedMap,
    styles = {},
  },
  ref,
) => {
  const { handleCameraChange, cameraProps } = useGoogleMapsContext();
  const [markerRef, marker] = useAdvancedMarkerRef();
  const [localCenter, setLocalCenter] = useState(center);
  const [isDragging, setIsDragging] = useState(false);
  const isOnStill = center && !isDragging;
  useImperativeHandle(ref, () => ({
    marker: marker,
  }));

  useEffect(() => {
    if (center) {
      setLocalCenter(center);
    }
  }, [center?.lat, center?.lng]);

  const handleMapDragStart = () => {
    setLocalCenter(null);
    setIsDragging(true);
  };

  const handleMapDragEnd = () => {
    setIsDragging(false);
  };

  const debounceSetCenter = debounce((newCenter: google.maps.LatLngLiteral) => {
    setCenter(newCenter);
  }, 300);

  const handleMarkerDrag = (e: google.maps.MapMouseEvent) => {
    if (e.latLng) {
      const newCenter = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      setLocalCenter(newCenter);
      debounceSetCenter(newCenter);
      if (setIsOutOfRegion) setIsOutOfRegion(false);
      setPlaceId('');
      setIsOnDrag(true);
    }
  };

  return (
    <>
      {isOutOfRegion && (
        <WarningMessage
          message={
            <FormattedMessage
              {...messages.warningTitle}
              values={{ region: translation(utilsMessages[currentRegion || '']).toUpperCase() }}
            />
          }
          type="warning"
          // icon={
          //   <SVGIcon
          //     src={AlertOn}
          //     alt="Warning"
          //     style={{
          //       padding: 4,
          //       borderRadius: 16,
          //       background: 'white',
          //     }}
          //   />
          // }
        />
      )}
      <Map
        reuseMaps
        defaultCenter={cameraProps.center}
        defaultZoom={cameraProps.zoom}
        center={localCenter}
        onCameraChanged={handleCameraChange}
        mapId={process.env.GOOGLE_MAP_ID}
        style={styles}
        onDragstart={handleMapDragStart}
        onDragend={handleMapDragEnd}
        {...mapOptions}
      >
        {!openExtendedMap && (
          <MapControl position={ControlPosition.TOP_LEFT}>
            <OpenExtendedMapButton
              icon={<MapOptionIcon renderAsImg={false} src={MapIcon} />}
              onClick={() => setOpenExtendedMap(true)}
              disabled={openExtendedMap}
            >
              {translation(messages.openExtendedMap)}
            </OpenExtendedMapButton>
          </MapControl>
        )}
        {!hideMarker && (
          <AdvancedMarker
            position={localCenter || center}
            title="Ghim địa chỉ trên bản đồ"
            ref={markerRef}
            draggable={openExtendedMap}
            onDragEnd={handleMarkerDrag}
          >
            {isOnStill && <AddressPinInfo>{translation(messages.addressPinInfo)}</AddressPinInfo>}
            <SVGIcon src={isOnStill ? AddressPin : QuestionPin} width={40} height={47} />
          </AdvancedMarker>
        )}
      </Map>
    </>
  );
};

export default forwardRef(StoreMap);
