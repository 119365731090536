import gql from 'graphql-tag';

export default gql`
  query product($id: ID!, $regionCode: String) {
    product(id: $id, regionCode: $regionCode) {
      id
      supplier {
        id
        name
        private
      }
      supplierInternalCode
      imageUrl
      note
      origin
      preservation
      preservationLocal
      vat
      uomLocal
      uom
      price
      originalPrice
      category {
        id
        name
        nameLocal
        parent {
          id
          name
          nameLocal
        }
      }
      name
      nameLocal {
        en
        vi
      }
      description
      isFavorite
      inStock
      tags
      details
      allowAddToCart
      private
      allowPurchase
      brand {
        id
        slug
        name
      }
      medias {
        type
        url
        name
        isPrimary
      }
    }
  }
`;
