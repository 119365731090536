import { convertStringToSlug } from 'utils/generateProductSlug';

export type FormFieldNameFullText = 'addressDetail' | 'address' | 'geoLat' | 'geoLon' | 'geoPlusCode';

const cityPrefix = ['thanh pho', 'thành phố', 'tinh', 'tỉnh', 'city', 'province', 'tp.', 'tp', 't.', 't '];
const districtPrefix = [
  'thanh pho',
  'thành phố',
  'city',
  'quan',
  'district',
  'huyen',
  'thị xã',
  'thi xa',
  'town',
  'tp.',
  'tp ',
  'q.',
  'q ',
  'h.',
  'h ',
  'tx.',
  'tx ',
];
const wardPrefix = [
  'phuong',
  'ward',
  'xa',
  'commune',
  'thi tran',
  'thị trấn',
  'p.',
  'p ',
  'x.',
  'x ',
  't.t',
  'tt.',
  'tt ',
];

export const generateRawText = (location: string) => {
  return location.replace(new RegExp([...cityPrefix, ...districtPrefix, ...wardPrefix].join('|'), 'gi'), '').trim();
};

const createBoundaryRegex = (prefixes: string[]) => {
  return new RegExp(`\\b(${prefixes.join('|')})\\b`, 'gi');
};
export const refinedLocationPronounce = (location: string, type: string) => {
  if (type === 'province') {
    return location.replace(createBoundaryRegex(cityPrefix), '').trim();
  }
  if (type === 'district') {
    return location.replace(createBoundaryRegex(districtPrefix), '').trim();
  }
  if (type === 'ward') {
    return location.replace(createBoundaryRegex(wardPrefix), '').trim();
  }
  return location;
};

export function isMatchAddress(value: string, target: string, type: string, isFullText = false) {
  if (!value || !target) {
    return false;
  }
  const blindValue = convertStringToSlug(value, true);
  const blindTarget = convertStringToSlug(target, true);

  if (blindValue === blindTarget) {
    return true;
  }
  // Remove leading zero
  const zeroLeadingRegex = /\b0(\d)\b/;
  const valueWithoutLeadingZero = blindValue.replace(zeroLeadingRegex, (match) => match[1]);
  const targetWithoutLeadingZero = blindTarget.replace(zeroLeadingRegex, (match) => match[1]);

  if (isFullText) {
    return valueWithoutLeadingZero.includes(targetWithoutLeadingZero);
  }
  return (
    refinedLocationPronounce(valueWithoutLeadingZero, type) === refinedLocationPronounce(targetWithoutLeadingZero, type)
  );
}

export function isLocationMatchingWithAddressDetail(location: string, addressParts: string[], key: string) {
  return addressParts.some((field) => {
    const regex = new RegExp(`^${field}(\\s+\\d+)?$`, 'i');
    const isMatch = regex.test(location);
    if (isMatch || isMatchAddress(location, field, key, false)) {
      return true;
    }
    return false;
  });
}

export function handleDuplicateAddressField(item: any) {
  const { province, district, ward, country, /* surroundDistrict, surroundWard, */ label } = item;
  const duplicationField = { province: province, district: district, ward: ward, country: country };
  const addressParts = label.split(',').map((part: string) => part.trim());
  const filteredParts = addressParts.filter((part: string) => {
    return !Object.entries(duplicationField).some(([key, field]) => {
      const regex = new RegExp(`^${key === 'country' ? `${field}|Việt Nam|Vietnam` : field}(\\s+\\d+)?$`, 'i');
      const isMatch = regex.test(part);
      if (isMatch || isMatchAddress(part, field, key, false)) {
        return true;
      }
      return false;
    });
  })/* .filter((part: string) => ![...surroundDistrict, ...surroundWard].some((surround: string) => {
    const regex = new RegExp(`^${surround}(\\s+\\d+)?(,)?`, 'i');
    return regex.test(part);
  })); */
  return filteredParts.join(', ').trim();
}

export function constructFullAddress(location: any) {
  const { address, city, district, ward } = location;
  return `${address}, ${ward ?? ''}, ${district ?? ''}, ${city ?? ''}`;
}