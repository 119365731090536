import React, { useMemo } from 'react';
import useCovertAddrToCoords from './useConvertAddrToCoords';

interface UseAddressToCoordsProps {
  addressValue: string | null;
  setAutoSuggestions: React.Dispatch<React.SetStateAction<any[]>>;
  setCoords: React.Dispatch<React.SetStateAction<google.maps.LatLngLiteral | null>>;
  setAddressComponents: React.Dispatch<React.SetStateAction<any>>;
  setIsFetchingSuggestions?: React.Dispatch<React.SetStateAction<boolean>>;
  type?: 'autocomplete' | 'nearby';
  coords?: google.maps.LatLngLiteral | null;
  placeId?: string;
  isOnDrag?: boolean;
  hideMarker?: boolean;
}

const useAddressToCoords = ({
  addressValue,
  setAutoSuggestions,
  setCoords,
  setAddressComponents,
  setIsFetchingSuggestions,
  type = 'autocomplete',
  coords,
  placeId,
  isOnDrag,
  hideMarker = false,
}: UseAddressToCoordsProps) => {
  const location = hideMarker ? null : addressValue;
  const memoizedSuggestionOption = useMemo(
    () => ({
      setAutoSuggestions: setAutoSuggestions,
      setAddress: setAddressComponents,
      setCoords: setCoords,
      setIsFetchingSuggestions: setIsFetchingSuggestions,
      coords: coords,
      placeId: placeId,
      isOnDrag: isOnDrag,
    }),
    [setAutoSuggestions, setAddressComponents, setCoords, placeId, coords?.lat, coords?.lng, isOnDrag],
  );
  useCovertAddrToCoords(location, memoizedSuggestionOption, type);
};

export default useAddressToCoords;
