import gql from "graphql-tag";

export default gql`
 query brandsForHome(
  $brandIds: [Int!]
  $categoryIds: [Int!],
  $pagination: Pagination!
  ) { 
    brandsForHome(
    brandIds: $brandIds
    categoryIds: $categoryIds
    pagination: $pagination
  ) {
    data {
        categories {
            key
            productBrands {
              id
              slug
              name
              imageUrl
          }
        }
    }
  }
}
`;