import gql from 'graphql-tag';

export default gql`
  query getAllOrigins {
    getAllOrigins {
      data {
        name
        nameVn
      }
    }
  }
`;
