import { message } from 'antd';
import { fork, put, take, call, select } from 'redux-saga/effects';
import ActionTypes from './constants';
import { addStore, updateStore, getFullAddress, handleCheckStoreIsDuplicate } from 'utils/apollo';
import { handleStore } from './action';
import { refetchBuyer, setIsShowRecommendationProductsPopup } from 'containers/MainLayout/actions';
import translations from 'translations';
import utilsMessages from 'utils/messages';
import { AccountBuyerStatus, FindFullAddressResponse } from '../../types/schema';
import { selectRecommendationProductsForNewCustomer } from 'containers/MainLayout/selectors';

function* createStoreFlow(payload) {
  const { buyer, ...rest } = payload;
  const isDuplicateStore: any = yield call(handleCheckStoreIsDuplicate, {
    input: { name: rest.name, location: rest.location },
  });
  if (!isDuplicateStore.errors) {
    if (isDuplicateStore.isDuplicate) {
      message.error(translations(utilsMessages.duplicateStoreError));
      yield put(handleStore.failure({} as any));
      return;
    }
    const response = yield call(addStore, { input: rest });
    if (!response.errors) {
      yield put(handleStore.success(response));
      yield put(refetchBuyer());
      message.success(translations(utilsMessages.createStoreSuccessfully));
      const recommendationProducts = yield select(selectRecommendationProductsForNewCustomer());
      if (buyer?.accountBuyerStatus === AccountBuyerStatus.NotActivated && recommendationProducts.length) {
        yield put(setIsShowRecommendationProductsPopup(true));
      }
    } else {
      yield put(handleStore.failure(response.errors));
      message.error(translations(utilsMessages.createStoreFailure));
    }
  }
}

function* updateStoreFlow(payload) {
  const response = yield call(updateStore, { input: payload });
  if (!response.errors) {
    yield put(handleStore.success(response));
    yield put(refetchBuyer());
    message.success(translations(utilsMessages.updateStoreSuccessfully));
  } else {
    yield put(handleStore.failure(response.errors));
    message.error(translations(utilsMessages.updateStoreFailure));
  }
}

function* handleStoreFlow() {
  while (true) {
    const { payload } = yield take(ActionTypes.HANDLE_STORE_REQUEST);
    const { data, isUpdate } = payload;
    if (isUpdate) {
      yield call(updateStoreFlow, data);
    } else {
      yield call(createStoreFlow, data);
    }
  }
}

function* findFullAddress() {
  const { payload } = yield take(ActionTypes.FIND_FULL_ADDRESS_REQUEST);
  const { district, ward } = payload;
  const response = yield call(getFullAddress, { districtCodes: [district], wardCodes: [ward] });
  if (!response.errors) {
    if (payload.cb) {
      payload.cb({ fullAddress: response.data[0].value as FindFullAddressResponse });
    }
  } else {
    if (payload.cb) {
      payload.cb({ fullAddress: null });
    }
  }
}

export default function* createStorePopupSaga() {
  yield fork(findFullAddress);
  yield fork(handleStoreFlow);
}
