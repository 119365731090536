import gql from 'graphql-tag'

export default gql`
  mutation createNewProductRequest($input: CreateNewProductRequestInput!) {
    createNewProductRequest(input: $input) {
      success
      userErrors {
        field
        message
      }
    }
  }
`