import React, { FC, useRef, useEffect, useMemo } from 'react';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import commingSoon from './comingSoon.png';

import styled from 'styled-components';
import SVGIcon from 'components/SVGIcon/SVGIcon';
import subBackground from './subBackground.svg';
import mobileSubBackground from './mobileSubBackground.svg';

import translations from 'translations';
import messages from '../../utils/messages';
import { Viewport } from 'components/ViewportProvider';
import datlatVeggies from './datlatVeggies.svg';
import combo from './combo.svg';
import gyomuSuper from './gyomuSuper.png';
import kamereoLabelImg from './kamereoLabelImg.png';
import media from 'utils/mediaStyle';
import { FormattedMessage } from 'react-intl';
import GyomuMessages from '../../containers/LandingPage/GyomuSuper/messages';
import { selectBuyer, selectLang } from 'containers/MainLayout/selectors';

import './tooltip.css';
import { ApplicationRootState } from 'types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Buyer } from 'types/schema';
import { Tooltip } from 'antd';

interface IState {
  lang: string;
  buyer?: Buyer;
}

interface IProps extends IState, RouteComponentProps {}

interface ISection {
  id: number;
  img?: number;
  svgImg?: any;
  backgroundColor: {
    main: string;
    sub: string;
  };
  title: {
    main: string;
    sub?: string;
  };
  imgStyle?: {
    right?: number;
    bottom?: number;
  };
  tag?: string;
  pageUrl?: string;
  dynamic?: boolean;
  disable?: boolean;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 10pt;
  overflow: auto;
  min-height: 44px;
  @media (max-width: 1023px) {
    padding-bottom: 12px;
  }
`;

const TagItem = styled(Link)`
  position: relative;
  ${({ backgroundcolor }) => `
    background: ${backgroundcolor}
    `}
  @media(min-width: 992px) {
    height: 36px;
    min-width: 228px;
    flex-shrink: 0;
  }
  color: rgba(0, 0, 0, 0.87);
  height: 80px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex: 1;
  justify-content: space-between;
  min-width: 152px;
  cursor: pointer;
  &:not(:first-child) {
    align-items: center;
    @media (max-width: 991px) {
      align-items: unset;
    }
  }
  ${({ dynamic }) =>
    dynamic &&
    `
  ${TitleBox} {
    flex-direction: column;
    justify-content: unset;
    align-items: flex-start;
    ${media.lg`
      justify-content: center;
    `}
    ${SubTitle} {
      color: #808289;
      font-size: 14px;
      font-weight: 300;
    }
    ${SubTitleDynamic} {
      font-size: 14px;
      font-weight: 400;
      &:nth-child(2) {
        animation: fadeText 4s infinite;
      }
      &:nth-child(3) {
        animation: fadeText 4s infinite;
        animation-delay: 1.3s;
        opacity: 0;
      }
      &:nth-child(4) {
        animation: fadeText 4s infinite;
        animation-delay: 2.7s;
        opacity: 0;
      }
    }
  }
  `}
`;

const TagItemNorthern = styled.div`
  position: relative;
  ${({ backgroundcolor }) => `
    background: ${backgroundcolor}
    `}
  @media(min-width: 992px) {
    height: 36px;
    min-width: 228px;
    flex-shrink: 0;
  }
  height: 80px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex: 1;
  justify-content: space-between;
  min-width: 152px;
  cursor: pointer;
  ${({ disable }) =>
    disable &&
    `
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    opacity: 0.85;
    background: rgba(255, 255, 255, 0.6);
    pointer-events: none;
  } 
  cursor: default;
     

 ${TitleBoxNorthern} {
    flex-direction: column;
    justify-content: unset;
    align-items: flex-start;
    ${media.lg`
      justify-content: center;
    `}
    ${SubTitle} {
      color: #808289;
      font-size: 14px;
      font-weight: 400;
    }
  }
`}
`;
const TitleBox = styled.div`
  padding: 0 0 0 8px;
  z-index: 10;
  display: flex;
  align-items: center;
  width: 100%;
  &:first-child {
    margin-top: 3px;
  }
  @media (max-width: 991px) {
    position: relative;
    width: 126px;
    padding: 8px 0 0 8px;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 0px;
  }
`;

const TitleBoxNorthern = styled.div`
  padding: 0 0 0 8px;
  z-index: 10;
  display: flex;
  align-items: center;
  width: 100%;
  @media (max-width: 991px) {
    position: relative;
    width: 126px;
    padding: 8px 0 0 8px;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 0px;
  }
`;

const MainTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
`;
const SubTitle = styled.div`
  font-size: 12px;
  line-height: 12px;
  margin-top: 4px;
`;
const SubTitleDynamic = styled.div`
  position: absolute;
  top: 27px;
  font-size: 12px;
  line-height: 12px;
  margin-top: 4px;
  ${media.lg`
    top: 17px;
  `}
`;

const SubBackground = styled(SVGIcon)`
  margin-left: auto;
  @media (max-width: 991px) {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-left: unset;
  }
  ${({ backgroundcolor }) => `
    rect {
      fill: ${backgroundcolor}
    }
  `}
`;
const ImageWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 15px;
  display: flex;
  @media (max-width: 991px) {
    ${({ right }) => `
      right: ${right ?? 15}px;
    `}
  }
  ${({ bottom }) => bottom && `bottom: ${bottom}px`}
`;
const Image = styled.img`
  width: 60px;
`;
const SvgImage = styled(SVGIcon)``;

const CommingSoonPopup = styled.div`
  width: 380px;
  padding: 12px;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
`;
const CommingSoonImage = styled.img`
  width: 40px;
  height: 40px;
`;

const CommingSoonTextbox = styled.div`
  color: #242527;
  font-weight: 400;
  line-height: 16px;
  & > div:first-child {
    font-weight: 500;
    margin-bottom: 2px;
  }
`;
const CommingSoonText = styled.div``;

const TagNavBar: FC<IProps> = ({ history, lang, buyer }) => {
  const isLogin = window.localStorage.getItem('token');
  const regionLocal = localStorage.getItem('region');

  const dynamicText = useRef('San pham moi');
  const sectionData: ISection[] = useMemo(() => {
    const sections: ISection[] = [
      {
        id: 2,
        img: kamereoLabelImg,
        backgroundColor: {
          main: '#E9F2FF',
          sub: '#CCE0FF',
        },
        title: {
          main: translations(messages.kamereoBrand),
          sub: translations(messages.kamereoBrandSubText),
        },
        imgStyle: {
          right: 5,
        },
        pageUrl: lang === 'vi' ? 'thuong-hieu-kamereo' : `thuong-hieu-kamereo?lang=${lang}`,
      },
      {
        id: 3,
        svgImg: combo,
        backgroundColor: {
          main: '#FFECEA',
          sub: '#FFD5D2',
        },
        title: {
          main: translations(messages.combo),
          sub: translations(messages.comboSubText),
        },
        tag: 'SUPER_COMBO',
        imgStyle: {
          right: -24,
        },
      },
      {
        id: 4,
        svgImg: datlatVeggies,
        backgroundColor: {
          main: '#EFFFD6',
          sub: '#D3F1A7',
        },
        title: {
          main: translations(messages.dalatVeggies),
          sub: translations(messages.dalatVeggiesSubText),
        },
        tag: 'RAU_CU_QUA_DA_LAT',
        imgStyle: {
          right: 5,
        },
      },
    ];
    if ((isLogin && buyer?.region === 'HCM') || (!isLogin && regionLocal === 'HCM')) {
      sections.unshift({
        id: 1,
        img: gyomuSuper,
        backgroundColor: {
          main: '#E7F9FF',
          sub: '#C6EDFB',
        },
        title: {
          main: translations(messages.gyomuSuper),
          sub: translations(messages.commingSoon),
        },
        imgStyle: {
          right: -20,
          bottom: 12,
        },
        pageUrl: lang === 'vi' ? 'nguyen-lieu-nhat-ban-gyomu-japan' : `nguyen-lieu-nhat-ban-gyomu-japan?lang=${lang}`,
        dynamic: true,
      });
    } else {
      sections.push({
        id: 1,
        img: gyomuSuper,
        backgroundColor: {
          main: '#E7F9FF',
          sub: '#C6EDFB',
        },
        title: {
          main: translations(messages.gyomuSuper),
          sub: translations(messages.commingSoon),
        },
        imgStyle: {
          right: -20,
          bottom: 12,
        },
        tag: 'GYOMU_SUPER',
        disable: true,
      });
    }
    return sections;
  }, [lang, isLogin, regionLocal, buyer]);
  useEffect(() => {
    setInterval(() => {
      dynamicText.current = 'tesst';
    }, 1800);
  }, []);
  return (
    <Container className="tags-nav-bar-container">
      <Viewport.Consumer>
        {({ width }) => {
          const notDesktop = width < 992;
          return (isLogin && buyer?.region === 'HCM') || (!isLogin && regionLocal === 'HCM')
            ? sectionData.map((item, index) => (
                <TagItem
                  key={index}
                  backgroundcolor={item.backgroundColor.main}
                  // onClick={() => history.push(`/${item.tag ? item.tag : item.pageUrl}`)}
                  dynamic={item?.dynamic}
                  to={`/${item.tag ? item.tag : item.pageUrl}`}
                >
                  <TitleBox>
                    <MainTitle>{item.title.main}</MainTitle>
                    {item.id === 1 ? (
                      <>
                        <SubTitleDynamic>
                          <FormattedMessage {...GyomuMessages.discoverNow} />
                        </SubTitleDynamic>
                        <SubTitleDynamic>
                          <FormattedMessage {...GyomuMessages.qualityProducts} />
                        </SubTitleDynamic>
                        <SubTitleDynamic>
                          <FormattedMessage {...GyomuMessages.from} />
                          <span style={{ color: '#1E7AFD', fontWeight: '500', marginLeft: '4px' }}>
                            <FormattedMessage {...GyomuMessages.japan} />
                          </span>
                        </SubTitleDynamic>
                      </>
                    ) : (
                      notDesktop && <SubTitle>{item.title.sub}</SubTitle>
                    )}
                  </TitleBox>
                  <SubBackground
                    src={notDesktop ? mobileSubBackground : subBackground}
                    backgroundcolor={item.backgroundColor.sub}
                    renderAsImg={false}
                  />
                  <ImageWrapper right={item?.imgStyle?.right} bottom={item?.imgStyle?.bottom}>
                    {item.svgImg ? <SvgImage src={item.svgImg} /> : item.img && <Image src={item.img} />}
                  </ImageWrapper>
                </TagItem>
              ))
            : sectionData.map((item) => (
                <Tooltip
                  key={item.id}
                  placement="bottomRight"
                  title={
                    !!item.disable && (
                      <CommingSoonPopup>
                        <CommingSoonImage src={commingSoon} />
                        <CommingSoonTextbox>
                          <CommingSoonText>{translations(messages.commingSoon)}!</CommingSoonText>
                          <CommingSoonText>{translations(messages.commingSoonMessage)}</CommingSoonText>
                        </CommingSoonTextbox>
                      </CommingSoonPopup>
                    )
                  }
                  overlayClassName={item.disable ? 'coming-soon-tooltip-container' : ''}
                >
                  <TagItemNorthern
                    backgroundcolor={item.backgroundColor.main}
                    onClick={() => !item?.disable && history.push(`/${item.tag ? item.tag : item.pageUrl}`)}
                    disable={item?.disable}
                  >
                    <TitleBoxNorthern>
                      <MainTitle>{item.title.main}</MainTitle>
                      {(item.id === 1 || notDesktop) && <SubTitle>{item.title.sub}</SubTitle>}
                    </TitleBoxNorthern>
                    <SubBackground
                      src={notDesktop ? mobileSubBackground : subBackground}
                      backgroundcolor={item.backgroundColor.sub}
                      renderAsImg={false}
                    />
                    <ImageWrapper right={item?.imgStyle?.right} bottom={item?.imgStyle?.bottom}>
                      {item.svgImg ? <SvgImage src={item.svgImg} /> : item.img && <Image src={item.img} />}
                    </ImageWrapper>
                  </TagItemNorthern>
                </Tooltip>
              ));
        }}
      </Viewport.Consumer>
    </Container>
  );
};

const mapStateToProps = createStructuredSelector<ApplicationRootState, IState>({
  lang: selectLang(),
  buyer: selectBuyer(),
});

const withConnect = connect(mapStateToProps);

export default compose(withRouter, withConnect)(TagNavBar);
