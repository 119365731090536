import gql from 'graphql-tag'
export default gql` query FindFullAddress($wardCodes: [String], $districtCodes: [String]) {
    findFullAddress(wardCodes: $wardCodes, districtCodes: $districtCodes) {
        data {
            key
            value {
              provinceCode
              districtCode
              wardCode
            }
        }
    }
}

`