import { Col, Modal, Row } from 'antd';
import MarketItem from 'components/MarketItem/MarketItem';
import SVGIcon from 'components/SVGIcon/SVGIcon';
import {
  setFavorite,
  setIsConfirmLocation,
  setIsShowRecommendationProductsPopup,
  setProductNote,
} from 'containers/MainLayout/actions';
import {
  selectAddedProducts,
  selectAddingProducts,
  selectCart,
  selectIsConfirmLocation,
  selectRecommendationProductsForNewCustomer,
  selectUserPermissions,
} from 'containers/MainLayout/selectors';
import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styles/styled-components';
import { ApplicationRootState } from 'types';
import { Buyer } from 'types/schema';
import media from 'utils/mediaStyle';
import utilsMessages from 'utils/messages';
import BackgroundImage from './backgroundImage.png';
import CartIcon from './Cart.svg';
import CloseIcon from './CloseIcon.svg';
import messages from '../../containers/HomePage/messages';

const ModalContainer = styled(Modal)`
  overflow: auto;
  background: #ffffff;
  border-radius: 8px;
  &.ant-modal {
    .ant-modal-content {
      padding: 0;
      height: 100%;
      box-shadow: none;
      .ant-modal-close {
        display: none;
      }
    }
  }
`;

const TopBackground = styled.div`
  position: relative;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: #d3f1a7;
  height: 176px;
  @media (max-width: 767px) {
    height: 130px;
  }
`;

const TopBackgroundImage = styled.img`
  width: 100%;
  max-width: 400px;
  position: absolute;
  bottom: 0;
  right: 176px;
  @media (max-width: 1023px) {
    right: 10px;
    max-width: 300px;
  }
  @media (max-width: 767px) {
    right: 10px;
    max-width: 180px;
  }
`;

const TopBackgroundText = styled.div`
  font-size: 40px;
  font-weight: 600;
  width: 330px;
  line-height: 48px;
  position: absolute;
  top: 50%;
  left: 50px;
  color: #37471F;
  transform: translateY(-50%);
  @media (max-width: 1023px) {
    font-size: 35px;
    line-height: 35px;
  }
  @media (max-width: 767px) {
    font-size: 22px;
    line-height: 22px;
    left: 10px;
    width: 210px;
  }
`;

const ProductListRow = styled(Row)`
  padding: 0px 8px 20px;
  background: #ffffff;
  margin: 4px 0 !important;
  &.ant-row {import { utilsMessages } from 'utils/messages';

    display: flex;
    flex-wrap: wrap;
  }
  > .ant-col {
    &:not(:last-child) {
      border-bottom: 1px solid #dce0e9;
      ${media.md`
        border-bottom: none;
      `};
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  @media (min-width: 1200px) {
    .ant-col-xl-5 {
      width: 20%;
    }
  }
`;
const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  position: sticky;
  top: 0;
  padding: 20px 20px;
  z-index: 100;
`;
const Title = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: #242527;
  @media (max-width: 767px) {
    font-size: 16px;
  }
`;
const SubTitle = styled.div`
  font-size: 14px;
  line-height: 14px;
  @media (max-width: 767px) {
    font-size: 12px;
  }
`;
const TextBox = styled.div``;

const ViewCartButton = styled.div`
  background: #43a047;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  border-radius: 30px;
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  gap: 4px;
  cursor: pointer;
`;
const CloseIconWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 20px
  width: 36px;
  height: 36px;
  background: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

interface IStateProps {
  isConfirmLocation: boolean;
  recommendationProductsForNewCustomer: any[];
  addedProducts: any;
  addingProducts: any;
  cart: any;
  permissions: any[];
}

interface IDispatchProps {
  setIsConfirmLocation: (data: any) => void;
  onToggleFavorite: (data: any) => void;
  hideRecommendationPopup: () => void;
}

interface Props extends IStateProps, IDispatchProps, RouteComponentProps {
  buyer: any;
}

const RecommendationProductsPopup: React.FC<Props> = ({
  buyer,
  history,
  addedProducts,
  addingProducts,
  recommendationProductsForNewCustomer,
  onToggleFavorite,
  permissions,
  cart,
  hideRecommendationPopup,
}) => {
  const loggedIn = !!localStorage.getItem('token');
  const [open, setOpen] = useState(true);
  const currentLang = window.localStorage.getItem('lang') || 'vi';
  const isShowCartButton = useMemo(() => {
    return cart?.cartSet?.[0]?.items?.length > 0;
  }, [cart]);

  const onClickTogglingFavorite = useCallback(
    (product) => {
      onToggleFavorite({ product: product });
    },
    [onToggleFavorite],
  );

  return (
    <ModalContainer
      title={null}
      open={open}
      footer={null}
      closable={true}
      destroyOnClose
      centered
      width={1096}
      height={688}
      onCancel={() => {
        hideRecommendationPopup();
        setOpen(false);
      }}
    >
      <TopBackground>
        <CloseIconWrapper
          onClick={() => {
            hideRecommendationPopup();
            setOpen(false);
          }}
        >
          <SVGIcon src={CloseIcon} />
        </CloseIconWrapper>
        <TopBackgroundText>
          <FormattedMessage {...messages.welcomeToKamereo} />
        </TopBackgroundText>
        <TopBackgroundImage src={BackgroundImage} />
      </TopBackground>
      <TitleWrapper>
        <TextBox>
          <Title>
            <FormattedMessage {...messages.recommendationTitle} />
          </Title>
          <SubTitle>
            {currentLang === 'vi' ? (
              <span>
                Được mua nhiều nhất trong danh mục kinh doanh{' '}
                <strong>
                  {buyer?.businessCategory ? (
                    <FormattedMessage {...utilsMessages[buyer?.businessCategory || '']} />
                  ) : (
                    ''
                  )}
                </strong>
              </span>
            ) : (
              <span>
                Best-selling in the&nbsp;
                <strong>
                  {buyer?.businessCategory ? (
                    <FormattedMessage {...utilsMessages[buyer?.businessCategory || '']} />
                  ) : (
                    ''
                  )}
                </strong>
                &nbsp;business category
              </span>
            )}
          </SubTitle>
        </TextBox>
        {isShowCartButton && (
          <ViewCartButton
            onClick={() => {
              hideRecommendationPopup();
              setOpen(false);
              history.push('checkout/step/1');
            }}
          >
            <SVGIcon src={CartIcon} />
            <FormattedMessage {...messages.viewCart} />
          </ViewCartButton>
        )}
      </TitleWrapper>
      <ProductListRow gutter={[20, 20]}>
        {recommendationProductsForNewCustomer.map((product, index) => (
          <Col md={6} xl={4} key={product?.id}>
            <MarketItem
              listName="Market Product List"
              loggedIn={loggedIn}
              gotoSignIn={() => {}}
              product={product}
              loading={product?.loading}
              favorite={product?.isFavorite}
              note={product?.note}
              maxQuantity={100}
              added={addedProducts[product.id]}
              adding={addingProducts[product.id]}
              position={index}
              permissions={permissions}
              onToggleFavorite={() => onClickTogglingFavorite(product)}
              impressionProperties={{ type: {} as any }}
              addToCartProperties={{ addFrom: {} as any }}
              isHideNote={true}
              onOpenNote={() => {}}
              onDeleteNote={() => {}}
            />
          </Col>
        ))}
      </ProductListRow>
    </ModalContainer>
  );
};

const mapStateToProps = createStructuredSelector<ApplicationRootState, IStateProps>({
  isConfirmLocation: selectIsConfirmLocation(),
  recommendationProductsForNewCustomer: selectRecommendationProductsForNewCustomer(),
  addedProducts: selectAddedProducts(),
  addingProducts: selectAddingProducts(),
  cart: selectCart(),
  permissions: selectUserPermissions(),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setIsConfirmLocation: (data: boolean) => dispatch(setIsConfirmLocation(data)),
  onToggleFavorite: (data) => dispatch(setFavorite(data)),
  setProductNote: (data: any) => dispatch(setProductNote(data)),
  hideRecommendationPopup: () => dispatch(setIsShowRecommendationProductsPopup(false)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps)(RecommendationProductsPopup);

export default withRouter(withConnect);
