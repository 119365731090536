import React from 'react';
import { ProductBrand, ProductBrandsResponse } from '../../types/schema';
import { Flex, Image } from 'antd';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import messages from 'utils/messages';
import SVGIcon from 'components/SVGIcon/SVGIcon';
import noImage from 'components/Image/noIMG.svg';

const BrandWrapper = styled(Link)`
  flex-basis: 50%;
  max-height: 80px;
  max-width: 80px;
  border-radius: 6px;
  background-color: #fff;
`;

const BrandColumn = styled(Flex)`
  min-width: 226px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px;
  border-radius: 0px 8px 8px 0px;
  margin-bottom: auto;

  & > *:not(:last-child) {
    margin-top: -8px;
  }
`;
const Brand = styled(Image)`
  &.ant-image-img {
    display: block;
    object-fit: contain;
    border-radius: 6px;
  }
`;

const ViewAllBrands = styled(Link)`
  color: #43a047;
  text-align: right;
  font-family: 'Maven Pro';
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 8px;
  min-width: max-content;
`;

export interface BrandsByCategory extends Pick<ProductBrandsResponse, 'data'> {
  categoryId: number;
}

interface BrandByCategoryProps {
  brands?: BrandsByCategory;
}

const BrandByCategory: React.FC<BrandByCategoryProps> = ({ brands }) => {
  if (brands?.data?.length === 0) return null;
  return (
    <BrandColumn wrap gap={20}>
      {brands?.data?.map((brand: ProductBrand) => {
        // how to check if it a url or not
        const isUrl = brand?.imageUrl && (brand?.imageUrl.includes('http') || brand?.imageUrl.includes('static/media'));
        return (
          <BrandWrapper to={`/thuong-hieu/${brand.slug}-b${brand.id}`} key={brand.id}>
            {isUrl ? (
              <Brand preview={false} width={80} height={80} key={brand?.id} src={brand?.imageUrl || ''} />
            ) : (
              <SVGIcon src={noImage} width={80} height={80} />
            )}
          </BrandWrapper>
        );
      })}
      <ViewAllBrands to={`/thuong-hieu?categoryIds=${brands?.categoryId}`}>
        <FormattedMessage {...messages.viewAllBrands} />
      </ViewAllBrands>
    </BrandColumn>
  );
};

export default BrandByCategory;
