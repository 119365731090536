import gql from 'graphql-tag'

export default gql`
  query getFrequentlyBuyTogether(
    $supplierInternalCodes: [String]
    $size: Int
    $requiredItemsBaseCodes: [String]
    $regionCode: String!
  ) {
    getFrequentlyBuyTogether(
      supplierInternalCodes: $supplierInternalCodes
      size: $size
      requiredItemsBaseCodes: $requiredItemsBaseCodes
      regionCode: $regionCode
    ) {
      products {
        id
        imageUrl
        uomLocal
        uom
        price
        originalPrice
        inStock
        isFavorite
        name
        note
        origin
        preservation
        preservationLocal
        description
        supplierInternalCode
        supplier {
          id
        }
        tags
        category {
          name
          parent {
            name
          }
        }
        details
        brand {
          id
          slug
          name
        }
        medias {
          type
          url
          name
          isPrimary
        }
      }
    }
  }
`