import { Flex, Menu } from 'antd';
import { Viewport } from 'components/ViewportProvider';
import { ContainerState } from 'containers/MainLayout/types';
import React, { Component } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { Buyer, Category } from 'types/schema';
import getSelectedCategory from 'utils/getSelectedCategory';
import media from 'utils/mediaStyle';
import SVGIcon from '../SVGIcon/SVGIcon';
import { ErrorBoundary } from './ErrorBoundary';
import Icons from './iconsNewDesign';
import rightArrow from './rightArrow.svg';
import { STATIC_CATEGORY, tags } from './sidebarFilterData';
import { createSearchQueryfromSlug, getPathSegmentFromSlug } from 'utils/getPathSegmentFromSlug';
import { generateSlugFromID } from 'utils/urls/categoriesPathSegment';
import { pathnameChecker } from '../../utils/url';
import { FormattedMessage } from 'react-intl';
import utilsMessages from 'utils/messages';
import BrandByCategory, { BrandsByCategory } from 'components/BrandByCategory';

const Icon = styled(SVGIcon)`
  width: 22px;
  height: 22px;
  margin: auto;
`;

const Label = styled.div`
  text-align: left;
  font-size: 14px;
  flex: 1;
  font-weight: 400;
  ${media.lg`
    margin-left: 8px;
  `};
`;

const SubLabel = styled(Link)`
  white-space: nowrap;
  border-radius: 4px;
  transition: all 0.3s ease;
  background-color: white;
  font-weight: 400;
  &:hover,
  &.selected {
    color: #2f904f !important;
  }
`;

const LabelWrapper = styled.div`
  min-width: 100%;
  box-sizing: border-box;
  white-space: nowrap;
  text-align: center;
  color: inherit;
  ${media.lg`
    display: flex;
  `};
`;

const CategoryNavContainer = styled.nav`
  border-collapse: collapse;
  display: inline-flex;
  flex-direction: row;
  justify-content: start;
  min-width: max-content;
  .ant-menu-horizontal {
    border-bottom: none;
  }
  ${media.lg`
    flex-direction: column;
    background: transparent;
    width: auto;
    .ant-menu-vertical {
      .ant-menu-item.ant-menu-item {
        margin: 0;
      }
      border-right: none;
    }
  `};

  .ant-menu {
    border-radius: 6px;
    border: 1px solid rgba(158, 158, 158, 0.2);
    border-top: none;
    font-weight: 400;
    ${({ isScrollable }) =>
      isScrollable &&
      `
      height: 381px;
      overflow-y: auto;
    `}

    border-radius: 6px;
  }
  .ant-menu-submenu .ant-menu-sub {
    padding: 0 8px !important;
  }
  .ant-menu .ant-menu-submenu-title {
    height: auto;
    padding: 4pt;
    line-height: 26px;
    font-weight: 400;

    .ant-menu-submenu-arrow {
      display: none;
    }
    .ant-menu-title-content {
      width: 100%;
    }
  }
  .ant-menu li:last-child {
    .ant-menu-submenu-title {
      border-bottom: none;
    }
  }
  .ant-menu-horizontal > .ant-menu-item,
  .ant-menu-horizontal > .ant-menu-submenu {
    border-bottom: none;
  }
  .ant-menu-submenu:not(:first-child) {
    .ant-menu-submenu-title a {
      /* border-top: 1px solid rgba(158, 158, 158, 0.2); */
    }
  }
`;
const CategoryNavShadow = styled.div`
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: -1;
  position: absolute;
  right: 0;
  top: 0;
  width: 4px;
  height: 100%;
  background-color: transparent;
  pointer-events: none;
  display: none;
  ${media.lg`
    display: block;
  `};
`;

const CategoryWrapper = styled.div`
  overflow: visible;
  z-index: 1;
  display: flex;
  ${media.lg`
    z-index: 20;
    top: 100pt;
  `};
  transition: left 0.3s ease-in-out;
  margin-top: 12px;
  .ant-menu-submenu.ant-menu-submenu-popup {
    top: 12px !important;
    left: 192px !important;
    max-height: ${({ wrapperHeight }) => (wrapperHeight ? `${wrapperHeight}px` : '381px')};
    overflow-y: auto;
    .ant-menu {
      box-shadow: unset;
      border: 1px solid rgba(158, 158, 158, 0.2);
      border-top: none;
      border-radius: 6px;
    }
  }

  .submenu-list {
    .ant-menu {
      /* Adjust the height of submenu list to align with the height of nav */
      height: ${({ wrapperHeight }) => (wrapperHeight ? `${wrapperHeight}px` : '100%')};
      min-height: 381px;
      padding-inline: 10px;
      min-width: 436px;
    }
  }

  .ant-menu-submenu.ant-menu-submenu-popup {
    .ant-menu {
      border-radius: 0 6px 6px 0;
      overflow-y: hidden;
    }
  }
  .ant-menu-sub .ant-menu-item:hover {
    background: unset !important;
    color: #2f904f !important;
  }
`;

const SubMenuList = styled.div`
  min-width: 210px;
`;

const SubMenu = styled(Menu.SubMenu)`
  &:first-of-type {
    a {
      overflow: hidden;
    }
  }
  &:last-of-type {
    a {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      overflow: hidden;
    }
  }
  a {
    background: white;
    transition: background-color 0.3s ease;
  }
  .ant-menu-submenu-title {
    padding: 0 !important;
    color: rgba(0, 0, 0, 0.85);
    a {
      padding: 6px 0px 6px 6px;
      color: inherit;
      display: block;
      &:focus {
        text-decoration: none;
      }
      div {
        align-items: center;
      }
    }
  }
  transition: all 0.3s ease;
  &.selected .ant-menu-submenu-title,
  &:hover .ant-menu-submenu-title {
    color: #2f904f;
  }
`;

export const CategoryContainer = styled.div`
  position: absolute;
  z-index: 100;
  top: 0;
  &:before {
    content: '';
    position: absolute;
    top: -20px;
    left: 0;
    width: 100%;
    height: 20px;
    cursor: pointer;
  }
  &:hover {
    display: block;
  }
  &.show-category {
    display: block;
  }
  ${({ disabledStore }) =>
    disabledStore &&
    `
    top: 50px;
  `}
`;
const CategoryMobileContainer = styled.div`
  margin-inline: 20px;
  overflow: auto;
  scrollbar-width: none;
`;
const CategoryMobileWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ isDisplayFullCategory }) =>
    `repeat(${isDisplayFullCategory ? '6' : '5'}, minmax(66px, 1fr))`};
  gap: 12px;
  column-gap: 32px;
  padding-bottom: 12px;
  margin-inline: 12px;
  @media (max-width: 400px) {
    column-gap: 18px;
  }
`;

const CategoryMobileItem = styled.div`
  a div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
    & div {
      font-size: 12px;
      font-weight: 450;
      line-height: 20px;
      color: #343538;
      text-align: center;
      text-wrap: balance;
    }
  }
  a div ${Icon} {
    width: 44px;
    height: 44px;
  }
`;
const Overlay = styled.div`
  display: ${({ overlay }) => (overlay ? 'block' : 'none')};
  content: '';
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 101px;
  left: 0px;
  background: rgba(66, 66, 66, 0.1);
  z-index: -1;
`;

const SliderContainer = styled.div`
  width: 18px;
  height: 4px;
  background-color: #ececec;
  border-radius: 6px;
  margin-inline: auto;
  margin-top: 12px;
  margin-bottom: 20px;
`;

const SlideDots = styled.div`
  width: 8px;
  height: 4px;
  background-color: #43a047;
  border-radius: 6px;
  transform: ${({ active }) => (active ? 'translateX(100%)' : 'translateX(0)')};
  transition: transform 0.3s ease-in-out;
`;

interface CategoryNavState {
  sideNavOpened: boolean;
  categories: Category[];
  buyer: Buyer;
  brandsByCategory: BrandsByCategory[];
  hasScrolled?: boolean;
}
interface CategoryNavProps extends RouteComponentProps {
  main?: ContainerState;
  overlay?: boolean;
  onCloseCategory?: () => void;
}

const HEIGHT_OF_SUBMENU_ITEM = 38;

class CategoryNav extends Component<CategoryNavProps, CategoryNavState> {
  public state: CategoryNavState = {
    sideNavOpened: false,
    buyer: {} as Buyer,
    categories: [],
    brandsByCategory: [],
    hasScrolled: false,
  };

  public getSelectedCategory = (searchQuery: string) => {
    const substate = this.props.main;
    return getSelectedCategory(searchQuery, substate && substate.categories);
  };

  public static getDerivedStateFromProps(nextProps: CategoryNavProps) {
    if (nextProps.main) {
      const { sideNav, categories, brandsByCategory, buyer } = nextProps.main;
      return {
        sideNavOpened: sideNav,
        categories: categories,
        brandsByCategory: brandsByCategory,
        buyer: buyer,
      };
    }
    return {
      sideNavOpened: false,
      categories: [],
      brandsByCategory: [],
      buyer: {} as Buyer,
    };
  }

  public handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const scrollLeft = e.currentTarget.scrollLeft;
    const scrollRight = e.currentTarget.scrollWidth - e.currentTarget.clientWidth - scrollLeft;
    if (scrollLeft > 0) {
      this.setState({
        hasScrolled: true,
      });
    } else if (scrollRight > 0) {
      this.setState({
        hasScrolled: false,
      });
    }
  };

  public render() {
    const isLogin = window.localStorage.getItem('token');
    const regionLocal = localStorage.getItem('region');
    const { sideNavOpened, categories, brandsByCategory, buyer, hasScrolled } = this.state;
    const { location, overlay, onCloseCategory } = this.props;
    const segment = getPathSegmentFromSlug(location);
    const searchQuery = createSearchQueryfromSlug(location, segment);
    const selectedCategory = this.getSelectedCategory(searchQuery);
    const lang = localStorage.getItem('lang') || 'vi';
    const path = pathnameChecker.isFavoritePage(location.pathname) ? '/favorite' : '';
    const categoriesByRegion = categories?.filter((category) =>
      (isLogin && buyer?.region === 'HCM') || (!isLogin && regionLocal === 'HCM')
        ? true
        : Number(category.id) !== 125 && Number(category.id) !== 121,
    );
    const currentStore = window.localStorage.getItem('store');
    return (
      <Viewport.Consumer>
        {({ width }) => {
          const isMobileSize = width < 1025;
          return (
            <ErrorBoundary>
              {!isMobileSize ? (
                <>
                  <CategoryContainer
                    isHomePage={location.pathname === '/'}
                    className={location.pathname === '/' && 'show-category'}
                    disabledStore={
                      !isMobileSize && buyer?.stores?.find((store) => store?.id === currentStore)?.status === 'BLOCKED'
                    }
                  >
                    <CategoryWrapper
                      sideNavOpen={sideNavOpened}
                      id="products-category"
                      wrapperHeight={
                        overlay
                          ? (categoriesByRegion?.length + STATIC_CATEGORY.length) * HEIGHT_OF_SUBMENU_ITEM
                          : undefined
                      }
                    >
                      <CategoryNavContainer isScrollable={!overlay}>
                        <Menu
                          getPopupContainer={() => document.getElementById('products-category') || document.body}
                          style={{ width: 200 }}
                        >
                          {/* {tags.map((tag) => {
                            return (
                              <SubMenu
                                key={tag.name}
                                className={
                                  searchQuery.search(tag.value) !== -1 && path !== '/favorite' ? 'selected' : ''
                                }
                                title={
                                  <Link to={`/${tag.value}`} onClick={onCloseCategory}>
                                    <LabelWrapper>
                                      {Icons[tag.name] && <Icon src={Icons[tag.name]} />}
                                      <Label>{tag.nameLocal}</Label>
                                    </LabelWrapper>
                                  </Link>
                                }
                              />
                            );
                          })} */}
                          {categoriesByRegion?.map((category: Category, _index) => {
                            const targetBrands = brandsByCategory.find(
                              (brand) => Number(brand.categoryId) === category.id,
                            );
                            return (
                              <SubMenu
                                key={category.name}
                                title={
                                  <Link to={`/${generateSlugFromID(category, lang)}`}>
                                    <LabelWrapper onClick={onCloseCategory}>
                                      {Icons[category?.id || 0] && <Icon src={Icons[category?.id || 0]} />}
                                      <Label>{category.nameLocal}</Label>
                                      <Icon src={rightArrow} style={{ marginLeft: 8 }} />
                                    </LabelWrapper>
                                  </Link>
                                }
                                className={
                                  selectedCategory &&
                                  selectedCategory.name.indexOf(category.name) > -1 &&
                                  path !== '/favorite'
                                    ? 'selected'
                                    : ''
                                }
                                popupClassName="submenu-list"
                              >
                                <Flex style={{ height: '100%', width: '100%' }}>
                                  <SubMenuList>
                                    {category.children?.map((subCategory) => (
                                      <Menu.Item
                                        key={subCategory.name}
                                        style={{ padding: 0 }}
                                        onClick={onCloseCategory}
                                      >
                                        <SubLabel
                                          to={`/${generateSlugFromID(subCategory, lang)}`}
                                          className={
                                            selectedCategory && selectedCategory.name === subCategory.name
                                              ? 'selected'
                                              : ''
                                          }
                                        >
                                          {subCategory.nameLocal}
                                        </SubLabel>
                                      </Menu.Item>
                                    ))}
                                  </SubMenuList>
                                  <BrandByCategory brands={targetBrands} />
                                </Flex>
                              </SubMenu>
                            );
                          })}
                          {STATIC_CATEGORY.map((item) => (
                            <SubMenu
                              key={item.key}
                              className={location.pathname === item.pathname ? 'selected' : ''}
                              title={
                                <Link to={item.pathname} onClick={onCloseCategory}>
                                  <LabelWrapper>
                                    <Icon src={Icons[item.id]} />
                                    <Label>
                                      <FormattedMessage {...utilsMessages[item.key]} />
                                    </Label>
                                  </LabelWrapper>
                                </Link>
                              }
                            />
                          ))}
                        </Menu>
                      </CategoryNavContainer>
                      <CategoryNavShadow />
                    </CategoryWrapper>
                  </CategoryContainer>
                  <Overlay overlay={overlay} onClick={onCloseCategory} />
                </>
              ) : (
                <>
                  <CategoryMobileContainer onScroll={this.handleScroll}>
                    <CategoryMobileWrapper isDisplayFullCategory={categoriesByRegion?.length > 10}>
                      {/* {tags.map((tag) => {
                        return (
                          <CategoryMobileItem
                            key={tag.name}
                            className={searchQuery.search(tag.value) !== -1 && path !== '/favorite' ? 'selected' : ''}
                          >
                            <Link to={path === '/favorite' ? `${path}?tags=${tag.value}` : `/${tag.value}`}>
                              <LabelWrapper>
                                {Icons[tag.name] && <Icon src={Icons[tag.name]} />}
                                <Label>{tag.nameLocal}</Label>
                              </LabelWrapper>
                            </Link>
                          </CategoryMobileItem>
                        );
                      })} */}
                      {categoriesByRegion?.map((category: Category) => {
                        return (
                          <CategoryMobileItem
                            key={category.name}
                            className={
                              selectedCategory &&
                              selectedCategory.name.indexOf(category.name) > -1 &&
                              path !== '/favorite'
                                ? 'selected'
                                : ''
                            }
                            popupClassName="submenu-list"
                          >
                            <Link
                              to={
                                path === '/favorite'
                                  ? `${path}?category=${encodeURIComponent(category.name)}`
                                  : `/${generateSlugFromID(category, lang)}`
                              }
                            >
                              <LabelWrapper>
                                {Icons[category?.id || 0] && (
                                  <Icon
                                    src={Icons[category?.id || 0]}
                                    style={{
                                      width: 44,
                                      height: 44,
                                    }}
                                  />
                                )}
                                <Label>{category.nameLocal}</Label>
                              </LabelWrapper>
                            </Link>
                          </CategoryMobileItem>
                        );
                      })}
                    </CategoryMobileWrapper>
                  </CategoryMobileContainer>
                  {width < 575 && (
                    <SliderContainer>
                      <SlideDots active={hasScrolled} />
                    </SliderContainer>
                  )}
                </>
              )}
            </ErrorBoundary>
          );
        }}
      </Viewport.Consumer>
    );
  }
}

export default withRouter(CategoryNav);
