import * as React from 'react';
import styled, { CSSProp } from 'styled-components';
import { Modal as AModal } from 'antd';
import Button from '../Button/Button';
import SVGIcon from '../SVGIcon/SVGIcon';
import media from 'utils/mediaStyle';

const Header = styled.header`
  display: flex;
  flex-direction: row;
  color: #333a41;
  text-transform: ${({ upperCase }) => (upperCase ? 'uppercase' : 'none')};
  align-items: center;
  font-weight: bold;
`;
const HeaderIcon = styled(SVGIcon)<any>`
  margin-right: 6pt;
  width: 20px;
  height: 20px;
  svg {
    width: 100%;
    height: 100%;
    path {
      fill: #2e904e;
    }
  }
`;

const Body = styled.section<any>``;

const Modal = styled(AModal)<any>`
  ${({ oneBtn }) =>
    oneBtn &&
    `
    .ant-modal-footer {
      border-top: none;
      text-align: center;
      button {
        width: 100%;
        margin-bottom: 6pt;
      }
    }
  `};

  ${({ paddingless }) =>
    paddingless &&
    `
    .ant-modal-body {
      padding: 0;
      ${media.md`
        padding: 24px;
      `};
    }`};

  &.ant-modal {
    max-width: calc(100% - 16px);
  }

  ${({ customStyles }) => customStyles}
`;

export default function ({
  size,
  isOpen,
  headerIcon,
  headerLabel,
  children,
  submitLabel,
  closeLabel,
  confirmLoading,
  onSubmit,
  onClose,
  paddingless,
  disableOkButton,
  upperCase = true,
  customStyles,
  destroyOnClose = false,
}: {
  paddingless?: boolean;
  upperCase?: boolean;
  customStyles?: CSSProp;
  confirmLoading?: boolean;
  disableOkButton?: boolean;
  size?: string;
  isOpen: boolean;
  children?: any;
  headerIcon?: string;
  headerLabel?: string | React.ReactElement<any>;
  submitLabel?: string | React.ReactElement<any>;
  closeLabel?: string | React.ReactElement<any>;
  onSubmit?: () => void;
  onClose?: () => void;
  destroyOnClose?: boolean;
}) {
  return (
    <Modal
      centered
      destroyOnClose={destroyOnClose}
      title={
        headerLabel && (
          <Header upperCase={upperCase}>
            {headerIcon && <HeaderIcon renderAsImg={false} src={headerIcon} />}
            {headerLabel}
          </Header>
        )
      }
      width={size === 'large' ? '800pt' : '500pt'}
      open={isOpen}
      maskClosable={false}
      closable={false}
      oneBtn={!(closeLabel && submitLabel)}
      paddingless={paddingless}
      footer={
        closeLabel || submitLabel
          ? [
              closeLabel && (
                <Button key="close" type="secondary" onClick={onClose}>
                  {closeLabel}
                </Button>
              ),
              submitLabel && (
                <Button
                  key="submit"
                  type="primary"
                  onClick={onSubmit}
                  disabled={disableOkButton}
                  loading={confirmLoading}
                >
                  {submitLabel}
                </Button>
              ),
            ]
          : null
      }
      customStyles={customStyles}
    >
      <Body>{children}</Body>
    </Modal>
  );
}
